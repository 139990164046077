import { t } from 'i18next';
import * as yup from 'yup';

import {
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  notesSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
  waypointSchemaOptionalSites,
} from '~constants/regexConst';
import { fileAttachmentSchema } from '~formsShared/components/FileAttachments';
import { Job } from '~hooks/useJob';
import { JobFormProps } from '~hooks/useJob/models';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';

export const jobFormSchema = yup.object().shape({
  id: yup.string(),
  jobStartAt: yup.string().required(),
  material: yup.object().when('$isHiredTruckService', {
    is: true,
    then: () => nameIdNonRequiredSchema.notRequired(),
    otherwise: () => nameIdSchemaRequired.required(),
  }),
  equipment: yup.object().notRequired(),
  additionalEquipment: yup.object().notRequired(),
  quantity: yup.number().when('$isHiredTruckService', {
    is: true,
    then: (schema) => {
      return schema
        .nullable()
        .positive()
        .transform((_, val) => (String(val).trim() !== '' ? Number(val) : null));
    },
    otherwise: (schema) =>
      schema
        .positive()
        .required()
        .typeError(
          t('form_validation_errors.should_be_number', {
            field: t('form_fields.quantity'),
          }) || '',
        ),
  }),
  unitOfMeasure: yup.object().when('$isHiredTruckService', {
    is: true,
    then: () => nameIdNonRequiredSchema.notRequired(),
    otherwise: () => nameIdSchemaRequired.required(),
  }),
  priority: yup
    .number()
    .min(
      0,
      `${t('form_validation_errors.min', {
        min: 0,
        field: t('form_fields.priority'),
      })}`,
    )
    .notRequired()
    .typeError(
      t('form_validation_errors.should_be_number', {
        field: t('form_fields.priority'),
      }) || '',
    ),
  pickUpWayPoint: yup.object().when('$isHiredTruckService', {
    is: true,
    then: () => waypointSchemaOptionalSites.notRequired(),
    otherwise: () => waypointSchema.required(),
  }),
  dropOffWayPoint: yup.object().when('$isHiredTruckService', {
    is: true,
    then: () => waypointSchemaOptionalSites.notRequired(),
    otherwise: () => waypointSchema.required(),
  }),
  notes: notesSchema,
  jobNotes: notesSchema,
  orderNotes: notesSchema,
  projectNotes: notesSchema,
  internalNotes: notesSchema,
  allContacts: yup.array().of(
    yup.object().shape({
      name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
      phone: phoneSchema,
      type: nameIdNonRequiredSchema,
      isDeleted: yup.boolean().notRequired(),
    }),
  ),
  vendorAccount: yup.object().notRequired(),
  loads: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      quantity: yup
        .number()
        .transform((value, originalValue) =>
          originalValue === '' || originalValue === null ? null : value,
        )
        .when(['$isHiredTrucksFeatureOn', '$isHiredTruckService'], {
          is: (isHiredTrucksFeatureOn: boolean, isHiredTruckService: boolean) =>
            isHiredTrucksFeatureOn && isHiredTruckService,
          then: (schema) => schema.nullable().notRequired(),
          otherwise: (schema) =>
            schema
              .positive()
              .required()
              .typeError(
                t('form_validation_errors.should_be_number', {
                  field: t('form_fields.quantity'),
                }) || '',
              ),
        }),
      unitOfMeasure: yup
        .object()
        .when(['$isHiredTrucksFeatureOn', '$isHiredTruckService'], {
          is: (isHiredTrucksFeatureOn: boolean, isHiredTruckService: boolean) =>
            isHiredTrucksFeatureOn && isHiredTruckService,
          then: () => nameIdNonRequiredSchema.notRequired(),
          otherwise: () => nameIdSchemaRequired.required(),
        }),
      material: yup.object().when('$isHiredTrucksFeatureOn', {
        is: (isHiredTrucksFeatureOn: boolean) => !isHiredTrucksFeatureOn,
        then: () => nameIdNonRequiredSchema.notRequired(),
        otherwise: (schema) =>
          schema.when('$isHiredTruckService', {
            is: true,
            then: () => nameIdNonRequiredSchema.notRequired(),
            otherwise: () => nameIdSchemaRequired.required(),
          }),
      }),
      waypoints: yup
        .array()
        .of(
          yup.object().shape({
            site: nameIdNonRequiredSchema.notRequired(),
          }),
        )
        .notRequired(),
      pickUpWaypoints: yup.array().when('$isHiredTrucksFeatureOn', {
        is: (isHiredTrucksFeatureOn: boolean) => !isHiredTrucksFeatureOn,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) =>
          schema.of(
            yup.object().shape({
              site: yup.object().when('$isHiredTruckService', {
                is: true,
                then: () => nameIdNonRequiredSchema.notRequired(),
                otherwise: () => nameIdSchemaRequired.required(),
              }),
            }),
          ),
      }),
      dropOffWaypoints: yup.array().when('$isHiredTrucksFeatureOn', {
        is: (isHiredTrucksFeatureOn: boolean) => !isHiredTrucksFeatureOn,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) =>
          schema.of(
            yup.object().shape({
              site: yup.object().when('$isHiredTruckService', {
                is: true,
                then: () => nameIdNonRequiredSchema.notRequired(),
                otherwise: () => nameIdSchemaRequired.required(),
              }),
            }),
          ),
      }),
    }),
  ),
  foremen: yup.array().of(yup.object().shape({ id: yup.string().required() }).required()),
  vendor: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .nullable(),
  driver: yup
    .object()
    .shape({
      label: yup.string().min(1).required(),
      value: yup.string().min(1).required(),
    })
    .nullable(),
  fileAttachments: fileAttachmentSchema,
  requestedLoadsCount: yup.number().notRequired().min(1),
});

export const setDefaultJobValues = (job: Job) => {
  const pickUpWayPoint = job?.pickUpWayPoints?.[0];
  const dropOffWayPoint = job?.dropOffWayPoints?.[0];

  return {
    id: job.id,
    notes: job.notes,
    jobNotes: job.notes || '',
    projectNotes: '',
    orderNotes: '',
    internalNotes: '',
    quantity: job.quantity,
    unitOfMeasure: job.unitOfMeasure,
    service: job.service,
    material: job.material,
    equipment: job.equipment,
    additionalEquipment: job.additionalEquipment,
    requestedEquipmentTypeName: job.requestedEquipmentTypeName,
    jobId: job.jobId,
    jobStartAt: job.jobStartAt || '',
    pickUpWayPoint: pickUpWayPoint?.id
      ? {
          id: pickUpWayPoint?.id || '',
          type: pickUpWayPoint?.type || '',
          ordinality: pickUpWayPoint?.ordinality ?? 10,
          contact: {
            name: pickUpWayPoint?.contact?.name || '',
            phone: pickUpWayPoint?.contact?.phone || '',
          },
          site: {
            id: pickUpWayPoint?.site?.id || '',
            name: pickUpWayPoint?.site?.name || '',
            lat: pickUpWayPoint?.site?.lat,
            lon: pickUpWayPoint?.site?.lon,
          },
        }
      : null,
    dropOffWayPoint: dropOffWayPoint?.id
      ? {
          id: dropOffWayPoint?.id || '',
          type: dropOffWayPoint?.type || '',
          ordinality: dropOffWayPoint?.ordinality ?? 10,
          contact: {
            name: dropOffWayPoint?.contact?.name || '',
            phone: dropOffWayPoint?.contact?.phone || '',
          },
          site: {
            id: dropOffWayPoint?.site?.id || '',
            name: dropOffWayPoint?.site?.name || '',
            lat: dropOffWayPoint?.site?.lat,
            lon: dropOffWayPoint?.site?.lon,
          },
        }
      : null,
    priority: job.priority || '',
    vendorAccount: {
      name: job?.vendorJobAssignment?.vendorAccount?.name || '',
      id: job?.vendorJobAssignment?.vendorAccount?.id || '',
    },
    loads: [],
    foremen: job?.foremen || [],
    allContacts: job?.allContacts?.length
      ? job.allContacts.map((single) => {
          return {
            id: single.id || undefined,
            name: single.name || '',
            phone: single.phone || '',
            type: single.type || undefined,
            isDeleted: false,
          };
        })
      : [EmptyContactItem],
    vendor: job?.vendorJobAssignment?.vendorAccount
      ? {
          label: String(job.vendorJobAssignment.vendorAccount.name),
          value: String(job.vendorJobAssignment.vendorAccount.id),
        }
      : null,
    driver: job?.driver ? { label: job.driver.fullName, value: job.driver.id } : null,
    requestedLoadsCount: job.requestedLoadsCount,
  } as unknown as JobFormProps;
};
