import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import {
  dateFormatDDMMYYYYOption,
  dateFormatMMDDYYYYOption,
  dateFormatYYYYMMDDOption,
} from '~constants/enums';
import { nameIdSchemaRequired } from '~constants/regexConst';
import { Company, useCompany } from '~hooks/useCompany';
import { useStores } from '~store';
import UserStore from '~store/UserStore';
import { alert, AlertTypes } from '~types/AlertTypes';

const settingSchema = yup.object().shape({
  dateFormat: nameIdSchemaRequired,
});

type SettingDTO = yup.InferType<typeof settingSchema>;

const dateFormatOptions = [
  dateFormatMMDDYYYYOption,
  dateFormatDDMMYYYYOption,
  dateFormatYYYYMMDDOption,
];

function DateFormatSetting() {
  const { userStore, toasterStore } = useStores();
  const { updateCompany } = useCompany();
  const [isSaving, setIsSaving] = useState(false);
  const [inSaveMode, setInSaveMode] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<SettingDTO>({
    resolver: yupResolver(settingSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      dateFormat: userStore.userCompany.dateFormat
        ? dateFormatOptions.find(
            (option) => option.id === userStore.userCompany.dateFormat,
          )
        : dateFormatMMDDYYYYOption,
    },
  });

  const saveCompanyDateFormat = (data: SettingDTO) => {
    setIsSaving(true);
    updateCompany({
      company: {
        id: userStore.userCompany.id,
        dateFormat: data.dateFormat.id,
      } as Company,
    })
      .then(() => {
        setInSaveMode(false);
        reset({ dateFormat: data.dateFormat });
        toasterStore.push(
          alert(
            t('administration.configuration.configuration_saved'),
            AlertTypes.success,
          ),
        );
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Typography sx={{ mb: 1 }}>
        {t('administration.configuration.settings.date_format.setting_subtitle')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={3}>
          <AutocompleteFormField
            label={`${t('administration.configuration.settings.date_format.setting_title')}`}
            control={control}
            errors={errors}
            disabled={!inSaveMode}
            name="dateFormat"
            list={dateFormatOptions}
            getLabel={(item) => item?.name}
            getValue={(item) => item?.id}
          />
        </Grid>
        <Grid item sx={{ alignSelf: 'flex-end', mb: 1 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {inSaveMode && (
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setInSaveMode(false);
                  reset({
                    dateFormat: userStore.userCompany.dateFormat
                      ? dateFormatOptions.find(
                          (option) => option.id === userStore.userCompany.dateFormat,
                        )
                      : dateFormatMMDDYYYYOption,
                  });
                }}
                startIcon={<Close />}
              >
                {t('actions.cancel')}
              </Button>
            )}
            <LoadingButton
              loading={isSaving}
              variant="contained"
              color={'primary'}
              startIcon={inSaveMode ? <Check /> : null}
              onClick={
                inSaveMode
                  ? handleSubmit(saveCompanyDateFormat)
                  : () => setInSaveMode(true)
              }
            >
              {inSaveMode ? t('actions.save') : t('actions.edit')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default {
  component: DateFormatSetting,
  canAccess: (userStore: UserStore) => {
    return userStore.getPermissions().canEditCompany;
  },
};
