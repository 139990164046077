import Popover from '@mui/material/Popover';
import { Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useMemo, useState } from 'react';

import { MarkerType } from './constants';
import { TreadMarkerProps } from './types';

const apiKey = import.meta.env.TREAD__GOOGLE_MAPS_API_KEY;

type Props = TreadMarkerProps;

export function TreadMarker(marker: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  const onClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.domEvent.currentTarget as Element);
      }
    },
    [anchorEl],
  );

  const markerTypeToIcon: Record<
    MarkerType,
    google.maps.Icon | google.maps.Symbol
  > | null = useMemo(() => {
    if (!isLoaded) return null;
    return {
      [MarkerType.truck]: {
        url: 'map-markers/truck_marker.png',
        scaledSize: new window.google.maps.Size(16, 18),
        anchor: new window.google.maps.Point(8, 9),
      },
      [MarkerType.site]: {
        // https://www.svgrepo.com/show/1276/map-pin.svg
        path: 'M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878    c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212    c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084    c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358    c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307    C198.98,120.938,175.559,144.358,146.667,144.358z',
        fillColor: 'blue',
        fillOpacity: 1,
        strokeWeight: 2,
        scale: 0.15,
        strokeColor: '#ffffff',
        anchor: new window.google.maps.Point(150, 275),
      },
      [MarkerType.moving_site]: {
        path: 'M10,2 A8,8 0 1,0 10,18 A8,8 0 1,0 10,2',
        fillColor: 'black',
        fillOpacity: 1,
        scale: 0.9,
        strokeWeight: 2,
        strokeColor: '#ffffff',
        anchor: new window.google.maps.Point(9, 9),
      },
      [MarkerType.ping]: {
        path: 'M10,2 A8,8 0 1,0 10,18 A8,8 0 1,0 10,2',
        fillColor: '#6e6f70',
        fillOpacity: 1,
        scale: 0.6,
        strokeWeight: 0,
        anchor: new window.google.maps.Point(9, 9),
      },
    };
  }, [isLoaded]);

  const isDraggable = marker.type === MarkerType.site && marker.draggable;
  const popoverExists =
    marker.type === MarkerType.site && !marker.draggable && marker.popoverContent;
  return (
    <>
      <Marker
        position={{
          lng: marker.lng,
          lat: marker.lat,
        }}
        animation={google.maps.Animation.DROP}
        label={marker.type === MarkerType.truck ? marker.label : undefined}
        icon={
          markerTypeToIcon?.[marker.type]
            ? {
                ...markerTypeToIcon[marker.type],
                ...(marker.isActive ? { fillColor: 'orange' } : {}),
              }
            : undefined
        }
        draggable={isDraggable}
        onMouseDown={marker.type === MarkerType.site ? marker.onMouseDown : undefined}
        onMouseUp={marker.type === MarkerType.site ? marker.onMouseUp : undefined}
        onMouseOver={marker.type === MarkerType.site ? marker.onMouseOver : undefined}
        onMouseOut={marker.type === MarkerType.site ? marker.onMouseOut : undefined}
        onDragEnd={
          isDraggable
            ? (event) => {
                marker.onDragEnd?.({
                  lat: event.latLng?.lat() ?? 0,
                  lng: event.latLng?.lng() ?? 0,
                });
              }
            : undefined
        }
        onClick={popoverExists ? onClick : undefined}
      />
      {popoverExists && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClick}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {marker.popoverContent}
        </Popover>
      )}
    </>
  );
}
