import { yupResolver } from '@hookform/resolvers/yup';
import Refresh from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ModelError_Item } from '@treadinc/horizon-api-spec';
import { convertLength } from '@turf/helpers';
import { t } from 'i18next';
import { get } from 'lodash';
import {
  ComponentProps,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { GeofenceType, MarkerType } from '~components/Maps/v2/constants';
import { MapV2 } from '~components/Maps/v2/MapV2';
import { TreadMarkerProps } from '~components/Maps/v2/types';
import { AddressOptionForNewSite } from '~components/Order/SiteSelection/types';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import {
  data as enums,
  geoFenceCircleOption,
  geofenceEquipmentOption,
  geofenceNoneOption,
  geofenceOptions,
  geofencePolygonOption,
  GeoFenceTypes,
  movingGeoFencesTagsOptions,
} from '~constants/enums';
import { FormStateChangeProps } from '~formsShared';
import { AddressItem } from '~hooks/useAddress/models/AddressItem';
import { SupportedAddressProvider } from '~hooks/useAddress/models/AddressProvider';
import { Company, CompanyBasic, useCompany } from '~hooks/useCompany';
import { useEquipment } from '~hooks/useEquipment';
import { useGeocode } from '~hooks/useGeocode';
import { Geofence, Site } from '~hooks/useSites';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

import { useSiteFormV2 } from './hooks';
import { SITE_FORM_SCHEMA } from './schema';
import { SiteFormV2Fields } from './types';
import { geofenceToItemNameAndId, getErrorMapping } from './utils';

const gap = 2;

interface SiteFormRef {
  submit: (callback: () => void) => void;
  resetForm: (callback?: () => void) => void;
}

interface SiteFormProps {
  defaultSite?: Nullable<Site>;
  addressOption?: Nullable<AddressOptionForNewSite>;
  company?: Nullable<CompanyBasic>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  isInline?: boolean;
  errors?: ModelError_Item[];
}

export const SiteFormV2 = forwardRef(function SiteFormV2(
  {
    addressOption,
    defaultSite,
    onFormStateChange,
    company,
    errors: errorsProp,
  }: SiteFormProps,
  ref: Ref<SiteFormRef>,
) {
  // Null means that we are working with the primary geofence
  const [selectedAdditionalGeofence, setSelectedAdditionalGeofence] = useState<
    number | null
  >(null);
  const [companyDetails, setCompanyDetails] = useState<Company | null>(null);

  const { getPlaces, getAddressItemByGoogleMapsPlaceId } = useGeocode({
    addressProvider: SupportedAddressProvider.GOOGLE_MAPS_PLACES_AUTOCOMPLETE,
  });
  const { getAdditionalGeoFencesForSite } = useSiteFormV2();
  const { getEquipmentByCompanyId, isLoading: isLoadingEquipment } = useEquipment();
  const { getCompanyById } = useCompany();

  const { userStore, companyAssetsStore } = useStores();
  const equipmentOptions = companyAssetsStore.equipment;

  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<SiteFormV2Fields>({
    resolver: yupResolver(SITE_FORM_SCHEMA),
    mode: 'all',
    defaultValues: {
      company: company ? { legalName: company.legalName, id: company.id } : null,
      name: defaultSite?.name ?? null,
      siteType: defaultSite?.siteType ?? null,
      externalId: defaultSite?.externalId ?? null,
      notes: defaultSite?.notes ?? null,
      address: defaultSite?.address ?? null,
      latLon: defaultSite?.latLon ?? '',
      geofenceId: defaultSite?.nextBillionGeofence?.id ?? null,
      geofenceType: defaultSite?.nextBillionGeofence
        ? geofenceToItemNameAndId(defaultSite.nextBillionGeofence)
        : null,
      radius: defaultSite?.nextBillionGeofence?.circleRadius
        ? Math.round(
            Number(
              convertLength(
                defaultSite?.nextBillionGeofence?.circleRadius,
                'meters',
                'feet',
              ),
            ),
          )
        : null,
      equipment:
        equipmentOptions.find(
          (item) => item.id === defaultSite?.movingSite?.equipment_id,
        ) ?? null,
      drawingCoordinates: [],
      additionalGeofences: [],
    },
    delayError: 500,
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'additionalGeofences',
  });

  const watchedLatLon = watch('latLon');
  const geoCodedAddress = watch('address');
  const watchedGeofenceType = watch('geofenceType');
  const watchedRadius = watch('radius');
  const watchedEquipment = watch('equipment');
  const watchedDrawingCoordinates = watch('drawingCoordinates');
  const watchedAdditionalGeofences = watch('additionalGeofences');

  const resetLatLon = useCallback(() => {
    if (geoCodedAddress) {
      setValue('latLon', `${geoCodedAddress?.lat},${geoCodedAddress?.lng}`);
    } else if (defaultSite?.latLon) {
      setValue('latLon', defaultSite?.latLon);
    } else {
      setValue('latLon', '');
    }
  }, [geoCodedAddress, setValue]);

  useEffect(() => {
    // If the address changes, we need to reset the latLon
    if (geoCodedAddress !== defaultSite?.address) {
      resetLatLon();
    }
  }, [geoCodedAddress]);

  useEffect(() => {
    // If the form state changes, we need to notify the parent component. This is from the first SiteForm, but
    // we should look into improving this.
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  useEffect(() => {
    const hydrateForm = async (siteId: string | null) => {
      if (company?.id) {
        const emptyPromise = Promise.resolve(undefined);
        const defaultCompanyPromise = Promise.resolve(userStore.userCompany);

        const companyDetailsPromise =
          userStore.userCompany.id !== company.id
            ? getCompanyById({ id: company.id })
            : defaultCompanyPromise;
        const equipmentPromise = getEquipmentByCompanyId({
          companyId: company.id,
          shared: false,
        });
        const additionalGeofencesPromise = siteId
          ? getAdditionalGeoFencesForSite(siteId)
          : emptyPromise;

        const [companyDetails, equipment, additionalGeofences] = await Promise.all([
          companyDetailsPromise,
          equipmentPromise,
          additionalGeofencesPromise,
        ]);

        setCompanyDetails(companyDetails);

        const equipmentSelection = equipment.find(
          (item) => item.id === defaultSite?.movingSite?.equipment_id,
        );
        if (equipmentSelection) {
          setValue('equipment', equipmentSelection);
        }

        const additionalGeofencesFields = (additionalGeofences ?? []).map(
          (geofence: Geofence) => ({
            geofenceId: geofence.id,
            geofenceType: geofenceToItemNameAndId(geofence) ?? null,
            radius: geofence.circleRadius
              ? Math.round(Number(convertLength(geofence.circleRadius, 'meters', 'feet')))
              : null,
            equipment: equipmentSelection ?? null,
            drawingCoordinates: geofence.geojson?.coordinates
              ? (geofence.geojson?.coordinates as [number, number][][])[0].map(
                  (coordinate) => ({
                    lat: coordinate[1],
                    lng: coordinate[0],
                  }),
                )
              : [],
            tag:
              movingGeoFencesTagsOptions.find((tag) => tag.id === geofence.tag) ?? null,
          }),
        );
        setValue('additionalGeofences', additionalGeofencesFields);
      }
    };
    hydrateForm(defaultSite?.id ?? null);
  }, [defaultSite?.id]);

  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const newErrorField = getErrorMapping(error.field, error.message);
        if (newErrorField.field && newErrorField.message) {
          setError(newErrorField.field as keyof ReturnType<typeof setError>, {
            type: 'manual',
            message: newErrorField.message,
          });
        }
      }
    }
  }, [errorsProp]);

  useEffect(() => {
    // If the geofence type changes, we need to clear the drawing coordinates
    if (
      watchedGeofenceType?.id !== GeoFenceTypes.POLYGON &&
      watchedDrawingCoordinates.length
    ) {
      setValue('drawingCoordinates', []);
    }
    if (
      watchedGeofenceType?.id !== GeoFenceTypes.CIRCLE &&
      watchedGeofenceType?.id !== GeoFenceTypes.EQUIPMENT &&
      !!watchedRadius
    ) {
      setValue('radius', null);
    }
    if (
      watchedGeofenceType?.id === GeoFenceTypes.POLYGON &&
      defaultSite?.nextBillionGeofence?.geojson?.coordinates.length
    ) {
      /*
       * I don't have time to fix it in this pass, but the model definition is incorrect
       * as an always empty list, so we cast here.
       */
      const coordinatesArr = defaultSite.nextBillionGeofence.geojson.coordinates as [
        number,
        number,
      ][][];
      const coordinates = coordinatesArr[0] ?? [];
      setValue(
        'drawingCoordinates',
        coordinates.map((coordinate) => ({
          lat: coordinate[1],
          lng: coordinate[0],
        })),
      );
    }
  }, [watchedGeofenceType, defaultSite]);

  const tagOptions = useMemo(() => {
    const selectedTagIds = new Set(
      watchedAdditionalGeofences
        .filter((geofence) => geofence.tag)
        .map((geofence) => geofence.tag?.id),
    );
    const unselectedTags = movingGeoFencesTagsOptions.filter((tag) => {
      return !selectedTagIds.has(tag.id);
    });
    return unselectedTags;
  }, [
    movingGeoFencesTagsOptions,
    watchedAdditionalGeofences.filter((geofence) => geofence.tag),
  ]);

  const initialMapCenter = useMemo(() => {
    if (addressOption?.lat && addressOption?.lng) {
      return {
        lat: addressOption.lat,
        lng: addressOption.lng,
      };
    }
    if (defaultSite?.lat && defaultSite?.lng) {
      return {
        lat: defaultSite.lat,
        lng: defaultSite.lng,
      };
    }
    if (companyDetails?.defaultLat && companyDetails?.defaultLon) {
      return {
        lat: companyDetails.defaultLat,
        lng: companyDetails.defaultLon,
      };
    }
  }, [
    addressOption?.lng,
    addressOption?.lat,
    defaultSite?.lng,
    defaultSite?.lat,
    companyDetails?.defaultLon,
    companyDetails?.defaultLat,
  ]);

  // The radii we are listening for are hard to memoize, so we pull it out here for readability
  const additionalGeofenceRadii = watchedAdditionalGeofences
    .filter((geofence) => geofence.radius)
    .map((geofence) => geofence.radius) as number[];

  const allPolygonsExceptSelected = Number.isInteger(selectedAdditionalGeofence)
    ? [
        ...(watchedDrawingCoordinates.length > 0
          ? [
              watchedDrawingCoordinates.map((coordinate) => [
                coordinate.lng,
                coordinate.lat,
              ]) as [number, number][],
            ]
          : []),
        ...(watchedAdditionalGeofences
          .filter(
            (geofence, index) =>
              geofence.geofenceType?.id === GeoFenceTypes.POLYGON &&
              index !== selectedAdditionalGeofence,
          )
          .map((geofence) =>
            geofence.drawingCoordinates.map((coordinate) => [
              coordinate.lng,
              coordinate.lat,
            ]),
          ) as [number, number][][]),
      ]
    : (watchedAdditionalGeofences
        .filter((geofence) => geofence.geofenceType?.id === GeoFenceTypes.POLYGON)
        .map((geofence) =>
          geofence.drawingCoordinates.map((coordinate) => [
            coordinate.lng,
            coordinate.lat,
          ]),
        ) as [number, number][][]);

  // In future iterations, we will expose a TreadMarkerInterface for general consumption
  const locationMarkers:
    | Omit<TreadMarkerProps, 'onMouseUp' | 'onMouseDown'>[]
    | undefined = useMemo(() => {
    if (
      watchedLatLon.split(',').length === 2 &&
      watchedLatLon.split(',').every((v) => !isNaN(parseFloat(v.trim())))
    ) {
      const [lat, lon] = watchedLatLon
        .split(',')
        .map((v) => v.trim())
        .map((v) => parseFloat(v));
      return [
        /* We use two markers at the same location to represent the circles and the polygons.
         * In an upcoming pass, we will introduce the editable_site type, to consolidate these
         * markers.
         */
        {
          id: 'lat-lon-circle-marker',
          lat,
          lng: lon,
          type: MarkerType.site,
          geofenceType: GeofenceType.circle,
          radii: [
            watchedRadius ? convertLength(Number(watchedRadius), 'feet', 'meters') : 0,
            ...additionalGeofenceRadii.map((radius) =>
              convertLength(Number(radius), 'feet', 'meters'),
            ),
          ],
        },
        {
          id: 'lat-lon-polygon-marker',
          lat,
          lng: lon,
          type: MarkerType.site,
          // Our second marker is used for polygons and dragging.
          draggable: true,
          onDragEnd: (newCoordinates: { lat: number; lng: number }) => {
            setValue('latLon', `${newCoordinates.lat},${newCoordinates.lng}`, {
              shouldDirty: true,
            });
          },
          geofenceType: GeofenceType.polygon,
          coordinates: allPolygonsExceptSelected,
        },
      ];
    }
    return undefined;
  }, [
    watchedLatLon,
    watchedRadius,
    JSON.stringify(additionalGeofenceRadii),
    JSON.stringify(allPolygonsExceptSelected),
  ]);

  const showAddMoreGeofenceButton = useMemo(() => {
    if (!watchedGeofenceType?.id) {
      return false;
    }
    if (watchedGeofenceType.id === geofenceNoneOption.id) {
      return false;
    }
    if (
      watchedGeofenceType.id === GeoFenceTypes.EQUIPMENT &&
      watchedAdditionalGeofences.length >= 1
    ) {
      return false;
    }
    if (watchedAdditionalGeofences.length >= 5) {
      return false;
    }
    return true;
  }, [watchedGeofenceType?.id, watchedAdditionalGeofences.length]);

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap }}>
      <CompanySelectorFormField
        control={control}
        disabled={!!defaultSite?.company?.id}
        errors={errors}
        name={'company'}
        sx={{ gridColumn: '1 / -1' }}
      />
      <Box
        sx={{
          alignContent: 'start',
          columnGap: gap,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <TextFormField
          isRequired
          control={control}
          errors={errors}
          label={`${t('form_fields.name')}`}
          name="name"
          sx={{ mr: 2 }}
        />
        <AutocompleteFormField
          isRequired
          control={control}
          errors={errors}
          getLabel={(item) => item}
          getValue={(item) => item}
          label={`${t('form_fields.site_type')}`}
          list={enums.site_type.values}
          name="siteType"
        />
        <TextFormField
          control={control}
          errors={errors}
          isRequired={false}
          label={`${t('form_fields.id')}`}
          name="externalId"
        />
        <Box
          onClick={() => {
            setSelectedAdditionalGeofence(null);
          }}
          sx={{
            gridColumn: '1 / -1',
            alignContent: 'start',
            columnGap: gap,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            transition: 'all 0.5s',
            ...(selectedAdditionalGeofence === null &&
            watchedAdditionalGeofences.length > 0
              ? { boxShadow: 3, p: 2, bgcolor: 'primary.light' }
              : {}),
          }}
        >
          <Typography sx={{ gridColumn: '1 / -1', my: 2 }}>
            {t('form_fields.primary_geo_fence')}
          </Typography>
          <AutocompleteFormField
            isRequired
            control={control}
            disabled={
              watchedLatLon.length === 0 ||
              (watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT &&
                watchedAdditionalGeofences.length > 0)
            }
            errors={errors}
            getLabel={(item) => item?.name}
            getValue={(item) => item?.id}
            label={`${t('form_fields.geo_fence_type')}`}
            list={[...geofenceOptions, geofenceNoneOption]}
            name="geofenceType"
          />
          {watchedGeofenceType?.id === GeoFenceTypes.POLYGON && (
            <Typography
              sx={{
                color: 'grey.600',
                mt: 1,
              }}
            >
              {t('form_fields.geo_fence_type_hint_v2')}
            </Typography>
          )}
          {watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT && (
            <AutocompleteFormField
              clearable={true}
              control={control}
              disabled={watchedAdditionalGeofences.length > 0}
              errors={errors}
              getLabel={(item) => item._name || ''}
              getValue={(item) => item._id}
              label={`${t('form_fields.available_equipment')}`}
              loading={isLoadingEquipment}
              list={equipmentOptions}
              name="equipment"
              noOptionsText={t('form_fields.no_options')}
            />
          )}
          {(watchedGeofenceType?.id === GeoFenceTypes.CIRCLE ||
            watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT) && (
            <TextFormField
              control={control}
              errors={errors}
              label={`${t('form_fields.radius')} (${t('units_of_distance.feet')})`}
              name="radius"
              type="number"
            />
          )}
        </Box>
        {fields.length > 0 && (
          <Typography
            sx={{ gridColumn: '1 / -1', my: 2, pt: 2, borderTop: '1px solid lightgray' }}
          >
            {t('form_fields.additional_geo_fences')}
          </Typography>
        )}
        {fields.map((field, index) => {
          return (
            <Box
              key={field.id}
              onClick={() => {
                setSelectedAdditionalGeofence(index);
              }}
              sx={{
                gridColumn: '1 / -1',
                border: '1px solid lightgray',
                display: 'grid',
                gap,
                gridTemplateColumns: '1fr 1fr',
                p: 2,
                position: 'relative',
                transition: 'all 0.5s',
                ...(selectedAdditionalGeofence === index
                  ? { boxShadow: 3, bgcolor: 'primary.light' }
                  : {}),
              }}
            >
              <IconButton
                sx={{ position: 'absolute', top: '-20px', right: '-20px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (selectedAdditionalGeofence !== null) {
                    if (index === 0 || index === selectedAdditionalGeofence) {
                      setSelectedAdditionalGeofence(null);
                    }
                    if (index < selectedAdditionalGeofence) {
                      setSelectedAdditionalGeofence(selectedAdditionalGeofence - 1);
                    }
                  }
                  remove(index);
                }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <AutocompleteFormField
                control={control}
                disabled={
                  watchedLatLon.length === 0 ||
                  watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT
                }
                errors={errors}
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${t('form_fields.geo_fence_type')}`}
                list={
                  watchedAdditionalGeofences[index].geofenceType?.id ===
                  GeoFenceTypes.EQUIPMENT
                    ? [geofenceEquipmentOption]
                    : [geoFenceCircleOption, geofencePolygonOption]
                }
                name={`additionalGeofences.${index}.geofenceType`}
              />
              {watchedAdditionalGeofences[index].geofenceType?.id ===
                GeoFenceTypes.EQUIPMENT && (
                <AutocompleteFormField
                  clearable={true}
                  control={control}
                  disabled
                  errors={errors}
                  getLabel={(item) => item._name || ''}
                  getValue={(item) => item._id}
                  label={`${t('form_fields.available_equipment')}`}
                  loading={isLoadingEquipment}
                  list={equipmentOptions}
                  name={`additionalGeofences.${index}.equipment`}
                  noOptionsText={t('form_fields.no_options')}
                />
              )}
              {(watchedAdditionalGeofences[index].geofenceType?.id ===
                GeoFenceTypes.CIRCLE ||
                watchedAdditionalGeofences[index].geofenceType?.id ===
                  GeoFenceTypes.EQUIPMENT) && (
                <TextFormField
                  control={control}
                  errors={errors}
                  label={`${t('form_fields.radius')} (${t('units_of_distance.feet')})`}
                  name={`additionalGeofences.${index}.radius`}
                  type="number"
                />
              )}
              <AutocompleteFormField
                clearable={true}
                control={control}
                errors={errors}
                getLabel={(item) => item?.name}
                getValue={(item) => item?.id}
                label={`${t('form_fields.tag')}`}
                list={tagOptions}
                name={`additionalGeofences.${index}.tag`}
              />
            </Box>
          );
        })}
        {showAddMoreGeofenceButton && (
          <Box sx={{ gridColumn: '1 / -1' }}>
            <Button
              variant="text"
              color="info"
              size="small"
              disabled={!isValid}
              onClick={() => {
                append({
                  geofenceId: null,
                  geofenceType:
                    watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT
                      ? geofenceEquipmentOption
                      : null,
                  radius: null,
                  equipment:
                    watchedGeofenceType?.id === GeoFenceTypes.EQUIPMENT
                      ? watchedEquipment
                      : null,
                  drawingCoordinates: [],
                  tag: null,
                });
              }}
              sx={{ textDecoration: 'underline' }}
            >
              {t('administration.site.add_geofence')}
            </Button>
          </Box>
        )}
        <TextFormField
          control={control}
          errors={errors}
          label={`${t('form_fields.notes')}`}
          multiline={true}
          name="notes"
          rows={4}
          sx={{ gridColumn: '1 / -1', mt: 2 }}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap,
          gridTemplateColumns: '1fr auto auto',
          gridTemplateRows: 'auto 500px 1fr',
        }}
      >
        <AutocompleteAsyncFormField
          asyncCallback={getPlaces}
          clearable={true}
          control={control}
          debounceTime={500}
          errors={errors}
          getLabel={(item) => get(item, 'streetAddress', '')}
          getValue={(item) => get(item, 'placeId', '')}
          onSelect={(selectedAddress: AddressItem) => {
            getAddressItemByGoogleMapsPlaceId(selectedAddress.placeId).then((address) => {
              setValue('address', address);
            });
          }}
          placeholder={`${t('common.address_autocomplete_hint')}`}
          label={`${t('form_fields.address')}`}
          name="address"
        />
        <TextFormField
          isRequired
          control={control}
          disabled={false}
          errors={errors}
          label={`${t('form_fields.coordinates')}`}
          name="latLon"
          placeholder={`${t('form_fields.lat_lon_placeholder')}`}
          type="text"
        />
        <BasicTooltip title={t('administration.site.set_position_from_address')}>
          <Button
            color="inherit"
            size="small"
            onClick={resetLatLon}
            sx={{ p: 0, mt: '18px', height: 40 }}
          >
            <Refresh sx={{ width: 14, height: 14 }} />
          </Button>
        </BasicTooltip>
        <Box sx={{ gridColumn: '1 / -1' }}>
          <MapV2
            center={initialMapCenter}
            markers={locationMarkers as ComponentProps<typeof MapV2>['markers']}
            drawingMode={
              selectedAdditionalGeofence === null
                ? watchedGeofenceType?.id === GeoFenceTypes.POLYGON
                : watchedAdditionalGeofences[selectedAdditionalGeofence].geofenceType
                    ?.id === GeoFenceTypes.POLYGON
            }
            drawingCoordinates={
              selectedAdditionalGeofence === null
                ? watchedDrawingCoordinates
                : watchedAdditionalGeofences[selectedAdditionalGeofence]
                    .drawingCoordinates
            }
            emptyText={`${t('navigation.choose_address')}`}
            onPolygonEdit={(newCoordinates) => {
              if (selectedAdditionalGeofence === null) {
                setValue('drawingCoordinates', newCoordinates, { shouldDirty: true });
              } else {
                update(selectedAdditionalGeofence, {
                  ...watchedAdditionalGeofences[selectedAdditionalGeofence],
                  drawingCoordinates: newCoordinates,
                });
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
});
