import Box from '@mui/material/Box';
import { t } from 'i18next';
import { ReactNode, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  DEFAULT_TAB,
  StatusTabValue,
} from '~components/Filters/DispatchV3OrdersStatusFilter';
import { CommonLoader } from '~components/Loaders/CommonLoader';
import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { DispatchURLParams, OrdersDispatchStorageKeys } from '~constants/dispatch';
import DriversView from '~pages/Dispatch/components/drivers/DriversView';
import theme from '~theme/AppTheme';

import CalendarView from '../components/calendar/CalendarView';
import OrdersView from '../components/OrdersView';
import { INewDispatchViewComponent } from '../NewDispatch';

export enum DispatchV3View {
  ORDERS = 'orders',
  DRIVERS = 'drivers',
  CALENDAR = 'calendar',
}

const AVAILABLE_VIEWS: TabProps<DispatchV3View>[] = [
  { label: t('dispatch.dispatch_v2.orders'), value: DispatchV3View.ORDERS },
  { label: t('dispatch.dispatch_v2.calendar_tab'), value: DispatchV3View.CALENDAR },
  { label: t('dispatch.dispatch_v2.drivers'), value: DispatchV3View.DRIVERS },
];
const DEFAULT_VIEW = DispatchV3View.ORDERS;

const getInitialView = (searchParams: URLSearchParams) => {
  const viewParam = searchParams.get(DispatchURLParams.VIEW) as DispatchV3View;
  const storedView = localStorage.getItem(
    OrdersDispatchStorageKeys.VIEW_PARAM,
  ) as DispatchV3View;
  const view = viewParam || storedView;

  return Object.values(DispatchV3View).includes(view) ? view : DEFAULT_VIEW;
};

const getInitialStatusTab = (searchParams: URLSearchParams) => {
  const tabParam = searchParams.get(DispatchURLParams.TAB) as StatusTabValue;
  const storedTab = localStorage.getItem(
    OrdersDispatchStorageKeys.TAB_PARAM,
  ) as StatusTabValue;
  const tab = tabParam || storedTab;

  return Object.values(StatusTabValue).includes(tab) ? tab : DEFAULT_TAB;
};

function DispatchV3Provider() {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get(DispatchURLParams.VIEW) as DispatchV3View;
  const isValidView = Object.values(DispatchV3View).includes(view);

  const isLoading = !isValidView;

  const handleSelectedViewChange = useCallback(
    (view: DispatchV3View) => {
      setSearchParams((params) => {
        params.set(DispatchURLParams.VIEW, view);

        return params;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (isValidView) {
      localStorage.setItem(OrdersDispatchStorageKeys.VIEW_PARAM, view);
    }
  }, [isValidView, view]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set(DispatchURLParams.VIEW, getInitialView(params));
      params.set(DispatchURLParams.TAB, getInitialStatusTab(params));

      return params;
    });
  }, [setSearchParams]);

  if (isLoading) {
    return <CommonLoader />;
  }

  return <DispatchV3 onSelectedViewChange={handleSelectedViewChange} view={view} />;
}

export interface IDispatchV3ViewComponent extends INewDispatchViewComponent {
  viewSelector?: ReactNode;
}

interface DispatchV3Props {
  onSelectedViewChange: (view: DispatchV3View) => void;
  view: DispatchV3View;
}

const viewComponentByDispatchV3View: Record<
  DispatchV3View,
  (props: IDispatchV3ViewComponent) => JSX.Element
> = {
  [DispatchV3View.ORDERS]: OrdersView,
  [DispatchV3View.DRIVERS]: DriversView,
  [DispatchV3View.CALENDAR]: CalendarView,
};

function DispatchV3({ onSelectedViewChange, view }: DispatchV3Props) {
  const View = viewComponentByDispatchV3View[view];

  return (
    <Box
      sx={{
        bgcolor: theme.brandV2.colors.treadGray8,
        display: 'grid',
        gridAutoRows: 'auto auto 1fr',
        gridTemplateColumns: 'auto 1fr',
        height: '100%',
        mx: -5,
        width: `calc(100% + ${theme.spacing(5 * 2)})`,
      }}
    >
      <View
        viewSelector={
          <Tabs onChange={onSelectedViewChange} selected={view} tabs={AVAILABLE_VIEWS} />
        }
      >
        {/* FIXME @todo Dummy render to statisty the INewDispatchViewComponent contract. */}
        {/* Remove once Dispatch v3 fully rolled out. */}
        {() => <></>}
      </View>
    </Box>
  );
}

export default DispatchV3Provider;
