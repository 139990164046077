import WarningRounded from '@mui/icons-material/WarningRounded';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { OrderState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { ReactNode } from 'react';
import React from 'react';

import {
  Column,
  FlexColumn,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Order } from '~hooks/useOrders';
import { WayPoint } from '~hooks/useSites';
import {
  BulkAssignMultipleCTA,
  CreatedTime,
  CustomerName,
  EditOrderCTA,
  InternalNotes,
  OrderNotes,
  QuantityAccepted,
  QuantityDelivered,
  Requester,
  ServiceType,
  Status,
  Subtitle,
  ThreeDotsMenu,
  Title,
} from '~pages/Dispatch/components/OrderDispatchCardFragments';
import { GeofenceStartAdornment } from '~pages/Settings/Administration/Sites/components/GeofenceStartAdornment';
import { rootStore } from '~store';
import theme from '~theme/AppTheme';

export enum OrdersDispatchColumnKey {
  CHECKBOX = 'checkbox',
  DISPATCH = 'dispatch',
  COMPANY = 'company',
  TRUCKS_AND_TYPES = 'trucksandtypes',
  FOR = 'for',
  START = 'start',
  STATUS = 'status',
  WAYPOINTS = 'waypoints',
  MATERIAL = 'material',
  CYCLE = 'cycle',
  ALERTS = 'alerts',
  ACTIONS = 'actions',
}

export const orderDispatchColumns: Record<
  OrdersDispatchColumnKey,
  { title: ReactNode; size: string; sx?: SxProps; condition?: () => boolean }
> = {
  [OrdersDispatchColumnKey.CHECKBOX]: {
    title: '',
    size: '20px',
    sx: { px: 0 },
  },
  [OrdersDispatchColumnKey.DISPATCH]: {
    title: t('dispatch.dispatch_v2.dispatch_ext_id'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.COMPANY]: {
    title: t('dispatch.dispatch_v2.company'),
    size: '2fr',
    condition: () => rootStore?.userStore?.userCompanies?.length > 1 || false,
  },
  [OrdersDispatchColumnKey.FOR]: {
    title: t('dispatch.dispatch_v2.for_and_customer'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.TRUCKS_AND_TYPES]: {
    title: t('dispatch.dispatch_v2.trucks_and_type'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.START]: {
    title: t('dispatch.dispatch_v2.start'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.CYCLE]: {
    title: t('dispatch.dispatch_v2.cycle_time'),
    size: '80px',
  },
  [OrdersDispatchColumnKey.STATUS]: {
    title: t('form_fields.status'),
    size: '105px',
    sx: { display: 'flex', gap: theme.spacing(0.5), px: 0 },
  },
  [OrdersDispatchColumnKey.WAYPOINTS]: {
    title: t('dispatch.dispatch_v2.pick_up_and_drop_off'),
    size: '2fr',
  },
  [OrdersDispatchColumnKey.MATERIAL]: {
    title: t('dispatch.dispatch_v2.material_delivered'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.ALERTS]: {
    title: t('actions.alerts'),
    size: '1fr',
  },
  [OrdersDispatchColumnKey.ACTIONS]: {
    title: '',
    size: '120px',
    sx: { display: 'flex', justifyContent: 'flex-end', px: 0 },
  },
} as const;

export const makeOrderDispatchCardTemplateColumns = (
  isSidebarCollapsed: boolean = false,
  isDispatchV3: boolean = false,
) => {
  if (isDispatchV3) {
    if (isSidebarCollapsed) {
      return ['0px', '1fr', 'auto'].join(' ');
    }

    return [
      orderDispatchColumns[OrdersDispatchColumnKey.CHECKBOX].size,
      '1fr',
      'auto',
    ].join(' ');
  }

  return Object.entries(orderDispatchColumns)
    .filter(([, { condition }]) => !condition || condition())
    .map(([key, { size }]) => {
      if (isSidebarCollapsed && key === OrdersDispatchColumnKey.CHECKBOX) {
        return '0px';
      }

      return size;
    })
    .join(' ');
};

interface OrderDispatchColumnProps {
  order: Order;
}

interface CheckboxColumnProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  onCheckedStateChange?: () => void;
}

export function CheckboxColumn({
  isChecked,
  isDisabled,
  onCheckedStateChange,
}: CheckboxColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.CHECKBOX}>
      <Checkbox
        size="small"
        sx={{
          '&.MuiCheckbox-root': {
            color: theme.brandV2.colors.treadGray6,
            mt: '-10px',
            p: 0,
          },
          '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
          '&.Mui-disabled': { pointerEvents: 'auto' },
        }}
        disabled={Boolean(isDisabled)}
        checked={Boolean(isChecked)}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          onCheckedStateChange?.();
        }}
      />
    </Column>
  );
}

export function DispatchColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.DISPATCH}>
      <FlexColumn>
        <OverflowAwareText fontWeight={700} minHeight="18px">
          {order.dispatchNumber}
        </OverflowAwareText>

        <OverflowAwareText>{order?.project?.externalId}</OverflowAwareText>

        <ServiceType order={order} />
      </FlexColumn>
    </Column>
  );
}
export function CompanyColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.COMPANY}>
      <FlexColumn>
        <OverflowAwareText fontWeight={700} minHeight="18px">
          {order.company?.legalName}
        </OverflowAwareText>
      </FlexColumn>
    </Column>
  );
}

export function ForColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.FOR}>
      <FlexColumn>
        <CustomerName order={order} />
        <Requester order={order} />
      </FlexColumn>
    </Column>
  );
}

export const TrucksAndTypeColumn = ({ order }: OrderDispatchColumnProps) => {
  const trucksCount = order?.orderSummary?.jobsCount ?? 0;
  const equipmentType = order.equipmentType?.name;
  return (
    <Column columnKey={OrdersDispatchColumnKey.TRUCKS_AND_TYPES}>
      <FlexColumn>
        <OverflowAwareText>
          {t('dispatch.dispatch_v2.trucks_count', { count: trucksCount })}
        </OverflowAwareText>
        <OverflowAwareText>{equipmentType}</OverflowAwareText>
      </FlexColumn>
    </Column>
  );
};

export function StartColumn({ order }: OrderDispatchColumnProps) {
  let startTime = null;
  let startDate = null;

  if (order.loadAt) {
    startTime = dayjs.tz(order.loadAt).format('hh:mm A');
    startDate = dayjs.tz(order.loadAt).format('DD-MMM-YYYY');
  }

  return (
    <Column columnKey={OrdersDispatchColumnKey.START}>
      {(startTime || startDate) && (
        <FlexColumn>
          {startTime && <OverflowAwareText>{startTime}</OverflowAwareText>}
          {startDate && <OverflowAwareText>{startDate}</OverflowAwareText>}
        </FlexColumn>
      )}
    </Column>
  );
}

interface StatusColumnProps extends OrderDispatchColumnProps {
  onAccept: () => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onReject: () => void;
}

export function StatusColumn(props: StatusColumnProps) {
  return <Status {...props} />;
}

export const extractWaypointData = (waypoint?: WayPoint) => {
  if (!waypoint?.siteNested) {
    return null;
  }

  const name = waypoint.siteNested.name;
  const address = waypoint.siteNested.address?.thoroughfare;
  const geofenceType = waypoint.siteNested.nextBillionGeofence?.geofenceType;

  return { name, address, geofenceType };
};

export function WaypointsColumn({ order }: OrderDispatchColumnProps) {
  const pickUp = extractWaypointData(order.waypoints?.[0]);
  const dropOff = extractWaypointData(order.waypoints?.[1]);

  return (
    <Column columnKey={OrdersDispatchColumnKey.WAYPOINTS}>
      {pickUp && dropOff && (
        <FlexColumn>
          {[pickUp, dropOff].map((site, index) => (
            <OverflowAwareText key={index}>
              <Typography component="span" variant="subtitle2" display={'flex'}>
                <GeofenceStartAdornment
                  geofenceType={site.geofenceType}
                  isLoading={false}
                  sx={{ pr: 0.5 }}
                />
                {site.name}
              </Typography>

              {site.address && (
                <Typography
                  color={theme.brandV2.colors.treadGray2}
                  component="span"
                  fontWeight={400}
                  variant="subtitle2"
                >
                  {` ${site.address}`}
                </Typography>
              )}
            </OverflowAwareText>
          ))}
        </FlexColumn>
      )}
    </Column>
  );
}

export function MaterialColumn({ order }: OrderDispatchColumnProps) {
  const { numberFormatter } = useCompanyCurrency();

  const { orderSummary, material } = order;
  const deliveredQuantity =
    orderSummary?.deliveredQuantities.find(
      (deliveredQuantity) => deliveredQuantity.materialName === material?.name,
    ) || orderSummary?.deliveredQuantities[0];
  const delivered = `${numberFormatter(deliveredQuantity?.delivered ?? 0)} ${t('common.of')} ${numberFormatter(deliveredQuantity?.total ?? 0)} ${deliveredQuantity?.unitOfMeasure}`;

  return (
    <Column columnKey={OrdersDispatchColumnKey.MATERIAL}>
      {material?.name && (
        <FlexColumn>
          <OverflowAwareText>{material?.name}</OverflowAwareText>
          {deliveredQuantity && <OverflowAwareText>{delivered}</OverflowAwareText>}
        </FlexColumn>
      )}
    </Column>
  );
}

export function CycleColumn({ order }: OrderDispatchColumnProps) {
  const { loadCycleAvg } = order;

  const hours = Math.floor(
    dayjs.duration({ minutes: loadCycleAvg }).asHours(),
  ).toString();
  const minutes = dayjs
    .duration({ minutes: loadCycleAvg % 60 })
    .asMinutes()
    .toString();
  const formatted = `${hours}hr ${minutes}m`;

  return (
    <Column columnKey={OrdersDispatchColumnKey.CYCLE}>
      {Boolean(loadCycleAvg) && (
        <Box display="flex" justifyContent="center">
          <OverflowAwareText>{formatted}</OverflowAwareText>
        </Box>
      )}
    </Column>
  );
}

interface AlertsColumnProps extends OrderDispatchColumnProps {
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
}

export function AlertsColumn({
  nonRoutableAlertsFeatureFlagEnabled,
  order,
}: AlertsColumnProps) {
  let hasNonRoutableSites = false;

  if (nonRoutableAlertsFeatureFlagEnabled && order.waypoints) {
    hasNonRoutableSites = order.waypoints.some((waypoint) => {
      return !waypoint.siteNested?.routable;
    });
  }

  let alertMessage = '';

  if (hasNonRoutableSites) {
    alertMessage = t('common.address_confirmation_required');
  } else if (order.actionRequired) {
    alertMessage = t('dispatch.order.pending_jobs');
  }

  return (
    <Column columnKey={OrdersDispatchColumnKey.ALERTS}>
      {alertMessage ? (
        <Box display="flex" justifyContent="center">
          <BasicTooltip title={alertMessage}>
            <WarningRounded sx={{ color: theme.brandV2.colors.treadOrange }} />
          </BasicTooltip>
        </Box>
      ) : null}
    </Column>
  );
}

interface ActionsColumnProps extends OrderDispatchColumnProps {
  onBulkAssignMultiple?: () => void;
  onCancel: () => void;
  onCloneOrder: () => void;
  onEdit: () => void;
  onTextAllDrivers: () => void;
}

export function ActionsColumn({
  onBulkAssignMultiple,
  onCancel,
  onCloneOrder,
  onEdit,
  onTextAllDrivers,
  order,
}: ActionsColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.ACTIONS}>
      <FlexColumn>
        <Box display="flex">
          <EditOrderCTA order={order} onClick={onEdit} />

          <BulkAssignMultipleCTA order={order} onClick={onBulkAssignMultiple} />

          <ThreeDotsMenu
            onCancelClick={onCancel}
            onCloneOrderClick={onCloneOrder}
            onTextAllDriversClick={onTextAllDrivers}
            order={order}
          />
        </Box>

        <OverflowAwareText sx={{ textAlign: 'right' }}>{order.orderId}</OverflowAwareText>
      </FlexColumn>
    </Column>
  );
}

export function DispatchV3MainColumn({ order }: OrderDispatchColumnProps) {
  return (
    <Column columnKey={OrdersDispatchColumnKey.DISPATCH}>
      <FlexColumn>
        <Title order={order} />
        <Subtitle order={order} />
      </FlexColumn>
    </Column>
  );
}

interface DispatchV3ActionsColumnProps extends OrderDispatchColumnProps {
  onAccept: () => void;
  onBulkAssignMultiple?: () => void;
  onCancel: () => void;
  onCloneOrder: () => void;
  onEdit: () => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onReject: () => void;
  onTextAllDrivers: () => void;
}

export function DispatchV3ActionsColumn({
  onAccept,
  onBulkAssignMultiple,
  onCancel,
  onCloneOrder,
  onEdit,
  onOrderStateChange,
  onReject,
  onTextAllDrivers,
  order,
}: DispatchV3ActionsColumnProps) {
  return (
    <Column
      columnKey={OrdersDispatchColumnKey.ACTIONS}
      sx={{ display: 'flex', alignItems: 'flex-start' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Status
          onAccept={onAccept}
          onOrderStateChange={onOrderStateChange}
          onReject={onReject}
          order={order}
        />

        <BulkAssignMultipleCTA order={order} onClick={onBulkAssignMultiple} />

        <EditOrderCTA order={order} onClick={onEdit} />

        <ThreeDotsMenu
          onCancelClick={onCancel}
          onCloneOrderClick={onCloneOrder}
          onTextAllDriversClick={onTextAllDrivers}
          order={order}
        />
      </Box>
    </Column>
  );
}

export function DispatchV3BottomColumn({ order }: OrderDispatchColumnProps) {
  const NotesWrapper = ({ children }: React.PropsWithChildren) => (
    <Box sx={{ flex: 0, minWidth: 200 }}>{children}</Box>
  );
  const orderNotesComponent = <OrderNotes order={order} Wrapper={NotesWrapper} />;
  const internalNotesComponent = <InternalNotes order={order} Wrapper={NotesWrapper} />;

  return (
    <Column
      columnKey={OrdersDispatchColumnKey.DISPATCH}
      sx={{
        borderTop: `solid 1px ${theme.brandV2.colors.treadGray7}`,
        display: 'flex',
        gap: 5,
        gridColumn: 'span 3',
        mx: -1,
        pl: `calc(${orderDispatchColumns[OrdersDispatchColumnKey.CHECKBOX].size} + ${theme.spacing(2)})`,
        pr: 2,
        pt: 1,
      }}
    >
      <FlexColumn>
        <CustomerName
          order={order}
          sx={{ color: theme.brandV2.colors.treadBlue, fontWeight: 600 }}
        />
        <ServiceType order={order} />
      </FlexColumn>

      <FlexColumn>
        <Requester order={order} />
        <CreatedTime order={order} />
      </FlexColumn>

      {orderNotesComponent}
      {internalNotesComponent}

      <QuantityAccepted order={order} />

      <QuantityDelivered order={order} />
    </Column>
  );
}
