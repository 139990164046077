import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { useMemo } from 'react';

import { Order } from '~hooks/useOrders';
import { Service } from '~hooks/useServices';
import { serviceIsStandingHaul } from '~pages/Sales/Orders/newOrderFormSchema';
import theme from '~theme/AppTheme';

const PROGRESS_BAR_WIDTH_IN_PX = 72;

export const formatNumber = (input: number | string) => {
  const formattedNumber = Number(input).toFixed(2);

  return formattedNumber.replace(/(.00)$/, '');
};

interface OrderSummaryProps {
  order: Order;
}

export default function OrderSummary({ order }: OrderSummaryProps) {
  const groups = useMemo(() => {
    const { orderSummary, material, service } = order;

    const jobsCount = orderSummary?.jobsCount ?? 0;
    const assignedJobsCount = orderSummary?.assignedJobsCount ?? 0;
    const sentJobsCount = orderSummary?.sentJobsCount ?? 0;
    const acceptedJobsCount = orderSummary?.acceptedJobsCount ?? 0;
    const startedJobsCount = orderSummary?.startedJobsCount ?? 0;
    const deliveredLoadsCount = orderSummary?.deliveredLoadsCount ?? 0;
    const loadsCount = orderSummary?.loadsCount ?? 0;
    const deliveredQuantity =
      orderSummary?.deliveredQuantities.find(
        (deliveredQuantity) => deliveredQuantity.materialName === material?.name,
      ) || orderSummary?.deliveredQuantities[0];

    const isStandingHaul = serviceIsStandingHaul(service as Service);

    return [
      [
        {
          label: t('dispatch.dispatch_v2.order_summary.assigned.label'),
          count: assignedJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.assigned.description', {
            num: formatNumber(assignedJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.sent.label'),
          count: sentJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.sent.description', {
            num: formatNumber(sentJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.accepted.label'),
          count: acceptedJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.accepted.description', {
            num: formatNumber(acceptedJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
      ],
      [
        {
          label: t('dispatch.dispatch_v2.order_summary.trucks_live.label'),
          count: startedJobsCount,
          total: jobsCount,
          description: t('dispatch.dispatch_v2.order_summary.trucks_live.description', {
            num: formatNumber(startedJobsCount),
            total: formatNumber(jobsCount),
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.quantity.label'),
          count: Number(deliveredQuantity?.delivered) ?? 0,
          total: Number(deliveredQuantity?.total) ?? 0,
          description: t('dispatch.dispatch_v2.order_summary.quantity.description', {
            num: formatNumber(deliveredQuantity?.delivered ?? 0),
            total: formatNumber(deliveredQuantity?.total ?? 0),
            unit: deliveredQuantity?.unitOfMeasure,
          }),
        },
        {
          label: t('dispatch.dispatch_v2.order_summary.loads_delivered.label'),
          count: deliveredLoadsCount,
          total: loadsCount,
          description: t(
            'dispatch.dispatch_v2.order_summary.loads_delivered.description',
            {
              num: formatNumber(deliveredLoadsCount),
              total: isStandingHaul ? t('common.running') : formatNumber(loadsCount),
            },
          ),
        },
      ],
    ] as [SummaryProps[], SummaryProps[]];
  }, [order]);

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      gap={20}
      justifyContent="space-around"
      px={5}
      py={2}
    >
      {groups.map((group, index) => (
        <Box
          key={index}
          alignItems="flex-start"
          display="flex"
          flex={1}
          gap={1}
          justifyContent="space-around"
        >
          {group.map((item) => (
            <Summary key={item.label} {...item} />
          ))}
        </Box>
      ))}
    </Box>
  );
}

interface SummaryProps {
  count?: number;
  description: string;
  descriptionSx?: SxProps;
  label?: string;
  total?: number;
}

export function Summary({
  count,
  description,
  descriptionSx,
  label,
  total,
}: SummaryProps) {
  const hasProgressIndicator = !_.isUndefined(count) && !_.isUndefined(total);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mb: 0.5,
        minWidth: PROGRESS_BAR_WIDTH_IN_PX,
      }}
    >
      {label && (
        <Typography
          color={theme.brandV2.colors.treadGray2}
          fontSize="12px"
          whiteSpace="nowrap"
        >
          {label}
        </Typography>
      )}

      <Typography
        variant="subtitle2"
        sx={{
          color: theme.brandV2.colors.treadBlack,
          mb: 1,
          whiteSpace: 'nowrap',
          ...descriptionSx,
        }}
      >
        {description}
      </Typography>

      {hasProgressIndicator && (
        <Box
          bgcolor={theme.brandV2.colors.treadGray7}
          borderRadius="2px"
          height="4px"
          overflow="hidden"
          position="relative"
          width={PROGRESS_BAR_WIDTH_IN_PX}
          sx={{
            '&:before': {
              backgroundColor: theme.brandV2.colors.treadBlue,
              bottom: 0,
              content: "''",
              left: 0,
              position: 'absolute',
              top: 0,
              width: `calc((${count} / ${total}) *  100%)`,
            },
          }}
        />
      )}
    </Box>
  );
}
