/*
 * Each of these exported consts is a repeat of the enums below. Consider consolidating
 * into the enums below.
 */
export const companyConnectAccountByTreadId = 'company_connect_account_by_tread_id';
export const haulerRateTypeField = 'hauler_rate_type_field';

export enum FeatureFlags {
  agaveIntegrationEnabled = 'agave_integration_enabled',
  assignJobModal = 'assign_job_modal',
  bulkAssignMultiple = 'bulk_assign_multiple',
  companyConnectAccountByTreadId = 'company_connect_account_by_tread_id',
  dispatchV3 = 'dispatch_v3',
  driverAssignmentPopover = 'driver_assignment_popover_enabled',
  driverDay = 'driver-day',
  driverEquipmentEnhancements = 'driver_equipment_enhancements',
  driverPanelEnabled = 'driver_panel_enabled',
  driverPay = 'driver-pay',
  estimatedLoadQuantities = 'estimated_load_qtys',
  extendedDispatchFilters = 'extended_dispatch_filters',
  googleMapsLiveMapV2 = 'google_maps_live_map_v2',
  googleMapsPrimary = 'google_maps_primary',
  googleMapsSiteModification = 'google_maps_site_modification',
  haulerRateTypeField = 'hauler_rate_type_field',
  hybridBaseMap = 'hybrid_base_map',
  jobFormHiredTrucks = 'job_form_hired_trucks',
  manageEquipmentTypes = 'manage_equipment_types',
  manageTelematicsIntegrations = 'manage_telematics_integrations',
  newLiveMapScrubber = 'live_map_scrubber_v2',
  nonRoutableAlerts = 'non_routable_alerts',
  omniReportFeatureFlag = 'omni_reports',
  persistDispatchFilters = 'persist_dispatch_filters',
  projectPhase = 'project_phase_enabled',
  serviceClass = 'service_class',
  settlements = 'settlements',
  sharedLiveMapComponent = 'shared_live_map_component',
  showEquipmentHoursInChargesTable = 'show_equipment_hours_in_charges_table',
  showPhaseColumninChargesTable = 'show_phase_column_in_charges_table',
  simplifiedSettingsTabs = 'simplified_settings_tabs',
  summaryCounts = 'summary_counts',
  switchAssigneeTabsOrder = 'switch_assignee_tabs_order',
  treadLivePage = 'tread_live_page',
  unifiedEventTimeline = 'unified_event_timeline',
  vendorAccountActionMenu = 'vendor_account_action_menu',
}
