// Const phoneNumberRegex =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
import { t } from 'i18next';
import * as yup from 'yup';

import { matchIsValidTelOrTestTel } from '~utils/utilFunctions';

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+(\.[A-Z|a-z]{2,})+$/;
const nameRegex = /^(|^[a-zA-Z0-9À-ÖÙ-öù-ÿĀ-žḀ-ỿ\s-]+)$/;

const personNameSchema = (field: string) =>
  yup.string().matches(
    nameRegex,
    `${t('form_validation_errors.invalid', {
      field,
    })}`,
  );

const nameIdSchemaRequired = yup.object().shape({
  name: yup.string().required(),
  id: yup.string().required(),
});
export type NameIdSchemaRequired = yup.InferType<typeof nameIdSchemaRequired>;

const nameIdNonRequiredSchema = yup.object().shape({
  name: yup.string().notRequired(),
  id: yup.string().notRequired(),
});
const nameIdTypeSchema = yup.object().shape({
  name: yup.string(),
  id: yup.string(),
  type: yup.string(),
});
const notesSchema = yup.string();
const phoneSchema = yup
  .string()
  .test('is-valid-phone', `${t('form_validation_errors.invalid_phone')}`, (value) => {
    if (!value) return true;
    return matchIsValidTelOrTestTel(value || '');
  });

const notRequiredPhoneSchema = yup
  .string()
  .notRequired()
  .test('is-valid-phone', `${t('form_validation_errors.invalid_phone')}`, (value) => {
    if (!value?.trim()) return true;
    return matchIsValidTelOrTestTel(value || '');
  });

const notRequiredEmailSchema = yup
  .string()
  .notRequired()
  .matches(emailRegex, {
    excludeEmptyString: true,
    message: `${t('form_validation_errors.invalid_email')}`,
  });

const contactSchema = yup
  .object()
  .shape({
    name: personNameSchema(`${t('form_fields.name')}`).notRequired(),
    phone: phoneSchema.notRequired(),
  })
  .nullable()
  .notRequired();

const siteSchema = yup.object().shape({
  name: yup.string().required(),
  id: yup.string(),
  lat: yup.number().required(),
  lon: yup.number().required(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string(),
      placeId: yup.string(),
    })
    .notRequired(),
});

const optionalSiteSchema = yup.object().shape({
  name: yup.string().notRequired(),
  id: yup.string(),
  lat: yup.number().notRequired(),
  lon: yup.number().notRequired(),
  address: yup
    .object()
    .shape({
      streetAddress: yup.string(),
      placeId: yup.string(),
    })
    .notRequired(),
});

const waypointSchema = yup.object().shape({
  id: yup.string(),
  type: yup.string(),
  ordinality: yup.number(),
  site: siteSchema,
  contact: contactSchema,
});

const waypointSchemaOptionalSites = yup.object().shape({
  id: yup.string(),
  type: yup.string(),
  ordinality: yup.number(),
  site: optionalSiteSchema,
  contact: contactSchema,
});

export type WaypointSchema = yup.InferType<typeof waypointSchema>;
export type WaypointSchemaOptionalSites = yup.InferType<
  typeof waypointSchemaOptionalSites
>;

const latLonSchema = yup
  .string()
  .matches(
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    'Invalid latitude or longitude format',
  )
  .test({
    name: 'lat-lon-range',
    message: 'Invalid latitude or longitude range',
    test: function (value) {
      if (!value) return false;
      const [lat, lon] = value.split(',').map(parseFloat);
      return lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
    },
  });

export {
  emailRegex,
  latLonSchema,
  nameIdNonRequiredSchema,
  nameIdSchemaRequired,
  nameIdTypeSchema,
  notesSchema,
  notRequiredEmailSchema,
  notRequiredPhoneSchema,
  personNameSchema,
  phoneSchema,
  waypointSchema,
  waypointSchemaOptionalSites,
};
