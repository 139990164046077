import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import TreadLiveMapV2 from '~components/Maps/v2/TreadLiveMapV2';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { FeatureFlags } from '~constants/featureFlags';
import { Order } from '~hooks/useOrders';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

import { doneOrderStates } from '../constants/orderStates';
import { OrderDetails } from '../OrderDetails';
import { isCurrentPageDispatchV3 } from '../v3/utils';
import JobsTable from './JobsTable';
import OrderNotes from './OrderNotes';

interface OrderDispatchCardExpandedBlockProps {
  checkedJobs?: string[];
  onCheckedJobStateChange?: (jobId: string) => void;
  onCreateJobFromOrder: () => void;
  order: Order;
}

enum OrderDispatchCardExpandedBlockTab {
  JOBS,
  ORDER_DETAILS,
  LIVE_MAP,
}

export default function OrderDispatchCardExpandedBlock({
  checkedJobs,
  onCheckedJobStateChange,
  onCreateJobFromOrder,
  order,
}: OrderDispatchCardExpandedBlockProps) {
  const { userStore } = useStores();
  const [selectedTab, setSelectedTab] = useState(OrderDispatchCardExpandedBlockTab.JOBS);

  const isDispatchV3Page = isCurrentPageDispatchV3();

  const showLiveMapV2 = useFeatureFlag({
    featureFlagKey: FeatureFlags.googleMapsLiveMapV2,
  });

  const { canCreateJob } = userStore.getPermissions();
  const canAddNewJobs =
    order.editable && !doneOrderStates.includes(order.state) && canCreateJob;

  const handleSelectedTabChange = useCallback(
    (__: React.SyntheticEvent, value: OrderDispatchCardExpandedBlockTab) => {
      setSelectedTab(value);
    },
    [],
  );

  return (
    <Box>
      {isDispatchV3Page ? null : <OrderNotes order={order} />}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          px: 1,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleSelectedTabChange}
          sx={{
            '&.MuiTabs-root': { minHeight: 'auto', px: 1, mt: 2 },
            '& .MuiTabs-flexContainer': { gap: 1 },
            '& .MuiTabs-indicator': {
              backgroundColor: theme.brandV2.colors.treadBlack,
              borderRadius: 0,
              height: '2px',
            },
            '& .MuiTab-root': {
              fontSize: '12px',
              lineHeight: '32px',
              minHeight: 'auto',
              minWidth: 'auto',
              px: 1,
              py: 0,
              '&.Mui-selected': {
                color: theme.brandV2.colors.treadBlack,
              },
            },
          }}
        >
          <Tab label={t('form_fields.jobs')} />
          <Tab label={t('order.form.order_details')} />
          <Tab label={t('live_map.live_map')} />
        </Tabs>

        <Box>
          {canAddNewJobs && (
            <SmallButton
              color="brandV2Orange"
              variant="text"
              startIcon={<AddCircleOutline />}
              onClick={(event) => {
                event.stopPropagation();
                onCreateJobFromOrder();
              }}
              sx={{
                '&.MuiButtonBase-root': {
                  border: 'solid 1px transparent',
                  px: 1,
                  '.MuiButton-startIcon': { mr: 0.5 },
                  '.MuiSvgIcon-root': { fontSize: '16px' },
                  '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                },
              }}
            >
              {t('form_fields.job')}
            </SmallButton>
          )}
        </Box>
      </Box>

      <Box>
        {selectedTab === OrderDispatchCardExpandedBlockTab.JOBS && (
          <JobsTable
            order={order}
            checkedJobs={checkedJobs}
            onCheckedStateChange={onCheckedJobStateChange}
          />
        )}

        {selectedTab === OrderDispatchCardExpandedBlockTab.ORDER_DETAILS && (
          <Box p={1}>
            <OrderDetails order={order} />
          </Box>
        )}

        {selectedTab === OrderDispatchCardExpandedBlockTab.LIVE_MAP && (
          <Box
            m={1}
            borderRadius={1}
            overflow={'hidden'}
            border={1}
            sx={{
              background: '#fff',
              height: 400,
              borderColor: theme.brandV2.colors.treadGray7,
            }}
          >
            {showLiveMapV2 ? (
              <TreadLiveMapV2 order={order} />
            ) : (
              <TreadLiveMap order={order} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
