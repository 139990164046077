import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

const TEXT_LINE_HEIGHT_IN_PX = 19; // Measured for a font size of 12px
const TEXT_MAX_LINES = 4;
const TEXT_CONTAINER_HEIGHT_IN_PX = TEXT_LINE_HEIGHT_IN_PX * TEXT_MAX_LINES;

interface OrderNotesProps {
  order: Order;
}

const OrderNotes = observer(({ order }: OrderNotesProps) => {
  const project = order.project;
  const projectNotes = project?.notes;
  const orderNotes = order.notes ?? project?.notes;
  const jobNotes = order.jobNotes ?? project?.jobNotes;
  const internalNotes = order.internalNotes ?? project?.internalNotes;

  const hasNotes = Boolean(projectNotes || orderNotes || jobNotes || internalNotes);

  if (!hasNotes) {
    return null;
  }

  return (
    <Box
      sx={{
        borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
        px: 3,
        py: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        <Typography
          variant="subtitle2"
          sx={{ color: theme.brandV2.colors.treadGray2, fontWeight: 400 }}
        >
          {t('form_fields.notes')}
        </Typography>

        {projectNotes && (
          <NotesCard
            key={`project-${order.id}-${projectNotes.length}`}
            text={projectNotes}
            title={t('form_fields.project')}
          />
        )}

        {orderNotes && (
          <NotesCard
            key={`order-${order.id}-${orderNotes.length}`}
            title={t('form_fields.order')}
            text={orderNotes}
          />
        )}

        {jobNotes && (
          <NotesCard
            key={`job-${order.id}-${jobNotes.length}`}
            title={t('form_fields.job')}
            text={jobNotes}
          />
        )}

        {internalNotes && (
          <NotesCard
            key={`internal-${order.id}-${internalNotes.length}`}
            text={internalNotes}
            title={t('form_fields.internal')}
          />
        )}
      </Box>
    </Box>
  );
});

interface NotesCardProps {
  text: string;
  title: string;
  variant?: 'paragraph' | 'inline';
}

export function NotesCard({ text, title, variant = 'paragraph' }: NotesCardProps) {
  const textNodeRef = useRef<HTMLElement | null>(null);
  const [hasReadMore, setHasReadMore] = useState(false);

  const Wrapper = hasReadMore ? ReadMore : React.Fragment;

  const isParagraphVariant = variant === 'paragraph';
  const titleNode = (
    <Typography
      color={theme.brandV2.colors.treadBlack}
      component="span"
      variant="subtitle2"
    >
      {isParagraphVariant ? title : `${title}: `}
    </Typography>
  );
  const textNode = (
    <Typography
      ref={textNodeRef}
      component="span"
      sx={{
        color: theme.brandV2.colors.treadBlack,
        fontSize: '12px',
        lineHeight: `${TEXT_LINE_HEIGHT_IN_PX}px`,
        whiteSpace: 'pre-line',
      }}
    >
      {text}
    </Typography>
  );

  useLayoutEffect(() => {
    if (textNodeRef.current) {
      const { height } = textNodeRef.current.getBoundingClientRect();

      if (height > TEXT_CONTAINER_HEIGHT_IN_PX) {
        setHasReadMore(true);
      }
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        minWidth: '200px',
        position: 'relative',
      }}
    >
      {isParagraphVariant && titleNode}

      <Wrapper>
        {isParagraphVariant ? (
          textNode
        ) : (
          <Typography>
            {titleNode}
            {textNode}
          </Typography>
        )}
      </Wrapper>
    </Box>
  );
}

function ReadMore({ children }: React.PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCollapsedState = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen((state) => !state);
  }, []);

  return (
    <Collapse in={isOpen} collapsedSize={TEXT_CONTAINER_HEIGHT_IN_PX}>
      {children}

      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          pointerEvents: 'none',
          background: isOpen
            ? 'transparent'
            : `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)`,
        }}
      />

      <Divider
        sx={{
          bottom: 0,
          left: 0,
          mt: isOpen ? 1 : 0,
          position: isOpen ? 'relative' : 'absolute',
          right: 0,
          '&.MuiDivider-root:before, &.MuiDivider-root:after': {
            borderColor: theme.brandV2.colors.treadOrange,
          },
        }}
      >
        <Typography
          onClick={handleToggleCollapsedState}
          variant="subtitle2"
          sx={{ cursor: 'pointer', color: theme.brandV2.colors.treadOrange }}
        >
          {isOpen
            ? t('dispatch.dispatch_v2.read_less')
            : t('dispatch.dispatch_v2.read_more')}
        </Typography>
      </Divider>
    </Collapse>
  );
}

export default OrderNotes;
