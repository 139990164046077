import { SxProps } from '@mui/system';
import { t } from 'i18next';
import { useMemo } from 'react';

import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { JobAssignmentType } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

interface AssigneeTabsProps {
  driversCount: number;
  onChange: (tab: JobAssignmentType) => void;
  selectedTab: JobAssignmentType;
  sx?: SxProps;
  vendorsCount: number;
}

export default function AssigneeTabs({
  driversCount,
  onChange,
  selectedTab,
  sx,
  vendorsCount,
}: AssigneeTabsProps) {
  const switchAssigneeTabsOrderFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.switchAssigneeTabsOrder,
  });

  const assigneeTypeTabs = useMemo(() => {
    let driversLabel = t('dispatch.job.assignment_popover.driver');
    let vendorsLabel = t('dispatch.job.assignment_popover.vendor');

    if (driversCount) {
      const trucksCountFragment = t('common.trucks_count', {
        count: driversCount,
        plural: driversCount > 1 ? 's' : '',
      });
      driversLabel = `${driversLabel} (${trucksCountFragment.toLocaleLowerCase()})`;
    }

    if (vendorsCount) {
      const trucksCountFragment = t('common.trucks_count', {
        count: vendorsCount,
        plural: vendorsCount > 1 ? 's' : '',
      });
      vendorsLabel = `${vendorsLabel} (${trucksCountFragment.toLocaleLowerCase()})`;
    }

    const tabs: TabProps<JobAssignmentType>[] = [
      {
        label: driversLabel,
        value: JobAssignmentType.DRIVER,
      },
      {
        label: vendorsLabel,
        value: JobAssignmentType.VENDOR,
      },
    ];

    if (switchAssigneeTabsOrderFeatureFlagEnabled) {
      return [...tabs].reverse();
    }

    return tabs;
  }, [driversCount, vendorsCount, switchAssigneeTabsOrderFeatureFlagEnabled]);

  return (
    <Tabs onChange={onChange} selected={selectedTab} tabs={assigneeTypeTabs} sx={sx} />
  );
}
