import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { WaypointType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { OrderUnitOfMeasure, unitOfMeasureOptions } from '~constants/enums';
import { Job } from '~hooks/useJob';
import { Material, useMaterials } from '~hooks/useMaterials';
import { useSites } from '~hooks/useSites';
import { serviceIsHiredTruck } from '~pages/Sales/Orders/newOrderFormSchema';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import { useStores } from '~store';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

interface HiredTruckLoadsProps {
  jobData?: Nullable<Job>;
}

export const getDefaultHiredTruckLoad = (jobData?: Nullable<Job>) => {
  return {
    quantity: Number(jobData?.equipmentTypeGrossCapacity) || 1,
    unitOfMeasure: Number(jobData?.equipmentTypeGrossCapacity)
      ? (jobData?.unitOfMeasure as ItemNameAndId)
      : ({
          id: OrderUnitOfMeasure.LOAD,
          name: OrderUnitOfMeasure.LOAD,
        } as ItemNameAndId),
    material: jobData?.material
      ? { id: jobData.material.id, name: jobData.material.name }
      : null,
    waypoints: jobData?.waypoints,
    pickUpWaypoints: [
      {
        site:
          jobData?.waypoints?.find((waypoint) => waypoint.type === WaypointType.PICKUP)
            ?.site || null,
      },
    ],
    dropOffWaypoints: [
      {
        site:
          jobData?.waypoints?.find((waypoint) => waypoint.type === WaypointType.DROP_OFF)
            ?.site || null,
      },
    ],
  };
};

const HiredTruckLoads = ({ jobData }: HiredTruckLoadsProps) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { userStore } = useStores();
  const theme = useTheme();
  const { getMaterialsByCompanyIdTypeahead } = useMaterials();
  const { getCompanySitesTypeahead } = useSites();
  const watchedCompany = watch('company');
  const companyId = watchedCompany?.id || userStore.userCompany?.id || '';
  const isHiredTruckService = serviceIsHiredTruck(jobData?.service ?? null);

  const {
    fields,
    append: addLoad,
    remove: removeLoad,
  } = useFieldArray({
    control,
    name: 'loads',
  });

  return (
    <Box sx={{ mt: 3 }}>
      {fields.map((load, idx) => {
        return (
          <Box key={load.id}>
            <Grid
              container
              sx={{
                position: 'relative',
                p: 1.5,
                mb: 2,
                border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                borderRadius: 0.5,
              }}
            >
              <RemoveItemButton
                action={() => removeLoad(idx)}
                sx={{
                  ml: 2,
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  cursor: 'pointer',
                }}
              />

              <Grid item xs={1}>
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  {idx + 1}
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <AutocompleteAsyncFormField
                      asyncCallback={getCompanySitesTypeahead}
                      control={control}
                      errors={errors}
                      extraRequestOptions={{ companyId }}
                      name={`loads[${idx}].pickUpWaypoints[0].site`}
                      label={`${t('form_fields.pickup')}`}
                      isRequired={!isHiredTruckService}
                      getValue={(item) => item?.id}
                      getLabel={(item) => item.name || ''}
                      clearable={true}
                      disabled={!isHiredTruckService}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AutocompleteAsyncFormField
                      asyncCallback={getCompanySitesTypeahead}
                      control={control}
                      errors={errors}
                      extraRequestOptions={{ companyId }}
                      name={`loads[${idx}].dropOffWaypoints[0].site`}
                      label={`${t('form_fields.dropoff')}`}
                      isRequired={!isHiredTruckService}
                      getValue={(item) => item?.id}
                      getLabel={(material: Material) => {
                        return `${material?.externalId ? `${material.externalId} - ` : ''}${material?.name ?? ''}`;
                      }}
                      clearable={true}
                      disabled={!isHiredTruckService}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AutocompleteAsyncFormField
                      asyncCallback={getMaterialsByCompanyIdTypeahead}
                      control={control}
                      errors={errors}
                      extraRequestOptions={{ companyId }}
                      getLabel={(material: Material) => material?.name ?? ''}
                      getValue={(material: Material) => material?.id ?? ''}
                      label={`${t('form_fields.material')}`}
                      name={`loads[${idx}].material`}
                      clearable={isHiredTruckService}
                      isRequired={!isHiredTruckService}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextFormField
                      control={control}
                      errors={errors}
                      name={`loads[${idx}].quantity`}
                      label={`${t('form_fields.quantity')}`}
                      isRequired={!isHiredTruckService}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AutocompleteFormField
                      control={control}
                      errors={errors}
                      name={`loads[${idx}].unitOfMeasure`}
                      label={`${t('form_fields.unit')}`}
                      list={unitOfMeasureOptions}
                      isRequired={!isHiredTruckService}
                      getValue={(item) => item?.id}
                      getLabel={(item) => item.name || ''}
                      clearable={isHiredTruckService}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      })}
      <PseudoLink
        title={`+ ${t('loads.add')}`}
        sx={{ mt: 1, display: 'inline-flex' }}
        action={() => {
          addLoad(getDefaultHiredTruckLoad(jobData));
        }}
      />
    </Box>
  );
};

export default HiredTruckLoads;
