import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

interface BackToLiveBarProps {
  handleBackOnClick: () => void;
  selectedJob?: Job;
  selectedOrder?: Order;
}

const renderTooltipTitle = (key: string, values: Record<string, any>) => (
  <Trans
    i18nKey={key}
    values={values}
    components={{
      fontColor: <span style={{ color: 'white' }} />,
    }}
  />
);

const BackToLiveBarContent = ({
  tooltipKey,
  values,
  handleBackOnClick,
}: {
  tooltipKey: string;
  values: Record<string, any>;
  handleBackOnClick: () => void;
}) => (
  <Box display={'flex'} borderRadius={2} padding={1} sx={{ backgroundColor: 'black' }}>
    <Tooltip title={renderTooltipTitle(tooltipKey, values)}>
      <Typography noWrap sx={{ mr: 1, color: theme.palette.grey[500] }}>
        {renderTooltipTitle(tooltipKey, values)}
      </Typography>
    </Tooltip>
    <Button
      sx={{
        backgroundColor: theme.palette.grey[800],
        color: 'white',
        height: '25px',
        minWidth: '160px',
      }}
      onClick={handleBackOnClick}
    >
      <ArrowBack sx={{ height: '20px' }} />
      {t('live_map.actions.back_to_live_map')}
    </Button>
  </Box>
);

interface BackToLiveBarProps {
  handleBackOnClick: () => void;
  selectedJob?: Job;
  selectedOrder?: Order;
  bottom?: boolean;
}

export const BackToLiveBar = ({
  handleBackOnClick,
  selectedJob,
  selectedOrder,
  bottom,
}: BackToLiveBarProps) => {
  return (
    <Box
      position={'absolute'}
      zIndex={10}
      left={'50%'}
      top={bottom ? 'calc(100% - 80px)' : 0}
      sx={{ transform: 'translate(-50%, 10px)' }}
      maxWidth={'100%'}
      pr={'55px'}
      pl={2}
    >
      {selectedJob ? (
        <BackToLiveBarContent
          tooltipKey="live_map.view_selected_job"
          values={{
            jobId: selectedJob?.jobId,
            equipmentName: selectedJob?.equipment?.name,
          }}
          handleBackOnClick={handleBackOnClick}
        />
      ) : (
        <BackToLiveBarContent
          tooltipKey="live_map.view_selected_order"
          values={{
            orderId: selectedOrder?.orderId,
            orderName: selectedOrder?.company?.legalName,
          }}
          handleBackOnClick={handleBackOnClick}
        />
      )}
    </Box>
  );
};
