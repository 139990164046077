import _ from 'lodash';
import { ComponentProps, ElementType, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { PRIMARY_TOKEN } from '~constants/consts';
import { SystemRoles } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { ROUTES } from '~router/routePaths';
import { useStores } from '~store';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

interface ProtectedRouteProps extends ComponentProps<ElementType> {
  canAccess?: boolean;
  element: ElementType;
  featureFlag?: FeatureFlags;
  requiredRoles?: SystemRoles[];
}

export const ProtectedRoute = ({
  canAccess,
  element: Component,
  featureFlag,
  requiredRoles,
  ...rest
}: ProtectedRouteProps) => {
  const { userStore } = useStores();
  const isAuthenticated = !!localStorage.getItem(PRIMARY_TOKEN);

  const isFeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: featureFlag ?? ('___DUMMY_FEATURE_FLAG___' as FeatureFlags),
  });

  let isAllowed = true;

  if (requiredRoles?.length) {
    isAllowed = !!userStore.user.userRoles?.some(
      (role) => role.name === SystemRoles.PLATFORM_ADMIN,
    );
  }

  if (!_.isUndefined(canAccess)) {
    isAllowed = canAccess;
  }

  if (featureFlag && !isFeatureFlagEnabled) {
    isAllowed = false;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isAuthenticated ? (
        isAllowed ? (
          <Component {...rest} />
        ) : (
          <Navigate to="/" />
        )
      ) : (
        <Navigate to={`/${ROUTES.signIn}`} />
      )}
    </Suspense>
  );
};
