import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { EquipmentTypeahead } from '~hooks/useEquipment';
import { TelematicsVehicle, useTelematicsVehicles } from '~hooks/useTelematicsVehicles';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';

import { EquipmentSelector } from '../UserManagement/EquipmentSelector';

interface TelematicsVehicleEquipmentSelectorProps {
  remoteVehicle: TelematicsVehicle;
}

export const TelematicsVehicleEquipmentSelector = observer(
  ({ remoteVehicle }: TelematicsVehicleEquipmentSelectorProps) => {
    const { userStore, toasterStore } = useStores();
    const { updateTelematicsVehicleById } = useTelematicsVehicles();

    // Get user permissions to determine if user can edit telematics remote vehicles
    const userPermissions = useMemo(() => {
      return userStore.getPermissions();
    }, [userStore.getPermissions()]);

    const saveRemoteVehicleEquipment = (data: Nullable<EquipmentTypeahead>) => {
      updateTelematicsVehicleById(remoteVehicle.id, {
        equipment_id: data?.id ?? null,
      }).then(() => {
        toasterStore.push(
          alert(
            t('administration.integration.vehicle_updated', {
              name: remoteVehicle.remoteVehicleExternalId,
            }),
            AlertTypes.success,
          ),
        );
      });
    };

    return (
      <EquipmentSelector
        defaultEquipment={remoteVehicle.equipment}
        onSelect={saveRemoteVehicleEquipment}
        disabled={!userPermissions?.canEditTelematicsRemoteVehicle}
      />
    );
  },
);
