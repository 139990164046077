import { t } from 'i18next';
import { useCallback, useRef, useState } from 'react';

import { JobAssignmentType } from '~constants/enums';
import { Job, useJob } from '~hooks/useJob';
import { User } from '~hooks/useUsers';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

interface SelectedAssignment {
  type: JobAssignmentType;
  id: string;
  name: string;
}

export const useJobAssignment = (
  jobId: string,
  driver: User | undefined,
  onSuccess?: (job: Job) => void,
) => {
  const { toasterStore } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { doEvent, assignDriver, assignVendor, unassignDriver } = useJob();

  const assignActionMap = useRef({
    [JobAssignmentType.DRIVER]: assignDriver,
    [JobAssignmentType.VENDOR]: assignVendor,
  }).current;

  const handleAssign = useCallback(
    async (selectedAssignment: SelectedAssignment) => {
      setIsLoading(true);
      const assignActionResp: Job = await assignActionMap[selectedAssignment.type](
        jobId,
        selectedAssignment.id,
      );
      if (assignActionResp.id) {
        onSuccess?.(assignActionResp);
        toasterStore.push(
          alert(t('dispatch.job.job_assigned_successfully'), AlertTypes.success),
        );
      }
      setIsLoading(false);
    },
    [assignActionMap, jobId, onSuccess, setIsLoading],
  );

  const handleUnassign = useCallback(async () => {
    setIsLoading(true);
    const unassignActionResp: Job = driver
      ? await unassignDriver(jobId)
      : await doEvent(jobId, 'unassign');
    if (unassignActionResp.id) {
      onSuccess?.(unassignActionResp);
      toasterStore.push(
        alert(t('dispatch.job.job_unassigned_successfully'), AlertTypes.success),
      );
    }
    setIsLoading(false);
  }, [unassignDriver, doEvent, jobId, driver, onSuccess, setIsLoading]);

  return { handleAssign, handleUnassign, isLoading };
};
