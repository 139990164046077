import Close from '@mui/icons-material/Close';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip, { ChipPropsColorOverrides } from '@mui/material/Chip';
import { InputBaseProps } from '@mui/material/InputBase';
import { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';
import { t } from 'i18next';
import { debounce, get } from 'lodash';
import React, {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { FormFieldLabel } from '~components/FormFields/FormFieldLabel';
import { GeofenceStartAdornment } from '~pages/Settings/Administration/Sites/components/GeofenceStartAdornment';

import { SelectOption } from './Parts/SelectOption';

interface FormFieldProps {
  control: Control<any>; // Form control
  errors: Record<string, any>; // Object of errors
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  sx?: SxProps<Theme>; // Style
  clearable?: boolean; // IsClearable
  disabled?: boolean;
  multiple?: boolean;
  chipsColor?: OverridableStringUnion<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  >;
  defaultValue?: any;
  noOptionsText?: string | ReactNode;
  inputProps?: InputBaseProps['inputProps'];
  getValue: (value: any) => string; // Is used for the equal comparison of the values
  getLabel: (value: any) => string; // Is used for the label of the option
  groupBy?: (value: any) => string; // Is used for the groupBy of the option
  onInput?: (event: React.SyntheticEvent, value: string) => void;
  asyncCallback: (value: any) => any;
  debounceTime?: number;
  extraRequestOptions?: Record<string, any>;
  filterSelectedOptions?: boolean;
  limitTags?: number;
  onClear?: () => void;
  onSelect?: (value: any) => any;
  defaultSuggestions?: Record<string, any>[];
  hideLabel?: boolean;
  fetchOnInputChange?: boolean; // Set to false when this component is used with a non-typeahead api
  onMultipleOptionsChange?: <T>(values: T[]) => void;
  hideChipOverflow?: boolean;
  disablePortal?: boolean;
}

export interface AutocompleteAsyncFormFieldRef {
  reset: () => void;
  resetCursor: () => void;
  getSuggestions: () => Record<string, any>[];
  updateSuggestions: (newSuggestions: Record<string, any>[]) => void;
}

const AutocompleteAsyncFormField = forwardRef(function AutocompleteAsyncFormField(
  {
    control,
    errors,
    name,
    label,
    clearable,
    getLabel,
    getValue,
    groupBy,
    chipsColor,
    isRequired,
    disabled = false,
    multiple = false,
    defaultValue,
    inputProps,
    sx,
    noOptionsText = t('form_fields.no_options') || '',
    asyncCallback,
    debounceTime = 400,
    extraRequestOptions = {},
    filterSelectedOptions,
    limitTags,
    onClear,
    onSelect,
    defaultSuggestions,
    onInput,
    hideLabel = false,
    fetchOnInputChange = true,
    onMultipleOptionsChange,
    hideChipOverflow = false,
    disablePortal = true,
    placeholder,
  }: FormFieldProps,
  ref: Ref<AutocompleteAsyncFormFieldRef>,
) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<Record<string, any>[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prevCursor, setPrevCursor] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const resetSuggestions = useCallback(() => setSuggestions([]), [setSuggestions]);
  const SCROLL_OFFSET = 300; // How far above the bottom of the scroll container to trigger a fetch
  const fetchSuggestions = async (
    query: string,
    cursor: string,
    extraRequestOptions: any,
  ) => {
    setIsLoading(true);
    const entities = await asyncCallback({
      ...extraRequestOptions, // I.e. items count, page number, etc.
      query,
      cursor,
    });

    if (entities?.data?.length) {
      setPrevCursor(cursor);
      setSuggestions((prevState) => {
        if (!prevCursor && !cursor) {
          return entities.data;
        } else {
          return [...prevState, ...entities.data];
        }
      });
      setCursor(entities.pagination.after);
    } else if (entities?.length) {
      setSuggestions((prevState) => {
        if (inputValue) {
          return entities;
        } else {
          return [...prevState, ...entities];
        }
      });
    } else {
      resetSuggestions();
    }
    setIsLoading(false);
  };
  const searchDelayed = useMemo(
    () => debounce(fetchSuggestions, debounceTime),
    [asyncCallback],
  );
  const handleInputChange = (
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setInputValue(value);
    if (onInput) {
      onInput(event, value);
    }

    if (reason === 'input' && fetchOnInputChange) {
      if (value === '' && defaultSuggestions) {
        searchDelayed('', '', extraRequestOptions);
      } else {
        searchDelayed(value, '', extraRequestOptions);
      }
    }
    if (reason === 'clear' || reason === 'reset') {
      if (defaultSuggestions) {
        setSuggestions(defaultSuggestions);
      } else if (isOpen) {
        searchDelayed('', '', extraRequestOptions);
      }
    }
  };
  const handleClose = () => {
    setIsOpen(false);
    setPrevCursor('');
    setCursor('');
    setSuggestions([]);
  };

  const updateSuggestions = useCallback(
    (newSuggestions: Record<string, any>[]) => {
      setSuggestions(newSuggestions);
    },
    [setSuggestions],
  );
  useImperativeHandle(
    ref,
    () => ({
      reset() {
        resetSuggestions();
      },
      resetCursor() {
        setPrevCursor('');
        setCursor('');
      },
      getSuggestions() {
        return suggestions;
      },
      updateSuggestions(newSuggestions: Record<string, any>[]) {
        updateSuggestions(newSuggestions);
      },
    }),
    [resetSuggestions],
  );

  //Initial fetch of data
  useEffect(() => {
    if (isOpen && !isLoading && !suggestions.length) {
      // Fetch wth default value ? or empty string ? product
      fetchSuggestions('', cursor, extraRequestOptions);
    }
  }, [isOpen]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            ref={ref}
            open={isOpen}
            disablePortal={disablePortal}
            ListboxProps={{
              onScroll: (event: React.UIEvent<HTMLUListElement>) => {
                const target = event.currentTarget as HTMLElement;
                if (
                  !!cursor &&
                  !isLoading &&
                  target.scrollTop + target.clientHeight >=
                    target.scrollHeight - SCROLL_OFFSET
                ) {
                  // Exclude input value on subsequent fetches to avoid returning an empty array
                  fetchSuggestions('', cursor, extraRequestOptions);
                }
              },
            }}
            onOpen={() => {
              setIsOpen(true);
              if (defaultSuggestions) {
                setSuggestions(defaultSuggestions);
              }
            }}
            onClose={handleClose}
            noOptionsText={
              <Box
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {noOptionsText}
              </Box>
            }
            sx={{ ...sx }}
            slotProps={{
              paper: {
                elevation: 4,
              },
            }}
            multiple={multiple}
            options={suggestions}
            filterSelectedOptions={filterSelectedOptions ?? !!multiple}
            disableCloseOnSelect={multiple}
            limitTags={limitTags}
            includeInputInList
            fullWidth
            disabled={disabled}
            defaultValue={defaultValue}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            filterOptions={(options) => {
              if (!fetchOnInputChange) {
                return options.filter((item) =>
                  getLabel(item).toLowerCase().includes(inputValue.toLowerCase()),
                );
              } else {
                return options;
              }
            }}
            onChange={async (event, values, reason) => {
              if (reason === 'clear') {
                if (onClear) {
                  onClear();
                } else {
                  onChange(null);
                }
              } else {
                let formatedValues = values;
                if (onSelect) {
                  formatedValues = await onSelect(values);
                }
                if (multiple && onMultipleOptionsChange) {
                  onMultipleOptionsChange(formatedValues);
                }
                onChange(formatedValues);
              }
            }}
            renderGroup={(params) => {
              return (
                <li key={params.key}>
                  <div className="bg-gray-100">
                    <span className="pl-2 text-sm italic font-medium">
                      {params.group}
                    </span>
                  </div>
                  <div>{params.children}</div>
                </li>
              );
            }}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : getLabel(option)
            }
            isOptionEqualToValue={(option, value) => getValue(option) === getValue(value)}
            groupBy={groupBy}
            loading={isLoading}
            renderTags={(value: readonly string[], getTagProps) => (
              <>
                {value.map((option: string, index: number) => (
                  <Chip
                    size={'small'}
                    color={chipsColor || 'primary'}
                    label={getLabel(option)}
                    deleteIcon={<Close />}
                    style={{
                      ...(hideChipOverflow &&
                        limitTags &&
                        value.length > limitTags && { maxWidth: '70%' }),
                      ...(limitTags && index >= limitTags && { display: 'none' }),
                    }}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))}
                {limitTags && value.length > limitTags && (
                  <Typography>+{value.length - limitTags}</Typography>
                )}
              </>
            )}
            renderInput={(params) => (
              <>
                {!hideLabel && (
                  <FormFieldLabel
                    label={`${label}${!!label && isRequired ? ' *' : ''}`}
                  />
                )}
                <TextField
                  {...params}
                  label=""
                  size={'small'}
                  sx={{ display: 'flex' }}
                  margin={'dense'}
                  variant="outlined"
                  error={get(errors, name)}
                  helperText={get(errors, name)?.message}
                  className={isRequired && !disabled ? 'required' : ''}
                  inputProps={{
                    ...params.inputProps,
                    ...inputProps,
                    autoComplete: 'off',
                    'data-1p-ignore': 'true', // Special attribute to prevent 1Password autocomplete
                    'data-test-id': get(inputProps, 'data-test-id', 'async-autocomplete'),
                    'aria-label': hideLabel ? label : undefined,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    ...((name === 'pickUpAddress' || name === 'dropOffAddress') && {
                      startAdornment: (
                        <GeofenceStartAdornment
                          geofenceType={value?.geofence?.geofenceType}
                        />
                      ),
                    }),
                    placeholder: placeholder,
                  }}
                />
              </>
            )}
            value={value}
            disableClearable={!clearable}
            renderOption={(props, option, { inputValue, selected }) => (
              <SelectOption
                isCheckEnabled={multiple}
                selected={selected}
                option={option}
                getLabel={getLabel}
                inputValue={inputValue}
                props={props}
              />
            )}
          />
        )}
      />
    </>
  );
});

export { AutocompleteAsyncFormField };
