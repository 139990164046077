export const ROUTES = {
  base: '/',
  signIn: 'sign-in',
  signUp: 'sign-up',
  reset: 'reset',
  forgot: 'forgot',
  authConfirmation: 'confirmation',
  admin: {
    base: 'admin',
    users: 'users',
    companies: 'companies',
    connections: 'connections',
    accounts: 'accounts',
  },
  approvals: {
    base: 'approvals',
    driverDay: 'driver-day',
    driverPay: 'driver-pay',
    ticketsReview: 'tickets-review',
  },
  settings: {
    base: 'settings',
    userProfile: 'user-profile',
    generalSettings: 'general-settings',
    accounts: 'accounts',
    customers: 'customers',
    vendors: 'vendors',
    userManagement: 'user-management',
    drivers: 'drivers',
    companies: 'companies',
    equipmentTypeManagement: 'equipment-type',
    equipmentManagement: 'equipment',
    materialsManagement: 'materials',
    ratesManagement: 'rates',
    addOnCharges: 'rates/add-on-charges',
    sitesManagement: 'sites',
    departmentsManagement: 'departments',
    serviceClassesManagement: 'service-classes',
    configuration: 'configuration',
    integrations: {
      base: 'integrations',
      agave: {
        base: 'agave',
        quickBooksDesktop: 'quick-books-desktop',
        quickBooksOnline: 'quick-books-online',
      },
      axle: 'axle',
      paverTracker: 'paver-tracker',
    },
  },
  dispatch: {
    base: 'dispatch',
    orders: 'orders',
    jobs: 'jobs',
    v3: 'dispatch_v3',
  },
  dashboard: 'dashboard',
  reconciliation: 'reconciliation',
  live: 'live',
  messages: 'messages',
  sales: {
    base: 'sales',
    projects: 'projects',
    orders: 'orders',
  },
  payments: 'payments',
  reports: 'reports',
};
