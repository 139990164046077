import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { BasicEquipment, EquipmentTypeahead, useEquipment } from '~hooks/useEquipment';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

const equipmentSelectorSchema = yup.object().shape({
  equipment: yup
    .object()
    .shape({
      name: yup.string().notRequired(),
      id: yup.string().notRequired(),
      external_id: yup.string().notRequired(),
    })
    .nullable(),
});

type EquipmentSelectorDTO = yup.InferType<typeof equipmentSelectorSchema>;

interface EquipmentSelectorProps {
  defaultEquipment: Nullable<BasicEquipment>;
  disabled: boolean;
  onSelect: (data: Nullable<EquipmentTypeahead>) => void;
  selectedCompanyId?: string;
}

export const EquipmentSelector = observer(
  ({
    defaultEquipment,
    disabled,
    onSelect,
    selectedCompanyId,
  }: EquipmentSelectorProps) => {
    const { getEquipmentByCompanyIdTypeahead } = useEquipment();
    const { userStore } = useStores();
    const companyId = selectedCompanyId || userStore.userCompany?.id;

    const {
      control,
      setValue,
      formState: { errors },
    } = useForm<EquipmentSelectorDTO>({
      resolver: yupResolver(equipmentSelectorSchema),
      reValidateMode: 'onChange',
      defaultValues: {
        equipment: defaultEquipment,
      },
    });

    const getEquipmentLabel = (equipment?: EquipmentTypeahead) => {
      if (!equipment) return '';
      return (equipment.externalId ? `${equipment.externalId} - ` : '') + equipment.name;
    };

    useEffect(() => {
      const { id, name, externalId } = defaultEquipment || {};
      setValue(
        'equipment',
        defaultEquipment ? { id, name, external_id: externalId } : null,
      );
    }, [defaultEquipment]);

    return (
      <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <AutocompleteAsyncFormField
          control={control}
          errors={errors}
          name={'equipment'}
          getValue={(item) => item?.id || ''}
          getLabel={(item) =>
            item ? getEquipmentLabel(EquipmentTypeahead.parse(item)) : ''
          }
          asyncCallback={getEquipmentByCompanyIdTypeahead}
          extraRequestOptions={{
            companyId: companyId,
          }}
          filterSelectedOptions={false}
          clearable
          hideLabel
          disablePortal={false}
          sx={{ mt: 0.5 }}
          inputProps={{
            onKeyDown: (event) => {
              if (event.key === ' ') {
                // override MUI's default behavior of consuming space key to prevent scrolling.
                // https://github.com/mui/material-ui/issues/34566
                event.stopPropagation();
              }
            },
            sx: {
              fontSize: '12px',
            },
          }}
          onSelect={onSelect}
          onClear={() => onSelect(null)}
          disabled={disabled}
          placeholder={`${t('form_fields.select_truck')}`}
        />
      </Box>
    );
  },
);
