import Close from '@mui/icons-material/Close';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandCircleDownOutlined';
import Box from '@mui/material/Box';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { WaypointType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  LoadingSpinner,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { FormStateChangeProps } from '~formsShared';
import { useEquipment } from '~hooks/useEquipment';
import { useMaterials } from '~hooks/useMaterials';
import { Order, useOrdersDispatch } from '~hooks/useOrders';
import { useServices } from '~hooks/useServices';
import { Site, useSites } from '~hooks/useSites';
import { createSitesInline } from '~pages/Sales/Orders/helpers';
import { NewOrderForm, NewOrderFormHandler } from '~pages/Sales/Orders/NewOrderForm';
import { NewOrderFormSchemaInterface } from '~pages/Sales/Orders/newOrderFormSchema';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';

type AddDefaultSiteParams = {
  siteId: string;
  waypointType: WaypointType;
};

type NewDefaultSitesState = {
  pickUp: AddDefaultSiteParams[];
  dropOff: AddDefaultSiteParams[];
};

type NewOrderFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteParams>;

interface OrderFormProps {
  defaultDate?: string;
  isMinimized?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onMinimize?: () => void;
  order?: Order;
}

const OrderForm = observer(
  ({ defaultDate, isMinimized, isOpen, onClose, onMinimize, order }: OrderFormProps) => {
    const isEditing = Boolean(order?.id);

    const formRef = useRef<NewOrderFormHandler>(null);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [newDefaultSites, setNewDefaultSites] = useState<NewDefaultSitesState>({
      pickUp: [],
      dropOff: [],
    });
    const { addSiteToOrderDefaultSites, createNewSite } = useSites();
    const { userStore, ordersDispatchStore, companyAssetsStore, toasterStore } =
      useStores();
    const { createOrder, updateOrder } = useOrdersDispatch();

    const companyId = userStore.userCompany?.id;

    const equipment = companyAssetsStore.equipment;
    const { getEquipmentByCompanyId, isLoading: isLoadingEquipment } = useEquipment();

    const materials = companyAssetsStore.allMaterials;
    const { getAllMaterials, isLoading: isLoadingMaterials } = useMaterials();

    const services = companyAssetsStore.services;
    const { getAllServices, isLoading: isLoadingServices } = useServices();

    const isLoading = isLoadingEquipment || isLoadingMaterials || isLoadingServices;

    const onOrderCreated = useCallback(
      async (orderId: string) => {
        const sites = [...newDefaultSites.pickUp, ...newDefaultSites.dropOff];

        if (sites.length > 0) {
          await sites.reduce(
            async (acc, site) => {
              await acc;
              return await addSiteToOrderDefaultSites({
                orderId,
                siteId: site.siteId,
                waypointType: site.waypointType,
              });
            },
            Promise.resolve({} as Site),
          );
        }

        formRef.current?.fileAttachmentsOnSubmit(orderId);
      },
      [newDefaultSites.pickUp, newDefaultSites.dropOff],
    );

    const handleClose = useCallback(() => {
      setNewDefaultSites({ pickUp: [], dropOff: [] });
      onClose();
    }, [onClose]);

    const handleFormStateChange = useCallback(
      ({ isDirty, siteId, waypointType }: NewOrderFormStateChange) => {
        setFormIsDirty(isDirty);

        if (siteId) {
          if (waypointType === WaypointType.PICKUP) {
            setNewDefaultSites((sites) => ({
              ...sites,
              pickUp: _.uniqBy([...sites.pickUp, { siteId, waypointType }], 'siteId'),
            }));
          }

          if (waypointType === WaypointType.DROP_OFF) {
            setNewDefaultSites((sites) => ({
              ...sites,
              dropOff: _.uniqBy([...sites.dropOff, { siteId, waypointType }], 'siteId'),
            }));
          }
        }
      },
      [],
    );

    const saveOrder = useCallback(
      async (formData: NewOrderFormSchemaInterface) => {
        let newOrderId = '';
        const newFormData = (await createSitesInline(
          formData,
          createNewSite,
          userStore.userCompany?.siteRadius,
        )) as
          | NewOrderFormSchemaInterface
          | { error: boolean; field: string; message?: string };

        if ('error' in newFormData) {
          if (newFormData.message) {
            formRef.current?.setFieldError(newFormData.field, newFormData.message);
          } else {
            const isPickupSiteError = newFormData.field === 'pickUpWayPoint';
            const message = isPickupSiteError
              ? t('error_messages.orders.failed_to_create_pickup_site')
              : t('error_messages.orders.failed_to_create_dropoff_site');

            toasterStore.push(alert(message, AlertTypes.error));
          }

          return;
        }

        if (isEditing) {
          const updatedOrder = await updateOrder(newFormData);
          newOrderId = updatedOrder.orderId;

          formRef.current?.updateProjectDefaultSites();
          formRef.current?.fileAttachmentsOnSubmit(newOrderId);
        } else {
          const createdOrder = await createOrder(newFormData);

          if (createdOrder?.id) {
            newOrderId = createdOrder.orderId;
            await onOrderCreated(createdOrder.id);
          }
        }

        toasterStore.push(
          alert(
            isEditing
              ? t('order.order_updated', { name: newOrderId })
              : t('order.order_created', { name: newOrderId }),
            AlertTypes.success,
          ),
        );
        handleClose();
      },
      [isEditing, onOrderCreated, handleClose],
    );

    const handleOrderFormSubmit = useCallback(() => {
      // @ts-ignore Submit handler is not properly typed in the forms
      formRef.current?.submit((data: NewOrderFormSchemaInterface) => {
        saveOrder(data);
      });
    }, [saveOrder]);

    useEffect(() => {
      if (!companyId) {
        return;
      }

      if (!equipment?.length) {
        getEquipmentByCompanyId({ companyId });
      }
    }, [companyId, equipment?.length]);

    useEffect(() => {
      if (!companyId) {
        return;
      }

      if (!materials?.length) {
        getAllMaterials();
      }
    }, [companyId, materials?.length]);

    useEffect(() => {
      if (!companyId) {
        return;
      }

      if (!services?.length) {
        getAllServices();
      }
    }, [companyId, services?.length]);

    return (
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={isOpen && !isMinimized}
        keepMounted={isMinimized}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            height: '100dvh',
            maxWidth: '1200px',
            overflow: 'hidden',
            width: '80%',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              borderBottom: `solid 1px ${theme.palette.divider}`,
              display: 'flex',
              flex: 0,
              justifyContent: 'space-between',
              px: 2,
              py: 1,
            }}
          >
            <Typography variant="h5">
              {order ? t('order.update_order') : t('order.create_order')}
              {order && (
                <Typography
                  component="span"
                  sx={{
                    fontSize: '16px',
                    color: theme.brandV2.colors.treadGray3,
                    ml: 2,
                  }}
                >
                  {order.orderId}
                </Typography>
              )}
            </Typography>

            <IconButton color="inherit" size="small" onClick={handleClose}>
              <Close sx={{ width: 16, height: 16 }} />
            </IconButton>
          </Box>

          <Box sx={{ bgcolor: '#F3F3F3', flex: 1, px: 2, pb: 1, overflow: 'auto' }}>
            {isLoading ? (
              <LoadingSpinner isVisible={true} sx={{ height: '100%' }} />
            ) : (
              <NewOrderForm
                ref={formRef}
                defaultDate={defaultDate}
                defaultOrder={order ?? null}
                onFormStateChange={handleFormStateChange}
                sx={{ mt: -1 }}
              />
            )}
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              borderTop: `solid 1px ${theme.palette.divider}`,
              display: 'flex',
              flex: 0,
              gap: 1,
              justifyContent: 'flex-end',
              p: 1,
            }}
          >
            <SmallButton
              color="brandV2OrangeDark"
              onClick={onMinimize}
              endIcon={<ExpandCircleDownOutlined />}
              variant="text"
              sx={{
                '&.MuiButtonBase-root': {
                  height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                  mr: -0.5,
                  pr: 0.5,
                  pl: 1,
                  py: 0,
                  '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                  '.MuiButton-endIcon': { ml: 0.5, mr: 0 },
                  '.MuiSvgIcon-root': {
                    fontSize: '24px',
                    transform: 'rotate(-90deg)',
                  },
                },
              }}
            >
              {t('dispatch.dispatch_v2.minimize_order_form')}
            </SmallButton>

            <SmallButton
              color="brandV2Yellow"
              disabled={ordersDispatchStore.isSavingOrder || !formIsDirty}
              onClick={handleOrderFormSubmit}
              type="button"
              sx={{ position: 'relative' }}
            >
              <LoadingSpinner
                isVisible={ordersDispatchStore.isSavingOrder}
                loadingIndicatorColor="inherit"
                loadingIndicatorSize={13}
                sx={{ position: 'absolute', inset: 0 }}
              />

              <Typography
                component="span"
                sx={{
                  color: 'inherit',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  visibility: ordersDispatchStore.isSavingOrder ? 'hidden' : 'visible',
                }}
              >
                {t(`actions.${order ? 'submit' : 'create'}`)}
              </Typography>
            </SmallButton>
          </Box>
        </Box>
      </Drawer>
    );
  },
);

export default OrderForm;
