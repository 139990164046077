import Box from '@mui/material/Box';
import { t } from 'i18next';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_TAB, StatusTabValue } from '~components/Filters/OrdersStatusFilter';
import { CommonLoader } from '~components/Loaders/CommonLoader';
import Tabs, { TabProps } from '~components/Tabs/Tabs';
import { DispatchURLParams, OrdersDispatchStorageKeys } from '~constants/dispatch';
import DriversView from '~pages/Dispatch/components/drivers/DriversView';
import theme from '~theme/AppTheme';

import CalendarView from './components/calendar/CalendarView';
import OrdersView from './components/OrdersView';

export enum NewDispatchView {
  ORDERS = 'orders',
  DRIVERS = 'drivers',
  CALENDAR = 'calendar',
}

const AVAILABLE_VIEWS: TabProps<NewDispatchView>[] = [
  { label: t('dispatch.dispatch_v2.orders'), value: NewDispatchView.ORDERS },
  { label: t('dispatch.dispatch_v2.calendar_tab'), value: NewDispatchView.CALENDAR },
  { label: t('dispatch.dispatch_v2.drivers'), value: NewDispatchView.DRIVERS },
];
const DEFAULT_VIEW = NewDispatchView.ORDERS;

const getInitialView = (searchParams: URLSearchParams) => {
  const viewParam = searchParams.get(DispatchURLParams.VIEW) as NewDispatchView;
  const storedView = localStorage.getItem(
    OrdersDispatchStorageKeys.VIEW_PARAM,
  ) as NewDispatchView;
  const view = viewParam || storedView;

  return Object.values(NewDispatchView).includes(view) ? view : DEFAULT_VIEW;
};

const getInitialStatusTab = (searchParams: URLSearchParams) => {
  const tabParam = searchParams.get(DispatchURLParams.TAB) as StatusTabValue;
  const storedTab = localStorage.getItem(
    OrdersDispatchStorageKeys.TAB_PARAM,
  ) as StatusTabValue;
  const tab = tabParam || storedTab;

  return Object.values(StatusTabValue).includes(tab) ? tab : DEFAULT_TAB;
};

function NewDispatchProvider() {
  const [searchParams, setSearchParams] = useSearchParams();

  const view = searchParams.get(DispatchURLParams.VIEW) as NewDispatchView;
  const isValidView = Object.values(NewDispatchView).includes(view);

  const isLoading = !isValidView;

  const handleSelectedViewChange = useCallback(
    (view: NewDispatchView) => {
      setSearchParams((params) => {
        params.set(DispatchURLParams.VIEW, view);

        return params;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (isValidView) {
      localStorage.setItem(OrdersDispatchStorageKeys.VIEW_PARAM, view);
    }
  }, [isValidView, view]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set(DispatchURLParams.VIEW, getInitialView(params));
      params.set(DispatchURLParams.TAB, getInitialStatusTab(params));

      return params;
    });
  }, [setSearchParams]);

  if (isLoading) {
    return <CommonLoader />;
  }

  return <NewDispatch onSelectedViewChange={handleSelectedViewChange} view={view} />;
}

export interface INewDispatchViewComponent {
  children: (topbarControls: JSX.Element | null, content: JSX.Element) => JSX.Element;
}

interface NewDispatchProps {
  onSelectedViewChange: (view: NewDispatchView) => void;
  view: NewDispatchView;
}

const viewComponentByNewDispatchView: Record<
  NewDispatchView,
  ({ children }: INewDispatchViewComponent) => JSX.Element
> = {
  [NewDispatchView.ORDERS]: OrdersView,
  [NewDispatchView.DRIVERS]: DriversView,
  [NewDispatchView.CALENDAR]: CalendarView,
};

function NewDispatch({ onSelectedViewChange, view }: NewDispatchProps) {
  const View = viewComponentByNewDispatchView[view];

  return (
    <Box height="100%" mx={-5} width={`calc(100% + ${theme.spacing(5 * 2)})`}>
      <View>
        {(topbarControls, content) => (
          <Box
            bgcolor={theme.brandV2.colors.treadGray8}
            display="flex"
            flexDirection="column"
            height="100%"
            width="100%"
          >
            <Box
              alignItems="center"
              bgcolor="white"
              borderBottom={`solid 1px ${theme.brandV2.colors.treadGray7}`}
              display="flex"
              gap={1}
              py={1}
              sx={{ px: 2 }}
            >
              <Tabs
                onChange={onSelectedViewChange}
                selected={view}
                tabs={AVAILABLE_VIEWS}
              />

              <Box flex={1}>{topbarControls}</Box>
            </Box>

            {content}
          </Box>
        )}
      </View>
    </Box>
  );
}

export default NewDispatchProvider;
