import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import TreadLiveMapV2 from '~components/Maps/v2/TreadLiveMapV2';
import { FeatureFlags } from '~constants/featureFlags';
import { Job } from '~hooks/useJob';
import TreadLiveMap from '~pages/LiveMap/TreadLiveMap';
import { useFeatureFlag } from '~utils/hooks/useFeatureFlag';

export const ROUTE_MAP_HEIGHT_IN_PX = 400;

interface Props {
  job: Job;
  sx?: SxProps<Theme>;
}

const RouteMap = ({ job, sx = {} }: Props) => {
  const showLiveMapV2 = useFeatureFlag({
    featureFlagKey: FeatureFlags.googleMapsLiveMapV2,
  });
  return (
    <Box sx={{ width: '100%', height: ROUTE_MAP_HEIGHT_IN_PX, ...sx }}>
      {job ? (
        showLiveMapV2 ? (
          <TreadLiveMapV2 lazyLoadJobPings job={job} />
        ) : (
          <TreadLiveMap job={job} defaultFetchRoutePings={false} />
        )
      ) : (
        <>{t('actions.loading')}</>
      )}
    </Box>
  );
};

export { RouteMap };
