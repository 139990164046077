import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DispatchURLParams, OrdersDispatchStorageKeys } from '~constants/dispatch';
import { useStores } from '~store';

import StatusTabs, { StatusTab } from '../Tabs/StatusTabs';

const [pendingOnlyOrderState, remainingOrderStates] = _.partition(
  Object.values(OrderState),
  (value) => value === OrderState.PENDING_REQUEST,
);

export enum StatusTabValue {
  ORDERS = 'orders',
  PENDING = 'pending',
  ALL = 'all',
}

export const DEFAULT_TAB = StatusTabValue.ORDERS;

export const orderStatesByStatusTab: Record<StatusTabValue, OrderState[]> = {
  [StatusTabValue.ORDERS]: remainingOrderStates,
  [StatusTabValue.PENDING]: pendingOnlyOrderState,
  [StatusTabValue.ALL]: [],
};

const statusTabs: StatusTab<StatusTabValue>[] = [
  { label: t('dispatch.tabs.orders'), value: StatusTabValue.ORDERS },
  { label: t('dispatch.tabs.pending'), value: StatusTabValue.PENDING },
];

const DispatchV3OrdersStatusFilter = observer(() => {
  const { ordersDispatchStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get(DispatchURLParams.TAB) as StatusTabValue;
  const isValidTab = Object.values(StatusTabValue).includes(currentTab);

  const handleSelectedTabChange = useCallback(
    (value: StatusTabValue) => {
      setSearchParams((params) => {
        params.set(DispatchURLParams.TAB, value);

        return params;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (isValidTab) {
      const orderStatesByCurrentTab = orderStatesByStatusTab[currentTab];
      const selectedTabDidChange =
        JSON.stringify(orderStatesByCurrentTab) !==
        JSON.stringify(ordersDispatchStore.filters.orderStates);

      if (selectedTabDidChange) {
        localStorage.setItem(OrdersDispatchStorageKeys.TAB_PARAM, currentTab);

        ordersDispatchStore.setFilters(
          { orderStates: orderStatesByStatusTab[currentTab] },
          true,
        );
      }
    }
  }, [isValidTab, currentTab, JSON.stringify(ordersDispatchStore.filters.orderStates)]);

  if (!isValidTab) {
    return null;
  }

  return (
    <StatusTabs
      onChange={handleSelectedTabChange}
      selectedTab={ordersDispatchStore.filters.search ? StatusTabValue.ALL : currentTab}
      tabs={statusTabs}
    />
  );
});

export default DispatchV3OrdersStatusFilter;
