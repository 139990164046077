import { computed, makeObservable, observable, runInAction } from 'mobx';

import { JobAssignmentType } from '~constants/enums';

export type SelectedAssignee = {
  count: number;
  id: string;
  priority: number;
  type: JobAssignmentType;
};

export default class BulkAssignMultipleStore {
  public selectedAssignees: SelectedAssignee[] = [];

  constructor() {
    makeObservable(this, {
      selectedAssignees: observable,
      selectedAssigneesCount: computed,
      selectedAssigneesCountByAssigneeType: computed,
    });
  }

  get selectedAssigneesCountByAssigneeType() {
    return this.selectedAssignees.reduce(
      (obj, assignee) => {
        obj[assignee.type] = obj[assignee.type] + assignee.count;

        return obj;
      },
      {
        [JobAssignmentType.DRIVER]: 0,
        [JobAssignmentType.VENDOR]: 0,
      },
    );
  }

  get selectedAssigneesCount() {
    return this.selectedAssignees.reduce((sum, assignee) => sum + assignee.count, 0);
  }

  clearSelectedAssignees() {
    runInAction(() => {
      this.selectedAssignees = [];
    });
  }

  getSelectedAssignee(id: string) {
    return this.selectedAssignees.find((i) => i.id === id) ?? null;
  }

  isAssigneeSelected(id: string) {
    return this.selectedAssignees.some((assignee) => assignee.id === id);
  }

  unselectAssignee(id: string) {
    runInAction(() => {
      const index = this.selectedAssignees.findIndex((i) => i.id === id);

      if (index > -1) {
        this.selectedAssignees.splice(index, 1);
        this.selectedAssignees = this.selectedAssignees.map((assignee, index) => ({
          ...assignee,
          priority: index + 1,
        }));
      }
    });
  }

  updateSelectedAssignee(selection: Omit<SelectedAssignee, 'priority'>) {
    runInAction(() => {
      const index = this.selectedAssignees.findIndex((i) => i.id === selection.id);

      if (index > -1) {
        this.selectedAssignees.splice(index, 1, { ...selection, priority: index + 1 });
      } else {
        this.selectedAssignees.push({
          ...selection,
          priority: this.selectedAssignees.length + 1,
        });
      }
    });
  }
}
