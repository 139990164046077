import { OrderState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DispatchURLParams, OrdersDispatchStorageKeys } from '~constants/dispatch';
import { useStores } from '~store';

import StatusTabs, { StatusTab } from '../Tabs/StatusTabs';

export enum StatusTabValue {
  EXTERNAL = 'external',
  ACTIVE = 'active',
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  ALL = 'all',
}

export const DEFAULT_TAB = StatusTabValue.ACTIVE;

export const orderStatesByStatusTab: Record<StatusTabValue, OrderState[]> = {
  [StatusTabValue.EXTERNAL]: [OrderState.PENDING_REQUEST],
  [StatusTabValue.ACTIVE]: [
    OrderState.CREATED,
    OrderState.ACCEPTED,
    OrderState.IN_PROGRESS,
  ],
  [StatusTabValue.PENDING]: [OrderState.CREATED, OrderState.ACCEPTED],
  [StatusTabValue.IN_PROGRESS]: [OrderState.IN_PROGRESS],
  [StatusTabValue.DONE]: [OrderState.COMPLETED, OrderState.CANCELED, OrderState.REJECTED],
  [StatusTabValue.ALL]: [],
};

const statusTabs: StatusTab<StatusTabValue>[] = [
  { label: t('dispatch.tabs.external'), value: StatusTabValue.EXTERNAL },
  { label: t('dispatch.tabs.active'), value: StatusTabValue.ACTIVE },
  { label: t('dispatch.tabs.pending'), value: StatusTabValue.PENDING },
  { label: t('dispatch.tabs.in_progress'), value: StatusTabValue.IN_PROGRESS },
  { label: t('dispatch.tabs.done'), value: StatusTabValue.DONE },
  { label: t('dispatch.tabs.all'), value: StatusTabValue.ALL },
];

const OrdersStatusFilter = observer(() => {
  const { ordersDispatchStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get(DispatchURLParams.TAB) as StatusTabValue;
  const isValidTab = Object.values(StatusTabValue).includes(currentTab);

  const handleSelectedTabChange = useCallback(
    (value: StatusTabValue) => {
      setSearchParams((params) => {
        params.set(DispatchURLParams.TAB, value);

        return params;
      });
    },
    [setSearchParams],
  );

  useEffect(() => {
    if (isValidTab) {
      const orderStatesByCurrentTab = orderStatesByStatusTab[currentTab];
      const selectedTabDidChange =
        JSON.stringify(orderStatesByCurrentTab) !==
        JSON.stringify(ordersDispatchStore.filters.orderStates);

      if (selectedTabDidChange) {
        localStorage.setItem(OrdersDispatchStorageKeys.TAB_PARAM, currentTab);

        ordersDispatchStore.setFilters(
          { orderStates: orderStatesByStatusTab[currentTab] },
          true,
        );
      }
    }
  }, [isValidTab, currentTab, JSON.stringify(ordersDispatchStore.filters.orderStates)]);

  if (!isValidTab) {
    return null;
  }

  return (
    <StatusTabs
      onChange={handleSelectedTabChange}
      selectedTab={ordersDispatchStore.filters.search ? StatusTabValue.ALL : currentTab}
      tabs={statusTabs}
    />
  );
});

export default OrdersStatusFilter;
