import {
  Company_Read,
  IamRole_Read as UserRoleProto,
  StytchMemberStatus,
  TimeZone,
  User_Create,
  User_LastLocation_Read,
  User_Me_Read as UserProto,
  User_Update,
} from '@treadinc/horizon-api-spec';
import parsePhoneNumber from 'libphonenumber-js';
import _ from 'lodash';

import { UserValidationDTO } from '~components/UserForm/UserForm';
import { UserNotificationsPreferenceDTO } from '~components/UserForm/UserNotificationsPreferenceForm';
import { AdminCompany } from '~hooks/admin/useCompaniesAdmin/models';
import { BasicEquipment } from '~hooks/useEquipment/models';
import {
  getSelectedNotificationMethodsByNotification,
  UserPreferences,
} from '~hooks/useUsers';
import { Nullable } from '~types/Nullable';

// TODO: wtf is this model for? why wouldn't we use the User models?
export class AdminRoleItem {
  public static parse(proto: UserRoleProto): AdminRoleItem {
    return new AdminRoleItem(
      proto?.id ?? '',
      proto.name ?? '',
      proto?.created_at ?? '',
      proto?.updated_at ?? '',
    );
  }

  public get id(): string {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get createdAt(): Nullable<string> {
    return this._createdAt;
  }

  public get updatedAt(): Nullable<string> {
    return this._updatedAt;
  }

  constructor(
    private _id: string,
    private _name: string,
    private _createdAt: Nullable<string>,
    private _updatedAt: Nullable<string>,
  ) {}
}

export class AdminUser {
  public static parse(proto: UserProto): AdminUser {
    return new AdminUser(
      proto.id ?? '',
      proto?.first_name ?? '',
      proto.last_name ?? '',
      proto?.company ? AdminCompany.parse(proto?.company as Company_Read) : null,
      proto?.iam_roles?.length
        ? proto?.iam_roles.map((userSingleRole) => {
            return AdminRoleItem.parse(userSingleRole as UserRoleProto);
          })
        : [],
      proto.email,
      proto.phone,
      proto?.stytch_member_id ?? null,
      proto?.stytch_member_status ?? undefined,
      proto?.stytch_user_id ?? null,
      proto?.stytch_user_status ?? undefined,
      proto?.created_at ?? '',
      proto?.updated_at ?? '',
      proto?.time_zone ?? '',
      proto?.last_location ?? null,
      proto?.preferences ? UserPreferences.parse(proto.preferences) : null,
      proto?.text_to_accept_enabled,
      proto?.external_id,
      proto?.equipment ? BasicEquipment.parse(proto.equipment) : null,
    );
  }

  public static deparse(
    proto: AdminUser &
      Pick<UserValidationDTO, 'textToAcceptFieldEnabled' | 'textToAcceptFieldValue'>,
    invitesToSend?: User_Create['send_invite'],
    userNotificationsPreferenceData?: UserNotificationsPreferenceDTO,
  ): User_Update | User_Create {
    const data: Partial<User_Update | User_Create> = {
      iam_role_ids: proto.userRoles?.map((role: AdminRoleItem) => role.id) ?? [],
      first_name: proto.firstName,
      last_name: proto.lastName,
      ...(proto.email ? { email: proto.email } : {}),
      ...(proto.phone ? { phone: proto.phone.replace(/\s/g, '') } : {}),
      time_zone: proto.timeZone as TimeZone,
      external_id: proto.externalId,
      equipment_id: proto.equipment?.id ?? null,
    };

    if (!_.isNil(proto.textToAcceptFieldEnabled)) {
      data.text_to_accept_enabled = Boolean(proto.textToAcceptFieldValue);
    }

    if (proto.id) {
      const updateUserData = data as User_Update;
      updateUserData.id = proto.id;

      if (userNotificationsPreferenceData) {
        updateUserData.preferences = {
          notifications: getSelectedNotificationMethodsByNotification(
            userNotificationsPreferenceData,
          ),
        };
      }

      return updateUserData;
    }

    const createUserData = data as User_Create;
    createUserData.company_id = String(proto.company?.id);
    createUserData.send_invite = invitesToSend;

    return createUserData;
  }

  public get id(): string {
    return this._id;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get company(): Nullable<AdminCompany> {
    return this._company;
  }

  public get userRoles(): Nullable<AdminRoleItem[]> {
    return this._userRoles;
  }

  public get email(): Nullable<string> {
    return this._email;
  }

  public get phone(): Nullable<string> {
    if (this._phone) {
      const phone = this._phone.replace(/^\+/g, '');
      return parsePhoneNumber(`+${phone}`)?.formatInternational() as string;
    }
  }

  public get createdAt(): string {
    return this._createdAt;
  }

  public get updatedAt(): string {
    return this._updatedAt;
  }

  public get status(): StytchMemberStatus {
    return this._stytch_member_status;
  }
  public get isActiveMember(): boolean {
    return this._stytch_member_status === StytchMemberStatus.ACTIVE;
  }

  public get isActiveUser(): boolean {
    return this._stytch_user_status === StytchMemberStatus.ACTIVE;
  }

  public get timeZone(): string {
    return this._time_zone;
  }

  public get currentLocation(): Nullable<User_LastLocation_Read> {
    return this._last_location;
  }

  public get preferences() {
    return this._preferences;
  }

  public get textToAcceptEnabled() {
    return this._text_to_accept_enabled;
  }

  public get externalId(): string | null {
    return this._external_id;
  }

  public get equipment(): Nullable<BasicEquipment> {
    return this._equipment;
  }

  constructor(
    private _id: string,
    private _firstName: string,
    private _lastName: string,
    private _company: Nullable<AdminCompany>,
    private _userRoles: Nullable<AdminRoleItem[]>,
    private _email: Nullable<string>,
    private _phone: Nullable<string>,
    private _stytch_member_id: string | null,
    private _stytch_member_status: StytchMemberStatus,
    private _stytch_user_id: string | null,
    private _stytch_user_status: StytchMemberStatus,
    private _createdAt: string,
    private _updatedAt: string,
    private _time_zone: string,
    private _last_location: Nullable<User_LastLocation_Read>,
    private _preferences: Nullable<UserPreferences>,
    private _text_to_accept_enabled: boolean,
    private _external_id: string | null,
    private _equipment: Nullable<BasicEquipment>,
  ) {}
}
