import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeGrid } from 'react-window';

import {
  HEADER_PANEL_Z_INDEX,
  HeaderPanel,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { SearchBar } from '~components/Search/SearchBar';
import Tabs from '~components/Tabs/Tabs';
import { AccountTypesFilter, SystemRoles } from '~constants/enums';
import { Account, useAccount } from '~hooks/useAccount';
import { useCalendarDispatch } from '~hooks/useCalendarDispatch/useCalendarDispatch';
import { Order } from '~hooks/useOrders';
import { User, useUsers } from '~hooks/useUsers';
import { useStores } from '~store';
import { ignoreSunday } from '~utils/dateTime';

import { IDispatchV3ViewComponent } from '../../v3/DispatchV3';
import { isCurrentPageDispatchV3 } from '../../v3/utils';
import CreateOrderButton from '../CreateOrderButton';
import { HEADER_HEIGHT, JOB_COLUMN_WIDTH } from '../drivers/constants';
import DateFilter from '../filters/CalendarDateFilter';
import CalendarFilters from '../filters/CalendarFilters';
import OrderForm from '../OrderForm';
import CalendarDispatchFiltersBar from './CalendarDispatchFiltersBar';
import { getOrdersForCustomer, getOrdersForForeman } from './helpers';
import { ProjectOrderCard } from './ProjectOrderCard';

type OrderFormDialogState = {
  isOpen: boolean;
  order?: Order;
};

interface NameInfo {
  fullName?: string | null;
  phone?: string | null;
}

enum TabValue {
  FOREMAN = 'foreman',
  CUSTOMER = 'customer',
}

interface CalenderViewProps extends IDispatchV3ViewComponent {}

const CalendarView = observer(({ children, viewSelector }: CalenderViewProps) => {
  const theme = useTheme();
  const { getCompanyOrders, getCompanyOrdersByCompanyId } = useCalendarDispatch();
  const { getFilteredUsers, getFilteredCompanyUsers } = useUsers();
  const { getAllCompanyAccounts } = useAccount();
  const { calendarDispatchStore, userStore } = useStores();
  const [foremanUsers, setForemanUsers] = useState<User[]>([]);
  const [allForemanUsers, setAllForemanUsers] = useState<User[]>([]);
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [groupedOrders, setGroupedOrders] = useState({} as Record<string, Array<Order>>);
  const gridRef = useRef<HTMLDivElement>();
  const companyId = userStore.currentCompanies[0].id || userStore.userCompany?.id;
  const isCurrentCompanyActive = userStore.isCurrentCompanyActive;

  const isDispatchV3Page = isCurrentPageDispatchV3();

  useEffect(() => {
    const fetchData = async () => {
      let allForemanUsers: User[] = [];
      if (isCurrentCompanyActive) {
        allForemanUsers = await getFilteredUsers({
          roleNames: [SystemRoles.FOREMAN],
        });
      } else {
        allForemanUsers = await getFilteredCompanyUsers(companyId, {
          roleNames: [SystemRoles.FOREMAN],
        });
      }
      setAllForemanUsers(allForemanUsers);
    };
    fetchData();
  }, [companyId, isCurrentCompanyActive]);

  useEffect(() => {
    const fetchData = async () => {
      if (isCurrentCompanyActive) {
        await getAllCompanyAccounts({
          accountTypes: [AccountTypesFilter.CUSTOMER],
          callback: setAllAccounts,
        });
      } else {
        await getAllCompanyAccounts({
          accountTypes: [AccountTypesFilter.CUSTOMER],
          companyId,
          callback: setAllAccounts,
        });
      }
    };
    fetchData();
  }, [companyId, isCurrentCompanyActive]);

  useEffect(() => {
    const fetchData = () => {
      if (userStore.isCurrentCompanyActive) {
        getCompanyOrders();
      } else {
        getCompanyOrdersByCompanyId({ companyId });
      }
    };

    fetchData();
  }, [JSON.stringify(calendarDispatchStore.filters), companyId]);

  useEffect(() => {
    const fetchData = () => {
      let filteredForemanUsers = allForemanUsers;
      if (calendarDispatchStore.filters.searchName) {
        const searchTerm = calendarDispatchStore.filters.searchName.toLowerCase();
        filteredForemanUsers = allForemanUsers.filter((user) => {
          return (
            user.fullName.toLowerCase().includes(searchTerm) ||
            user.phone?.toLowerCase().includes(searchTerm)
          );
        });
      }
      setForemanUsers(filteredForemanUsers);

      let filteredAccounts = allAccounts;
      if (calendarDispatchStore.filters.searchName) {
        const searchTerm = calendarDispatchStore.filters.searchName.toLowerCase();
        filteredAccounts = allAccounts.filter((account) => {
          return account.name.toLowerCase().includes(searchTerm);
        });
      }
      setAccounts(filteredAccounts);
    };

    fetchData();
  }, [calendarDispatchStore.filters.searchName, allForemanUsers, allAccounts]);

  useEffect(() => {
    // Group orders by start date
    const groupedOrders: Record<string, Array<Order>> = calendarDispatchStore.orders
      .filter((o) => o.orderId)
      .reduce(
        (acc, order) => {
          const startDate = dayjs.tz(order.jobStartAt).format('YYYY-MM-DD');
          if (!acc[startDate]) {
            acc[startDate] = [];
          }
          acc[startDate].push(order);
          return acc;
        },
        {} as Record<string, Array<Order>>,
      );

    setGroupedOrders(groupedOrders);
  }, [calendarDispatchStore.orders]);

  const [orderFormDialog, setOrderFormDialog] = useState<OrderFormDialogState>({
    isOpen: false,
  });
  const [isOrderFormDrawerMinimized, setIsOrderFormDrawerMinimized] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TabValue.FOREMAN);

  const leftNameColumn: NameInfo[] = useMemo(() => {
    if (selectedTab === TabValue.FOREMAN) {
      return foremanUsers
        .map((foreman) => {
          return {
            fullName: foreman.fullName,
            phone: foreman.phone,
          };
        })
        .concat({
          fullName: 'Unassigned',
          phone: '',
        });
    } else if (selectedTab === TabValue.CUSTOMER) {
      return accounts.map((account) => {
        return {
          fullName: account.name,
          phone: '',
        };
      });
    }
    return [];
  }, [foremanUsers, accounts, selectedTab]);

  const generateColumnHeaders = () => {
    const days = [];
    const today = dayjs.tz(calendarDispatchStore.filters.startDate).startOf('day');

    for (let i = 0; i <= 6; i++) {
      const day = today.add(i, 'day');
      const formattedDay = day.format('ddd, MMM D');
      days.push(formattedDay);
    }

    return days;
  };

  const days = generateColumnHeaders();
  const rowHeight = 537;
  const headerHeight = 85;
  const gridWidthSize = 280;
  const gridWidth = gridWidthSize * days.length;
  const nameRows = useMemo(() => {
    if (selectedTab === TabValue.FOREMAN) {
      return foremanUsers.length + 1;
    } else if (selectedTab === TabValue.CUSTOMER) {
      return accounts.length;
    }
    return 0;
  }, [foremanUsers, accounts, selectedTab]);

  useEffect(() => {
    if (gridRef.current) {
      scrollToToday(gridRef.current, calendarDispatchStore.filters.startDate);
    }
  }, [calendarDispatchStore.filters.startDate]);

  const extraContentNodes = (
    <OrderForm
      defaultDate={calendarDispatchStore.filters.startDate}
      isMinimized={isOrderFormDrawerMinimized}
      isOpen={orderFormDialog.isOpen}
      onClose={() => {
        setIsOrderFormDrawerMinimized(false);
        setOrderFormDialog((state) => ({ ...state, isOpen: false }));
      }}
      onMinimize={() => setIsOrderFormDrawerMinimized(true)}
      order={orderFormDialog.order}
    />
  );

  if (isDispatchV3Page) {
    return (
      <>
        <Box sx={{ bgcolor: 'white', gridRow: 'span 2', width: JOB_COLUMN_WIDTH - 1 }} />

        <Box
          sx={{
            alignItems: 'center',
            bgcolor: 'white',
            borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            borderLeft: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
            px: 2,
            py: 1,
          }}
        >
          {viewSelector}

          <CreateOrderButton
            isForResumeOrderCreation={isOrderFormDrawerMinimized}
            onClick={() => {
              if (isOrderFormDrawerMinimized) {
                setIsOrderFormDrawerMinimized(false);
              } else {
                setOrderFormDialog({ isOpen: true });
              }
            }}
          />
        </Box>

        <Box
          sx={{
            bgcolor: 'white',
            borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            borderLeft: `solid 1px ${theme.brandV2.colors.treadGray7}`,
            display: 'flex',
            px: 2,
            py: 1,
          }}
        >
          <DateFilter />
        </Box>

        <Box
          sx={{
            bgcolor: theme.brandV2.colors.treadGray8,
            display: 'flex',
            flexDirection: 'column',
            gridColumn: 'span 2',
            height: `calc(100vh - ${HEADER_HEIGHT}px - 50px)`,
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              display: 'flex',
            }}
          >
            <Box sx={{ minWidth: JOB_COLUMN_WIDTH - 1, flex: 0 }} />

            <Box
              sx={{
                borderLeft: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                flex: 1,
                px: 2,
                py: 1,
              }}
            >
              <CalendarFilters />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'grid',
              flex: 1,
              gridTemplateColumns: `${JOB_COLUMN_WIDTH}px 1fr`,
              gridTemplateRows: `${headerHeight}px 1fr`,
              overflow: 'auto',
              width: '100%',
            }}
          >
            <HeaderPanel
              position="sticky"
              top={0}
              left={0}
              zIndex={HEADER_PANEL_Z_INDEX + 1}
              sx={{
                borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
                borderRight: `1px solid ${theme.brandV2.colors.treadGray7}`,
                px: 2,
              }}
            >
              <Tabs
                selected={selectedTab}
                onChange={(tab) => setSelectedTab(tab)}
                tabs={[
                  {
                    label: t('dispatch.dispatch_v2.calendar.foreman'),
                    value: TabValue.FOREMAN,
                  },
                  {
                    label: t('dispatch.dispatch_v2.calendar.customer'),
                    value: TabValue.CUSTOMER,
                  },
                ]}
              />

              <Box pt={1}>
                <SearchBar
                  placeHolder="Search"
                  alwaysExpanded={true}
                  setFilter={(value) => {
                    calendarDispatchStore.setFilters({ searchName: value }, true);
                  }}
                />
              </Box>
            </HeaderPanel>

            <HeaderPanel
              alignItems="center"
              display="grid"
              gridTemplateColumns={`repeat(${days.length}, ${gridWidthSize}px)`}
              height={`${headerHeight}px`}
              pl={2}
              position="sticky"
              top={0}
              zIndex={HEADER_PANEL_Z_INDEX}
            >
              {days.map((day, index) => (
                <OverflowAwareText key={index} fontWeight={600}>
                  {day}
                </OverflowAwareText>
              ))}
            </HeaderPanel>

            <Box position="sticky" left={0} zIndex={HEADER_PANEL_Z_INDEX}>
              {leftNameColumn.map((userInfo, index) => {
                return (
                  <Box
                    key={index}
                    display="flex"
                    flex={1}
                    height={rowHeight}
                    justifyContent="center"
                    style={{
                      border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                      borderLeftColor: 'transparent',
                      borderTop: 'none',
                      borderTopColor: 'transparent',
                    }}
                  >
                    <Box
                      alignItems="center"
                      bgcolor="#FBFDFD"
                      display="flex"
                      flex={1}
                      pl={2}
                    >
                      <Box>
                        <Typography variant="subtitle1SemiBold">
                          {userInfo ? userInfo.fullName : ''}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                          {userInfo ? userInfo.phone : ''}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>

            <Box ref={gridRef} position="relative" zIndex={HEADER_PANEL_Z_INDEX - 1}>
              <VariableSizeGrid
                columnWidth={() => gridWidthSize}
                rowHeight={() => rowHeight}
                columnCount={days.length}
                height={rowHeight * nameRows}
                rowCount={nameRows}
                width={gridWidth}
              >
                {({ columnIndex, rowIndex, style }) => {
                  const theme = useTheme();
                  const currentDay = dayjs
                    .tz(calendarDispatchStore.filters.startDate)
                    .startOf('day')
                    .add(columnIndex, 'day')
                    .format('YYYY-MM-DD');
                  const currentDayOrders = groupedOrders[currentDay] || [];
                  let orders: Order[] = [];

                  if (selectedTab === TabValue.FOREMAN) {
                    const currentForeman = foremanUsers[rowIndex];
                    orders = getOrdersForForeman(
                      currentForeman,
                      currentDayOrders,
                      allForemanUsers,
                    );
                  } else if (selectedTab === TabValue.CUSTOMER) {
                    const currentAccount = accounts[rowIndex];
                    orders = getOrdersForCustomer(currentAccount, currentDayOrders);
                  }

                  return (
                    <Box
                      id={`calendar-grid-${rowIndex}-${columnIndex}`}
                      display="flex"
                      style={{
                        ...style,
                        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                        borderLeftColor: 'transparent',
                        borderTopColor: 'transparent',
                        scrollMarginLeft: `${JOB_COLUMN_WIDTH}px`,
                      }}
                    >
                      <Box display="flex" flex={1} justifyContent="center">
                        <Box display="flex">
                          <Box>
                            <ProjectOrderCard orders={orders} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                }}
              </VariableSizeGrid>
            </Box>
          </Box>
        </Box>

        {extraContentNodes}
      </>
    );
  }

  return children(
    <CalendarDispatchFiltersBar
      isOrderFormDrawerMinimized={isOrderFormDrawerMinimized}
      onNewOrderClick={() => {
        if (isOrderFormDrawerMinimized) {
          setIsOrderFormDrawerMinimized(false);
        } else {
          setOrderFormDialog({ isOpen: true });
        }
      }}
    />,
    <>
      <Box height={`calc(100vh - ${HEADER_HEIGHT}px)`} overflow="hidden" width="100%">
        <Box
          display="grid"
          gridTemplateColumns={`${JOB_COLUMN_WIDTH}px 1fr`}
          gridTemplateRows={`${headerHeight}px 1fr`}
          height="100%"
          overflow="auto"
          width="100%"
        >
          <HeaderPanel
            position="sticky"
            top={0}
            left={0}
            zIndex={HEADER_PANEL_Z_INDEX + 1}
            sx={{
              borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
              borderLeft: `1px solid ${theme.brandV2.colors.treadGray7}`,
              borderRight: `1px solid ${theme.brandV2.colors.treadGray7}`,
              px: 2,
            }}
          >
            <Tabs
              selected={selectedTab}
              onChange={(tab) => setSelectedTab(tab)}
              tabs={[
                {
                  label: t('dispatch.dispatch_v2.calendar.foreman'),
                  value: TabValue.FOREMAN,
                },
                {
                  label: t('dispatch.dispatch_v2.calendar.customer'),
                  value: TabValue.CUSTOMER,
                },
              ]}
            />

            <Box pt={1}>
              <SearchBar
                placeHolder="Search"
                alwaysExpanded={true}
                setFilter={(value) => {
                  calendarDispatchStore.setFilters({ searchName: value }, true);
                }}
              />
            </Box>
          </HeaderPanel>

          <HeaderPanel
            alignItems="center"
            display="grid"
            gridTemplateColumns={`repeat(${days.length}, ${gridWidthSize}px)`}
            height={`${headerHeight}px`}
            pl={2}
            position="sticky"
            top={0}
            zIndex={HEADER_PANEL_Z_INDEX}
          >
            {days.map((day, index) => (
              <OverflowAwareText key={index} fontWeight={600}>
                {day}
              </OverflowAwareText>
            ))}
          </HeaderPanel>

          <Box position="sticky" left={0} zIndex={HEADER_PANEL_Z_INDEX}>
            {leftNameColumn.map((userInfo, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  flex={1}
                  height={rowHeight}
                  justifyContent="center"
                  style={{
                    border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                    borderLeftColor: 'transparent',
                    borderTop: 'none',
                    borderTopColor: 'transparent',
                  }}
                >
                  <Box
                    alignItems="center"
                    bgcolor="#FBFDFD"
                    display="flex"
                    flex={1}
                    pl={2}
                  >
                    <Box>
                      <Typography variant="subtitle1SemiBold">
                        {userInfo ? userInfo.fullName : ''}
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        {userInfo ? userInfo.phone : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box ref={gridRef} position="relative" zIndex={HEADER_PANEL_Z_INDEX - 1}>
            <VariableSizeGrid
              columnWidth={() => gridWidthSize}
              rowHeight={() => rowHeight}
              columnCount={days.length}
              height={rowHeight * nameRows}
              rowCount={nameRows}
              width={gridWidth}
            >
              {({ columnIndex, rowIndex, style }) => {
                const theme = useTheme();
                const currentDay = dayjs
                  .tz(calendarDispatchStore.filters.startDate)
                  .startOf('day')
                  .add(columnIndex, 'day')
                  .format('YYYY-MM-DD');
                const currentDayOrders = groupedOrders[currentDay] || [];
                let orders: Order[] = [];

                if (selectedTab === TabValue.FOREMAN) {
                  const currentForeman = foremanUsers[rowIndex];
                  orders = getOrdersForForeman(
                    currentForeman,
                    currentDayOrders,
                    allForemanUsers,
                  );
                } else if (selectedTab === TabValue.CUSTOMER) {
                  const currentAccount = accounts[rowIndex];
                  orders = getOrdersForCustomer(currentAccount, currentDayOrders);
                }

                return (
                  <Box
                    id={`calendar-grid-${rowIndex}-${columnIndex}`}
                    display="flex"
                    style={{
                      ...style,
                      border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                      borderLeftColor: 'transparent',
                      borderTopColor: 'transparent',
                      scrollMarginLeft: `${JOB_COLUMN_WIDTH}px`,
                    }}
                  >
                    <Box display="flex" flex={1} justifyContent="center">
                      <Box display="flex">
                        <Box>
                          <ProjectOrderCard orders={orders} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            </VariableSizeGrid>
          </Box>
        </Box>
      </Box>

      {extraContentNodes}
    </>,
  );
});

const scrollToToday = (divRef: HTMLDivElement, startDate: string | undefined) => {
  let weekStart = dayjs.tz().startOf('week');
  weekStart = ignoreSunday(weekStart);
  const daysFromWeekStart = dayjs.tz().diff(weekStart, 'days');

  const currentWeekStart = dayjs.tz(startDate).startOf('week');

  if (divRef) {
    if (dayjs.tz().startOf('week').isSame(currentWeekStart, 'week')) {
      const todayCol = divRef.querySelector<HTMLDivElement>(
        `#calendar-grid-0-${daysFromWeekStart}`,
      );
      if (todayCol) {
        todayCol.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
      }
    } else {
      divRef.scrollTo({
        left: 0,
      });
    }
  }
};

export default CalendarView;
