import ExpandMore from '@mui/icons-material/ExpandMore';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link, { LinkProps } from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CopyToClipboard } from '~components/CopyToClipboard/CopyToClipboard';
import { NavigationTabs } from '~components/LayoutComponents/NavigationTabs';
import { TREAD_PRIVACY_POLICY_LINK, TREAD_TERMS_LINK } from '~constants/consts';
import { SystemRoles } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { useLogin } from '~hooks/useLogin';
import { User } from '~hooks/useUsers';
import { useVersion } from '~hooks/useVersion';
import { Logo } from '~icons';
import CompanySelector from '~layout/AppHeader/CompanySelector/CompanySelector';
import useCompanySelector from '~layout/AppHeader/CompanySelector/hooks/useCompanySelector';
import { ROUTES } from '~router/routePaths';
import { useStores } from '~store';
import { useAwaitableFeatureFlag, useFeatureFlag } from '~utils/hooks/useFeatureFlag';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekday);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1 min',
    mm: '%d min',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
  // set Monday as the first day of the week
  weekStart: 1,
});

const getNavItems = (
  user: User,
  isPlatformAdmin: boolean,
  isImpersonating: boolean,
  omniReportFeatureFlag: boolean,
  dispatchV3FeatureFlag: boolean,
  theme: Theme,
) => {
  const list = [
    {
      title: t('page_headings.sales'),
      link: ROUTES.sales.base,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    },
    {
      title: t('page_headings.dispatch'),
      link: ROUTES.dispatch.base,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    },
  ];

  if (dispatchV3FeatureFlag) {
    list.push({
      title: `${t('page_headings.dispatch')} v3`,
      link: ROUTES.dispatch.v3,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    });
  }

  list.push(
    {
      title: t('page_headings.approvals'),
      link: ROUTES.approvals.base,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    },
    {
      title: t('page_headings.live_map'),
      link: ROUTES.live,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    },
  );

  if (isPlatformAdmin && !isImpersonating) {
    list.unshift({
      title: 'Admin',
      link: ROUTES.admin.base,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    });
  }

  if (
    omniReportFeatureFlag &&
    user.userRoles?.some((role) => role.name === SystemRoles.REPORTING)
  ) {
    list.push({
      title: t('page_headings.reports'),
      link: ROUTES.reports,
      sx: { my: 1, color: theme.palette.primary.contrastText },
    });
  }

  // Settings tab to be added to the end of the list
  list.push({
    title: t('page_headings.settings'),
    link: ROUTES.settings.base,
    sx: { my: 1, color: theme.palette.primary.contrastText },
  });

  return list;
};

const ResponsiveAppBar = () => {
  const { userStore, userAdminStore } = useStores();
  const navigate = useNavigate();
  const theme = useTheme();
  const { logOut } = useLogin();
  const { version } = useVersion();
  const { showCompanySelector, handleCompanySelectorChange } = useCompanySelector();
  const { isImpersonating } = userAdminStore;

  const isPlatformAdmin = useMemo(
    () =>
      userStore.user.userRoles?.some(
        (role) => role.name === SystemRoles.PLATFORM_ADMIN,
      ) ?? false,
    [userStore.user.userRoles],
  );

  const omniReportFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.omniReportFeatureFlag,
  });

  const dispatchV3FeatureFlagEnabled = useFeatureFlag({
    featureFlagKey: FeatureFlags.dispatchV3,
  });

  const handleLogout = () => {
    handleClose();
    logOut();
  };
  let timeDiffDisplay = '';
  if (version.time) {
    // Use the duration plugin to convert milliseconds into days and hours
    const versionDate = dayjs.utc(version.time);
    timeDiffDisplay = versionDate.fromNow();
  }

  const displayName = useMemo(() => {
    if (isImpersonating) {
      return t('page_headings.impersonating', {
        impersonator: userAdminStore.impersonatorUser?.fullName,
        impersonatee: userStore.user.fullName,
      });
    }
    return userStore.user.fullName;
  }, [
    userStore.user.fullName,
    isImpersonating,
    userAdminStore.impersonatorUser?.fullName,
  ]);

  const userRolesAndCompany = useMemo(() => {
    const { userRoles } = userStore.user ?? {};
    const { legalName } = userStore.userCompany ?? {};

    if (!legalName) {
      return null;
    }

    if (userRoles?.length === 1) {
      return `${userRoles[0].name} • ${legalName}`;
    }

    const additionalRolesCount = (userRoles?.length ?? 0) - 1;
    if (additionalRolesCount > 0) {
      return `${userRoles?.[0].name} + ${additionalRolesCount} • ${legalName}`;
    }

    return legalName;
  }, [userStore.user?.userRoles, userStore.userCompany?.legalName]);

  const navItems = useMemo(
    () =>
      getNavItems(
        userStore.user,
        isPlatformAdmin,
        isImpersonating,
        omniReportFeatureFlag.isFeatureFlagEnabled,
        dispatchV3FeatureFlagEnabled,
        theme,
      ),
    [userStore.user.userRoles, omniReportFeatureFlag.isFeatureFlagEnabled],
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: isImpersonating ? 'warning.main' : 'white',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ maxWidth: '100%', width: '100%' }}>
        <Toolbar disableGutters variant="dense" sx={{ px: 3 }}>
          {userStore.userCompany.legalName?.length && (
            <Box
              style={{ maxWidth: '100px', marginRight: '50px' }}
              onClick={() => navigate(`${ROUTES.base}`)}
              sx={{
                display: { xs: 'none', md: 'inline-flex' },
                alignItems: 'center',
                mr: 1,
                cursor: 'pointer',
              }}
            >
              {userStore.userCompany?.logoUrl?.length ? (
                <img
                  style={{ width: '100%', height: 'auto' }}
                  alt="User"
                  loading="lazy"
                  src={`${userStore.userCompany.logoUrl}`}
                />
              ) : (
                <Logo height={32} width={168} />
              )}
            </Box>
          )}

          <Box
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              display: { xs: 'none', md: 'flex' },
              borderRight: isImpersonating ? null : `1px solid ${theme.palette.divider}`,
            }}
          >
            <NavigationTabs
              navItems={navItems}
              initialValue={isPlatformAdmin ? ROUTES.admin.base : ROUTES.dispatch.base}
            />
          </Box>

          {!_.isEmpty(userStore.user) && !_.isNull(userRolesAndCompany) && (
            <Box
              sx={{
                height: '64px',
                display: 'flex',
              }}
            >
              {showCompanySelector && (
                <CompanySelector handleChange={handleCompanySelectorChange} />
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: '20px',
                  paddingLeft: '20px',
                }}
                onClick={handleClick}
              >
                <Box
                  sx={{
                    width: '170px',
                    flexDirection: 'column',
                  }}
                >
                  <Tooltip title={displayName}>
                    <Typography variant="body1" color={'black'} noWrap data-sentry-mask>
                      {displayName}
                    </Typography>
                  </Tooltip>

                  <Tooltip title={userRolesAndCompany}>
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {userRolesAndCompany}
                    </Typography>
                  </Tooltip>
                </Box>
                <ExpandMore
                  sx={{
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s',
                    ml: 1,
                  }}
                />
              </Box>
            </Box>
          )}
        </Toolbar>
      </Box>

      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            elevation: 9,
            sx: {
              border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
              mt: -1,
              width: '240px',
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(`${ROUTES.settings.base}/${ROUTES.settings.userProfile}`);
          }}
        >
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t('app_header.profile')}
          </Typography>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <Typography sx={{ color: theme.palette.text.secondary }}>
            {t('page_headings.logout')}
          </Typography>
        </MenuItem>

        <Divider />

        <MenuItem>
          <CopyToClipboard textToCopy={userStore.userCompany.treadId}>
            <Typography sx={{ fontSize: '12px', color: theme.brandV2.colors.treadBlack }}>
              <Typography
                component="span"
                sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
              >
                {t('page_headings.tread_id')}:
              </Typography>
              <Typography component="span" sx={{ fontSize: 'inherit' }}>
                {` ${userStore.userCompany.treadId}`}
              </Typography>
            </Typography>
          </CopyToClipboard>
        </MenuItem>

        <MenuItem disableRipple>
          <Typography sx={{ fontSize: '12px', color: theme.brandV2.colors.treadBlack }}>
            <Typography
              component="span"
              sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
            >
              {t('page_headings.last_release')}:
            </Typography>
            <Typography component="span" sx={{ fontSize: 'inherit' }}>
              {` ${timeDiffDisplay}`}
            </Typography>
          </Typography>
        </MenuItem>

        <MenuItem disableRipple>
          <Typography sx={{ fontSize: '12px', color: theme.brandV2.colors.treadBlack }}>
            <StyledLink href={TREAD_TERMS_LINK} rel="noopener noreferrer" target="_blank">
              {t('app_header.terms')}
            </StyledLink>
            {' • '}
            <StyledLink
              href={TREAD_PRIVACY_POLICY_LINK}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('app_header.privacy_policy')}
            </StyledLink>
          </Typography>
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

const StyledLink = styled(Link)<LinkProps>(() => ({
  fontSize: 'inherit',
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': { textDecoration: 'underline' },
}));

export default observer(ResponsiveAppBar);
