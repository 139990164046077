import { SiteType } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t as $t } from 'i18next';
import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';

import {
  DEFAULT_TAB,
  orderStatesByStatusTab,
  StatusTabValue,
} from '~components/Filters/OrdersStatusFilter';
import { OrdersDispatchStorageKeys } from '~constants/dispatch';
import { OrderState } from '~constants/enums';
import { AccountTypeahead } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import { Order } from '~hooks/useOrders';
import { Project } from '~hooks/useProjects';
import { isCurrentPageDispatchV3 } from '~pages/Dispatch/v3/utils';
import { Pagination } from '~services/pagination';
import { rootStore } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

const defaultPaginationState: Pagination = { limit: 20, page: 1 };

export enum ExpandedState {
  CLOSED = 'closed',
  EXPANDED = 'expanded',
  OPEN = 'open',
}
export enum DispatchV3ExpandedState {
  CLOSED = 'closed',
  OPEN = 'open',
}
export const ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE = ExpandedState.OPEN;

export type OrderCardsExpandedState = {
  allCards: string;
  cardsByOrder: Map<string, string>;
};

export type Filters = {
  customerAccountIds?: string[];
  dispatchNumbers?: string[];
  driverIds?: string[];
  dropoffSites?: string[];
  endDate?: string;
  jobStates?: string[];
  orderStates?: OrderState[];
  pickUpSites?: string[];
  projectIds?: string[];
  projectsExternalIds?: string[];
  requesterIds?: string[];
  search?: string;
  startDate?: string;
  siteTypes?: SiteType[];
  vendorAccountIds?: string[];
};

export type GetCompanyOrdersQueryParams = {
  'page[after]'?: string;
  'page[before]'?: string;
  'page[limit]': number;
  'filter[job][customer_account_ids]'?: string[];
  'filter[job][dispatch_numbers]'?: string[];
  'filter[job][driver_ids]'?: string[];
  'filter[job][dropoff_site_ids]'?: string[];
  'filter[job][end_date]'?: string;
  'filter[job][external_ids]'?: string[];
  'filter[job][pickup_site_ids]'?: string[];
  'filter[job][project_ids]'?: string[];
  'filter[job][start_date]'?: string;
  'filter[job][states]'?: string[];
  'filter[job][vendor_account_ids]'?: string[];
  'filter[states]'?: string[];
  'search[job][datagrid]'?: string;
  'filter[job][site_types][]'?: SiteType[];
};

export type GetJobsByOrderQueryParams = {
  'filter[states]'?: string[];
};

class OrdersDispatchStore {
  acceptingOrRejectingOrderId: string = '';
  cancellingJobId: string = '';
  copyVendorAssignmentsFilters: Filters = {};
  copyVendorAssignmentsOrders: Order[] = [];
  copyVendorAssignmentsPagination: Pagination = { ...defaultPaginationState };
  draggedAssignee?: DriverBasic | AccountTypeahead;
  driverPanelAssignees: Array<DriverBasic | AccountTypeahead> = [];
  duplicatingJobId: string = '';
  filters: Filters = {};
  hasMore: boolean = false;
  hasMoreCopyVendorAssignmentsOrders: boolean = true;
  isAcceptingOrRejectingJobs: Map<string, boolean> = new Map();
  isCancellingOrder: boolean = false;
  isCloningOrder: boolean = false;
  isCompletingOrder: boolean = false;
  isCreatingJobFromOrder: boolean = false;
  isLoadingCopyVendorAssignmentsOrders: boolean = false;
  isLoadingOrderJobs: Map<string, boolean> = new Map();
  isLoadingOrderProject: Map<string, boolean> = new Map();
  isLoadingOrders: boolean = false;
  isSavingOrder: boolean = false;
  isSendingJobs: Map<string, boolean> = new Map();
  isSidebarCollapsed?: boolean;
  isUpdatingOrderJob: Map<string, boolean> = new Map();
  orderCardsExpandedState: OrderCardsExpandedState = {
    allCards: ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
    cardsByOrder: new Map(),
  };
  orderJobs: Map<string, Job[]> = new Map();
  orderProject: Map<string, Project | null> = new Map();
  orders: Order[] = [];
  ordersPendingToShown: Order[] = [];
  pagination: Pagination = { ...defaultPaginationState };
  preventUpsertOrder: boolean = false;

  constructor() {
    makeObservable(this, {
      acceptingOrRejectingOrderId: observable,
      cancellingJobId: observable,
      copyVendorAssignmentsFilters: observable,
      copyVendorAssignmentsOrders: observable,
      copyVendorAssignmentsPagination: observable,
      draggedAssignee: observable,
      driverPanelAssignees: observable,
      duplicatingJobId: observable,
      filters: observable,
      hasMore: observable,
      hasMoreCopyVendorAssignmentsOrders: observable,
      isAcceptingOrRejectingJobs: observable,
      isCancellingOrder: observable,
      isCloningOrder: observable,
      isCompletingOrder: observable,
      isCreatingJobFromOrder: observable,
      isLoadingCopyVendorAssignmentsOrders: observable,
      isLoadingOrderJobs: observable,
      isLoadingOrderProject: observable,
      isLoadingOrders: observable,
      isSavingOrder: observable,
      isSendingJobs: observable,
      isSidebarCollapsed: observable,
      isUpdatingOrderJob: observable,
      orderCardsExpandedState: observable,
      orderJobs: observable,
      orderProject: observable,
      orders: observable,
      ordersPendingToShown: observable,
      pagination: observable,
      preventUpsertOrder: observable,
      toggleOrderCardExpandedState: action.bound,
    });
  }

  copyVendorAssignmentsOrdersFetchStart() {
    runInAction(() => {
      this.isLoadingCopyVendorAssignmentsOrders = true;
    });
  }

  copyVendorAssignmentsOrdersFetchEnd(orders: Order[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingCopyVendorAssignmentsOrders = false;
      this.copyVendorAssignmentsOrders = _.uniqBy(
        this.copyVendorAssignmentsOrders.concat(orders),
        (order) => order.id,
      );
      this.copyVendorAssignmentsPagination = {
        limit: 8,
        after: pagination?.after ?? '',
        page: defaultPaginationState.page,
      };
      this.hasMoreCopyVendorAssignmentsOrders = Boolean(pagination?.after);
    });
  }

  dismissOrdersPendingToBeShown() {
    runInAction(() => {
      this.orders = _.uniqBy(
        this.orders.concat(this.ordersPendingToShown),
        (order) => order.id,
      );
      this.ordersPendingToShown = [];
    });
  }

  jobCreateFromOrderStart(orderId: string) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, true);
    });
  }

  jobCreateFromOrderEnd(orderId: string, job?: Job) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, false);

      if (job) {
        this.upsertJob(job);
      }
    });
  }

  orderAcceptStart(orderId: string) {
    runInAction(() => {
      this.acceptingOrRejectingOrderId = orderId;
    });
  }

  orderAcceptEnd(order?: Order) {
    runInAction(() => {
      this.acceptingOrRejectingOrderId = '';

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  ordersFetchStart() {
    runInAction(() => {
      this.isLoadingOrders = true;
    });
  }

  ordersFetchEnd(orders: Order[], pagination?: Pagination) {
    runInAction(() => {
      this.isLoadingOrders = false;
      if (!pagination || (!pagination.after && !pagination.before)) {
        this.orders = orders;
      } else {
        this.orders = _.uniqBy(this.orders.concat(orders), (order) => order.id);
      }
      this.pagination = {
        limit: defaultPaginationState.limit,
        after: pagination?.after ?? '',
        page: defaultPaginationState.page,
      };
      this.hasMore = Boolean(pagination?.after);

      // Remove from the list of orders pending to be shown those that are now visible
      orders.forEach((newOrder) => {
        const indexOfOrderPendingToBeShown = this.ordersPendingToShown.findIndex(
          (pendingOrder) => {
            return pendingOrder.id === newOrder.id;
          },
        );

        if (indexOfOrderPendingToBeShown > -1) {
          this.ordersPendingToShown.splice(indexOfOrderPendingToBeShown, 1);
        }
      });
    });
  }

  orderCancelStart() {
    runInAction(() => {
      this.isCancellingOrder = true;
    });
  }

  orderCancelEnd(order?: Order) {
    runInAction(() => {
      this.isCancellingOrder = false;

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderCloneStart() {
    runInAction(() => {
      this.isCloningOrder = true;
    });
  }

  orderCloneEnd(order?: Order) {
    runInAction(() => {
      this.isCloningOrder = false;

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderCompleteStart() {
    runInAction(() => {
      this.isCompletingOrder = true;
    });
  }

  orderCompleteEnd(order?: Order) {
    runInAction(() => {
      this.isCompletingOrder = false;

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderCreateStart() {
    runInAction(() => {
      this.isSavingOrder = true;
    });
  }

  orderCreateEnd(order?: Order) {
    runInAction(() => {
      this.isSavingOrder = false;

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderProjectFetchStart(orderId: string) {
    runInAction(() => {
      this.isLoadingOrderProject.set(orderId, true);
      this.orderProject.set(orderId, null);
    });
  }

  orderProjectFetchEnd(orderId: string, project?: Project) {
    runInAction(() => {
      this.isLoadingOrderProject.set(orderId, false);
      this.orderProject.set(orderId, project ?? null);
    });
  }

  orderRejectStart(orderId: string) {
    runInAction(() => {
      this.acceptingOrRejectingOrderId = orderId;
    });
  }

  orderRejectEnd(order?: Order) {
    runInAction(() => {
      this.acceptingOrRejectingOrderId = '';

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderUpdateStart() {
    runInAction(() => {
      this.isSavingOrder = true;
    });
  }

  orderUpdateEnd(order?: Order) {
    runInAction(() => {
      this.isSavingOrder = false;

      if (order) {
        this.upsertOrder(order);
      }
    });
  }

  orderJobsFetchStart(orderId: string) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, true);
      this.orderJobs.set(orderId, []);
    });
  }

  orderJobsFetchEnd(orderId: string, jobs: Job[]) {
    runInAction(() => {
      this.isLoadingOrderJobs.set(orderId, false);
      this.orderJobs.set(orderId, jobs);
    });
  }

  orderJobAcceptStart(jobId: string) {
    runInAction(() => {
      this.isAcceptingOrRejectingJobs.set(jobId, true);
    });
  }

  orderJobAcceptEnd(jobId: string, job?: Job) {
    runInAction(() => {
      this.isAcceptingOrRejectingJobs.set(jobId, false);

      if (job) {
        this.upsertJob(job);
      }
    });
  }

  orderJobCancelStart(jobId: string) {
    runInAction(() => {
      this.cancellingJobId = jobId;
    });
  }

  orderJobCancelEnd(job?: Job) {
    runInAction(() => {
      this.cancellingJobId = '';

      if (job) {
        this.upsertJob(job);
      }
    });
  }

  orderJobDuplicateStart(jobId: string) {
    runInAction(() => {
      this.duplicatingJobId = jobId;
    });
  }

  orderJobDuplicateEnd(job?: Job) {
    runInAction(() => {
      this.duplicatingJobId = '';

      if (job) {
        this.upsertJob(job);
      }
    });
  }

  orderJobUpdateStart(jobId: string) {
    runInAction(() => {
      this.isUpdatingOrderJob.set(jobId, true);
    });
  }

  orderJobUpdateEnd(jobId: string, updatedJob?: Job) {
    runInAction(() => {
      this.isUpdatingOrderJob.set(jobId, false);

      if (updatedJob) {
        this.upsertJob(updatedJob);
      }
    });
  }

  orderJobRejectStart(jobId: string) {
    runInAction(() => {
      this.isAcceptingOrRejectingJobs.set(jobId, true);
    });
  }

  orderJobRejectEnd(jobId: string, job?: Job) {
    runInAction(() => {
      this.isAcceptingOrRejectingJobs.set(jobId, false);

      if (job) {
        this.upsertJob(job);
      }
    });
  }

  orderJobSendStart(jobId: string) {
    runInAction(() => {
      this.isSendingJobs.set(jobId, true);
    });
  }

  orderJobSendEnd(jobId: string, job?: Job) {
    runInAction(() => {
      this.isSendingJobs.set(jobId, false);

      if (job) {
        this.upsertJob(job);
      }
    });
  }

  resetCopyVendorAssignmentsOrders(filters: Filters = {}) {
    runInAction(() => {
      this.copyVendorAssignmentsFilters = filters;
      this.copyVendorAssignmentsOrders = [];
      this.copyVendorAssignmentsPagination = { ...defaultPaginationState };
      this.hasMoreCopyVendorAssignmentsOrders = true;
      this.isLoadingCopyVendorAssignmentsOrders = false;
    });
  }

  resetOrders(filters: Filters = {}) {
    runInAction(() => {
      this.filters = filters;
      this.hasMore = false;
      this.isLoadingOrderJobs = new Map();
      this.isLoadingOrderProject = new Map();
      this.isLoadingOrders = false;
      this.orderJobs = new Map();
      this.orderProject = new Map();
      this.orders = [];
      this.pagination = { ...defaultPaginationState };
    });
  }

  resetOrderJobs() {
    runInAction(() => {
      this.isLoadingOrderJobs = new Map();
      this.orderJobs = new Map();
    });
  }

  setCopyVendorAssignmentsFilters(filters: Filters, merge?: boolean) {
    this.resetCopyVendorAssignmentsOrders(
      merge ? { ...this.copyVendorAssignmentsFilters, ...filters } : filters,
    );
  }

  retrieveStoredFilters() {
    const storedFilters = localStorage.getItem(OrdersDispatchStorageKeys.APPLIED_FILTERS);
    const parsedFilters = (storedFilters ? JSON.parse(storedFilters) : {}) as Filters;

    // default start date today if this is the very first the time the screen is loaded
    if (!storedFilters) {
      parsedFilters.startDate = dayjs.tz().format('YYYY-MM-DD');
      parsedFilters.endDate = dayjs.tz().format('YYYY-MM-DD');
    }

    return parsedFilters;
  }

  restoreFilters() {
    runInAction(() => {
      this.filters = { ...this.filters, ...this.retrieveStoredFilters() };

      this.orderCardsExpandedState = { ...this.retrieveOrderCardsExpandedState() };
    });
  }

  persistFilters(filters: Filters) {
    runInAction(() => {
      const toPersist: Array<keyof Filters> = [
        'customerAccountIds',
        'dispatchNumbers',
        'driverIds',
        'dropoffSites',
        'endDate',
        'jobStates',
        'pickUpSites',
        'projectIds',
        'projectsExternalIds',
        'requesterIds',
        'startDate',
        'vendorAccountIds',
      ];

      const shouldPersistFilters = Object.keys(filters).some((key) => {
        return toPersist.includes(key as keyof Filters);
      });

      if (!shouldPersistFilters) {
        return;
      }

      const values = _.pick(filters, toPersist);
      localStorage.setItem(
        OrdersDispatchStorageKeys.APPLIED_FILTERS,
        JSON.stringify(values),
      );
    });
  }

  persistOrderCardsExpandedState() {
    localStorage.setItem(
      OrdersDispatchStorageKeys.EXPANDED_STATE,
      this.orderCardsExpandedState.allCards,
    );
  }

  retrieveOrderCardsExpandedState() {
    const storedExpandedState = localStorage.getItem(
      OrdersDispatchStorageKeys.EXPANDED_STATE,
    );
    const parsedExpandedState: OrderCardsExpandedState = {
      allCards: storedExpandedState
        ? storedExpandedState
        : ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
      cardsByOrder: new Map(),
    };

    return parsedExpandedState;
  }

  setFilters(filters: Filters, merge?: boolean) {
    const newFilters = merge ? { ...this.filters, ...filters } : filters;

    if (filters.search) {
      this.resetOrders({ search: filters.search, orderStates: [] });
    } else {
      const previousFilters = this.retrieveStoredFilters();
      const previousTab = (localStorage.getItem(OrdersDispatchStorageKeys.TAB_PARAM) ||
        DEFAULT_TAB) as StatusTabValue;
      const orderStates =
        'orderStates' in filters
          ? filters.orderStates
          : orderStatesByStatusTab[previousTab];

      this.resetOrders({ ...previousFilters, ...newFilters, orderStates, search: '' });
    }

    this.persistFilters(newFilters);
  }

  orderMatchesCurrentFilteringCriteria(order: Order) {
    const matchesOrderStatesFilter = this.filters.orderStates?.length
      ? this.filters.orderStates.includes(order.state)
      : true;

    const matchesStartAndEndDatesFilter =
      this.filters.startDate && this.filters.endDate
        ? dayjs
            .tz(order.loadAt)
            .isBetween(
              dayjs.tz(this.filters.startDate).startOf('day'),
              dayjs.tz(this.filters.endDate).endOf('day'),
              'seconds',
              '[]',
            )
        : true;

    return matchesOrderStatesFilter && matchesStartAndEndDatesFilter;
  }

  upsertOrder(order: Order) {
    if (this.preventUpsertOrder) {
      return;
    }

    runInAction(() => {
      const index = this.orders.findIndex(({ id }) => id === order.id);

      if (index > -1) {
        this.orders.splice(index, 1, order);
      } else {
        const isMatchingFilters = this.orderMatchesCurrentFilteringCriteria(order);

        if (isMatchingFilters) {
          this.ordersPendingToShown = _.uniqBy(
            this.ordersPendingToShown.concat([order]),
            (order) => order.id,
          );
        }
      }
    });
  }

  upsertJob(job: Job) {
    if (!job.order?.id) {
      return;
    }

    const orderId = job.order.id;
    const orderJobs = this.orderJobs.get(orderId);

    if (orderJobs) {
      const index = orderJobs.findIndex(({ id }) => id === job.id);

      runInAction(() => {
        if (index > -1) {
          orderJobs.splice(index, 1, job);
        } else {
          orderJobs.push(job);
        }

        this.orderJobs.set(orderId, orderJobs);
      });
    }
  }

  removeJob(jobId: string) {
    const orderEntry = [...this.orderJobs].find(([, jobs]) =>
      jobs.some(({ id }) => id === jobId),
    );

    if (orderEntry) {
      const [orderId, jobs] = orderEntry;

      const index = jobs.findIndex(({ id }) => id === jobId);

      if (index > -1) {
        runInAction(() => {
          // We don't want to display the order if it doesn't have any jobs
          this.preventUpsertOrder = true;
          const unassignedJobId = jobs[index].jobId;
          jobs.splice(index, 1);

          if (jobs.length === 0) {
            // Remove the entire order if there are no more jobs
            const unassignedOrder = this.orders.find(({ id }) => id === orderId);
            const unassignedOrderId = unassignedOrder?.orderId;
            this.orders = this.orders.filter(({ id }) => id !== orderId);
            rootStore.toasterStore.push(
              alert(
                $t('dispatch.order.order_id_unassigned', { id: unassignedOrderId }),
                AlertTypes.success,
              ),
            );
          } else {
            // Remove the individual job from the order
            this.orderJobs.set(orderId, jobs);
            rootStore.toasterStore.push(
              alert(
                $t('dispatch.job.job_id_removed', { id: unassignedJobId }),
                AlertTypes.success,
              ),
            );
          }

          this.preventUpsertOrder = false;
        });
      }
    }
  }

  toggleSidebar(isCollapsed?: boolean) {
    runInAction(() => {
      this.isSidebarCollapsed = _.isNil(isCollapsed)
        ? !this.isSidebarCollapsed
        : isCollapsed;
    });
  }

  clearDriverPanelAssignees() {
    runInAction(() => {
      this.driverPanelAssignees = [];
    });
  }

  setDriverPanelAssignees(data: Array<DriverBasic | AccountTypeahead>) {
    runInAction(() => {
      this.driverPanelAssignees = _.uniqBy(
        [...this.driverPanelAssignees, ...data],
        (assignee) => assignee.id,
      );
    });
  }

  setDraggedAssignee(assignee?: DriverBasic | AccountTypeahead) {
    runInAction(() => {
      this.draggedAssignee = assignee;
    });
  }

  setOrderCardsExpandedState(state: OrderCardsExpandedState) {
    runInAction(() => {
      this.orderCardsExpandedState = state;
      this.persistOrderCardsExpandedState();
    });
  }

  toggleOrderCardExpandedState(orderId: string) {
    runInAction(() => {
      const { allCards, cardsByOrder } = this.orderCardsExpandedState;
      const currentExpandedState = cardsByOrder.get(orderId) ?? allCards;
      let newExpandedState: string = ExpandedState.CLOSED;

      const isDispatchV3Page = isCurrentPageDispatchV3();

      if (isDispatchV3Page) {
        if (currentExpandedState === DispatchV3ExpandedState.CLOSED) {
          newExpandedState = DispatchV3ExpandedState.OPEN;
        }
      } else {
        if (currentExpandedState === ExpandedState.CLOSED) {
          newExpandedState = ExpandedState.EXPANDED;
        } else if (currentExpandedState === ExpandedState.EXPANDED) {
          newExpandedState = ExpandedState.OPEN;
        }
      }

      const newCardsByOrder = new Map(cardsByOrder);
      newCardsByOrder.set(orderId, newExpandedState);

      this.orderCardsExpandedState = { allCards, cardsByOrder: newCardsByOrder };
      this.persistOrderCardsExpandedState();
    });
  }
}

export default OrdersDispatchStore;
