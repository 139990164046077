import {
  Active,
  DndContext,
  DragOverlay,
  Over,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import {
  HEADER_PANEL_Z_INDEX,
  LoadingSpinner,
} from '~components/Order/ordersDispatchStyledComponents';
import {
  HEADER_HEIGHT,
  JOB_COLUMN_WIDTH,
} from '~pages/Dispatch/components/drivers/constants';
import {
  DriverSchedulerFiltersBar,
  SearchBar,
} from '~pages/Dispatch/components/drivers/DriverSchedulerFiltersBar';
import { JobCard, JobPlacement } from '~pages/Dispatch/components/drivers/JobCard';
import { TimeSlotCellGrid } from '~pages/Dispatch/components/drivers/TimeSlotCellGrid';
import { UnassignedJobs } from '~pages/Dispatch/components/drivers/UnassignedJobs';
import OrderForm from '~pages/Dispatch/components/OrderForm';
import {
  ConfirmChangeReason,
  useDriverScheduler,
} from '~pages/Dispatch/hooks/useDriverScheduler';
import { useStores } from '~store';
import NoDnDDataAttributeMouseSensor from '~utils/dnd-kit/NoDnDDataAttributeMouseSensor';
import { hexToRgba } from '~utils/utilFunctions';

import { IDispatchV3ViewComponent } from '../../v3/DispatchV3';
import { isCurrentPageDispatchV3 } from '../../v3/utils';
import SharedDatesFilter from '../SharedDatesFilter';
import DriversViewEffects from './DriversViewEffects';
import AssignedJobsFilters from './Filters/AssignedJobsFilters';
import { JobDialogsContextProvider } from './JobDialogsContext';

interface DriversViewProps extends IDispatchV3ViewComponent {}

const DriversView = ({ children, viewSelector }: DriversViewProps) => {
  const theme = useTheme();
  const { driverSchedulerStore } = useStores();
  const {
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    isConfirmingChange,
    setIsConfirmingChange,
    assignAndUpdateJobTransaction,
    unassignTransaction,
  } = useDriverScheduler();
  const confirmationDialogRef = useRef<ModalDialogHandler>(null);
  const isLoading =
    driverSchedulerStore.isLoadingUnassignedJobs ||
    driverSchedulerStore.isLoadingAssignees;

  const isDispatchV3Page = isCurrentPageDispatchV3();

  const [isNewOrderDialogOpen, setIsNewOrderDialogOpen] = useState(false);
  const [isOrderFormDrawerMinimized, setIsOrderFormDrawerMinimized] = useState(false);

  const dndSensors = useSensors(
    useSensor(NoDnDDataAttributeMouseSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 0,
      },
    }),
  );

  useEffect(() => {
    if (isConfirmingChange.state) {
      confirmationDialogRef.current?.open();
    } else {
      confirmationDialogRef.current?.close();
      setIsConfirmingChange((prev) => ({ ...prev, content: '' }));
    }
  }, [isConfirmingChange.state]);

  const determineCallBack = async () => {
    if (
      'over' in isConfirmingChange.props &&
      [ConfirmChangeReason.DRIVER_CHANGE, ConfirmChangeReason.VENDOR_CHANGE].includes(
        isConfirmingChange.reason,
      )
    ) {
      await assignAndUpdateJobTransaction(
        isConfirmingChange.props.over,
        isConfirmingChange.props.active,
      );
    } else {
      await unassignTransaction(isConfirmingChange.props.active);
    }
  };

  const handleDenyConfirmation = () => {
    setIsConfirmingChange({
      state: false,
      content: '',
      reason: ConfirmChangeReason.DRIVER_CHANGE,
      props: {
        over: undefined as unknown as Over,
        active: undefined as unknown as Active,
      },
    });
    driverSchedulerStore.setDraggedJob(null);
  };

  const extraContentNodes = (
    <>
      <ModalDialog
        ref={confirmationDialogRef}
        loading={false}
        title={t('actions.confirm_action')}
        content={isConfirmingChange.content}
        confirmButtonText={t('actions.confirm')}
        onCancel={handleDenyConfirmation}
        onClose={handleDenyConfirmation}
        callBack={determineCallBack}
      />

      <OrderForm
        defaultDate={driverSchedulerStore.dateFilters.startDate}
        isMinimized={isOrderFormDrawerMinimized}
        isOpen={isNewOrderDialogOpen}
        onClose={() => {
          setIsOrderFormDrawerMinimized(false);
          setIsNewOrderDialogOpen(false);
        }}
        onMinimize={() => setIsOrderFormDrawerMinimized(true)}
      />
    </>
  );

  if (isDispatchV3Page) {
    return (
      <>
        <DriversViewEffects />

        <JobDialogsContextProvider>
          <DndContext
            sensors={dndSensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <Box sx={{ gridRow: 'span 3', position: 'relative' }}>
              {isLoading && (
                <Box
                  sx={{
                    bgcolor: hexToRgba(theme.brandV2.colors.treadGray8, 0.5),
                    inset: 0,
                    position: 'absolute',
                    zIndex: HEADER_PANEL_Z_INDEX + 4,
                  }}
                />
              )}

              <UnassignedJobs />
            </Box>

            <Box
              sx={{
                alignItems: 'center',
                bgcolor: 'white',
                borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                display: 'flex',
                justifyContent: 'space-between',
                px: 2,
                py: 1,
              }}
            >
              {viewSelector}

              <Box>
                <DriverSchedulerFiltersBar
                  isOrderFormDrawerMinimized={isOrderFormDrawerMinimized}
                  onNewOrderClick={() => {
                    if (isOrderFormDrawerMinimized) {
                      setIsOrderFormDrawerMinimized(false);
                    } else {
                      setIsNewOrderDialogOpen(true);
                    }
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                bgcolor: 'white',
                borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                px: 2,
                py: 1,
              }}
            >
              <SharedDatesFilter dateRangePickerMode="icon" view="drivers" />
            </Box>

            <Box>
              {isLoading && (
                <Box
                  position="absolute"
                  top={`calc(${HEADER_HEIGHT}px + 94px)`}
                  left={JOB_COLUMN_WIDTH}
                  right={0}
                  bottom={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  zIndex={HEADER_PANEL_Z_INDEX + 4}
                  height={'100%'}
                  bgcolor={hexToRgba(theme.brandV2.colors.treadGray8, 0.5)}
                >
                  <LoadingSpinner isVisible={true} />
                </Box>
              )}

              <Box
                sx={{
                  alignItems: 'center',
                  bgcolor: 'white',
                  borderBottom: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                  display: 'flex',
                  gap: 1,
                  px: 2,
                  py: 1,
                }}
              >
                <SearchBar isAlwaysExpanded />

                {!driverSchedulerStore.assignedJobsFilters.search && (
                  <AssignedJobsFilters />
                )}
              </Box>

              <Box display="flex">
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      height: `calc(100vh - ${HEADER_HEIGHT}px - 97px)`,
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                    }}
                  >
                    <Box sx={{ flex: 1, width: '100%' }}>
                      <TimeSlotCellGrid />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <DragOverlay>
              {driverSchedulerStore.draggedJob ? (
                <>
                  <JobCard
                    jobId={driverSchedulerStore.draggedJob.id}
                    jobPlacement={JobPlacement.DRAGGING}
                  />
                </>
              ) : null}
            </DragOverlay>
          </DndContext>

          {extraContentNodes}
        </JobDialogsContextProvider>
      </>
    );
  }

  return children(
    <>
      <DriversViewEffects />
      <DriverSchedulerFiltersBar
        isOrderFormDrawerMinimized={isOrderFormDrawerMinimized}
        onNewOrderClick={() => {
          if (isOrderFormDrawerMinimized) {
            setIsOrderFormDrawerMinimized(false);
          } else {
            setIsNewOrderDialogOpen(true);
          }
        }}
      />
    </>,
    <JobDialogsContextProvider>
      <Box sx={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }} overflow={'hidden'}>
        <DndContext
          sensors={dndSensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          {isLoading && (
            <Box
              position="absolute"
              top={HEADER_HEIGHT}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              zIndex={HEADER_PANEL_Z_INDEX + 4}
              height={'100%'}
              bgcolor={hexToRgba(theme.brandV2.colors.treadGray8, 0.5)}
            >
              <LoadingSpinner isVisible={true} />
            </Box>
          )}
          <Box display="flex">
            <UnassignedJobs />
            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <Box sx={{ flex: 1, width: '100%', background: 'common.white' }}>
                  <TimeSlotCellGrid />
                </Box>
              </Box>
            </Box>
          </Box>

          <DragOverlay>
            {driverSchedulerStore.draggedJob ? (
              <>
                <JobCard
                  jobId={driverSchedulerStore.draggedJob.id}
                  jobPlacement={JobPlacement.DRAGGING}
                />
              </>
            ) : null}
          </DragOverlay>
        </DndContext>

        {extraContentNodes}
      </Box>
    </JobDialogsContextProvider>,
  );
};

export default observer(DriversView);
