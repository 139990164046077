import { useDroppable } from '@dnd-kit/core';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';

import {
  HeaderPanel,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { Job } from '~hooks/useJob/models';
import { useDriverSchedulerFetch } from '~pages/Dispatch/hooks/useDriverSchedulerFetch';
import { useStores } from '~store/RootStore';

import { isCurrentPageDispatchV3 } from '../../v3/utils';
import { JOB_CARD_OFFSET, JOB_COLUMN_WIDTH, ROW_HEIGHT } from './constants';
import { SearchInput } from './Filters/SearchInput';
import UnassignedJobsFilters from './Filters/UnassignedJobsFilters';
import InfiniteList from './InfiniteList';
import { JobCard, JobPlacement } from './JobCard';

const DroppableJobsColumn = ({
  children,
  isDispatchV3Page,
}: {
  children: ReactNode;
  isDispatchV3Page?: boolean;
}) => {
  const { setNodeRef } = useDroppable({ id: 'jobs-column' });

  return (
    <Box
      ref={setNodeRef}
      style={{
        width: `${JOB_COLUMN_WIDTH}px`,
        height: '100%',
        minHeight: `calc(100vh - ${JOB_CARD_OFFSET}px + ${isDispatchV3Page ? 97 : 0}px)`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
};

export const UnassignedJobs = observer(() => {
  const theme = useTheme();
  const { fetchUnassignedJobs } = useDriverSchedulerFetch();
  const { userStore, driverSchedulerStore } = useStores();
  const companyId = userStore.userCompany?.id;
  const [unassignedJobsInput, setUnassignedJobsInput] = useState('');

  const isDispatchV3Page = isCurrentPageDispatchV3();

  const applyUnassignedJobFilter = _.debounce(() => {
    driverSchedulerStore.setUnassignedJobsFilters({ search: unassignedJobsInput }, true);
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    applyUnassignedJobFilter();

    return () => {
      applyUnassignedJobFilter.cancel();
    };
  }, [unassignedJobsInput]);

  const hasNextJobsPage = !!driverSchedulerStore.unassignedJobsPagination.after?.length;

  const loadMoreJobs = () => {
    if (!hasNextJobsPage) return;

    fetchUnassignedJobs({
      states: [JobState.CREATED, JobState.REJECTED, JobState.REASSIGN],
      companyId,
    });
  };

  return (
    <Box
      aria-label="unassigned-jobs-column"
      sx={{
        flexShrink: 0,
        minHeight: '100%',
        width: `${JOB_COLUMN_WIDTH}px`,
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        borderRight: `${isDispatchV3Page ? 1 : 3}px solid ${theme.brandV2.colors.treadGray7}`,
        p: `0 ${theme.spacing(1)}`,
      }}
    >
      <HeaderPanel sx={{ mb: 2, borderBottom: 'none', top: 0 }}>
        <OverflowAwareText mt={1} fontWeight={600}>
          {t('dispatch.drivers.unassigned_jobs')}:{' '}
          {dayjs.tz(driverSchedulerStore.dateFilters.startDate).format("ddd MMM DD, 'YY")}
        </OverflowAwareText>

        <Box mt={1.25} display="flex" gap={1}>
          <UnassignedJobsFilters />

          <SearchInput onChange={setUnassignedJobsInput} value={unassignedJobsInput} />
        </Box>
      </HeaderPanel>
      <Box flexGrow={1} overflow="hidden">
        <DroppableJobsColumn isDispatchV3Page={isDispatchV3Page}>
          <InfiniteList<Job>
            items={driverSchedulerStore.unassignedJobs}
            itemCount={driverSchedulerStore.unassignedJobs.length}
            itemSize={ROW_HEIGHT}
            loadMoreItems={loadMoreJobs}
            hasNextPage={hasNextJobsPage}
            style={{ overflowX: 'hidden' }}
            renderItem={(index, style) => (
              <JobCard
                key={driverSchedulerStore.unassignedJobs[index]?.id}
                jobId={driverSchedulerStore.unassignedJobs[index]?.id}
                style={style}
                jobPlacement={JobPlacement.JOBS_COLUMN}
              />
            )}
          />
        </DroppableJobsColumn>
      </Box>
    </Box>
  );
});
