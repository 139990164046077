import { yupResolver } from '@hookform/resolvers/yup';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import Flag from '@mui/icons-material/Flag';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FileAttachment_Read, TicketState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { Body } from '~components/Drawer/src/ui/Body';
import { Footer } from '~components/Drawer/src/ui/Footer';
import { Header } from '~components/Drawer/src/ui/Header';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { Job } from '~hooks/useJob';
import { Ticket } from '~hooks/useTickets';
import { ImageUpload, ImageUploadHandler } from '~pages/Admin/Companies/ImageUpload';
import { canEditTicket } from '~utils/tickets/ticket-utils';

import FileAttachments, {
  fileAttachmentSchema,
} from '../../../formsShared/components/FileAttachments';
import { TicketFormDTO } from '../ApprovalsComponents/ticketFormSchema';
import { Field } from './Field';
import { TicketDetailForm } from './TicketDetailForm';

interface TicketDetailsProps {
  readonly?: boolean;
  loading?: boolean;
  job: Job;
  loadId?: string;
  onApprove: (ticket: Ticket) => void;
  onChange: (ticket: TicketFormDTO) => Promise<Ticket>;
  onClose: () => void;
  onDelete?: (ticket: Ticket) => void;
  onFlag: (ticke: Ticket) => Promise<Ticket>;
  onRemoveImage: (ticket: Ticket) => void;
  onUploadImage: (ticket: Ticket | undefined, image: File) => void;
  ticket?: Ticket;
}

const fileAttachmentsFormSchema = yup.object().shape({
  fileAttachments: fileAttachmentSchema,
});

export interface TicketDetailsImperativeApi {
  setEditMode: (isEditing: boolean) => void;
}

export const TicketDetails = forwardRef<TicketDetailsImperativeApi, TicketDetailsProps>(
  (
    {
      loading,
      readonly,
      job,
      loadId,
      onApprove,
      onChange,
      onClose,
      onDelete,
      onFlag,
      onRemoveImage,
      onUploadImage,
      ticket,
    },
    ref,
  ) => {
    const deleteImageModalDialogRef = useRef<ModalDialogHandler>(null);
    const deleteTicketModalDialogRef = useRef<ModalDialogHandler>(null);
    const imageUploadRef = useRef<ImageUploadHandler>(null);
    const [isEditing, setIsEditing] = useState(false);
    const ticketDetailFormRef = useRef<TicketDetailsImperativeApi>(null);

    useImperativeHandle(
      ref,
      () => ({
        setEditMode: (isEditing: boolean) => {
          ticketDetailFormRef.current?.setEditMode(isEditing);
        },
      }),
      [],
    );

    const form = useForm({
      resolver: yupResolver(fileAttachmentsFormSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {},
    });
    const {
      control,
      formState: { errors },
    } = form;

    const handleEditModeChange = useCallback((isEditing: boolean) => {
      setIsEditing(isEditing);
    }, []);

    const handleFlagTicket = useCallback(async () => {
      if (ticket) {
        try {
          setIsEditing(true);
          await onFlag(ticket);
        } finally {
          setIsEditing(false);
        }
      }
    }, [ticket, onFlag]);

    return (
      <>
        <ModalDialog
          ref={deleteImageModalDialogRef}
          title={$t('approvals.ticket_details.remove_image')}
          content={$t('approvals.ticket_details.remove_image_confirmation')}
          confirmButtonText={`${$t('actions.confirm')}`}
          confirmButtonColor={'error'}
          callBack={() => {
            if (ticket) {
              imageUploadRef.current?.reset();
              onRemoveImage(ticket);
              deleteImageModalDialogRef.current?.close();
            }
          }}
        />
        <ModalDialog
          ref={deleteTicketModalDialogRef}
          title={$t('approvals.ticket_details.confirm_delete')}
          content={$t('approvals.ticket_details.delete_warning')}
          confirmButtonText={`${$t('actions.delete')}`}
          callBack={() => {
            if (ticket && onDelete) {
              onDelete(ticket);
              deleteTicketModalDialogRef.current?.close();
            }
          }}
        />
        <Header
          title={
            ticket ? (
              <>
                <Typography
                  component="span"
                  variant="body1"
                  fontWeight="600"
                  sx={{ mr: 2 }}
                >
                  {$t('approvals.ticket_details.ticket_title', {
                    number: `${ticket?.ticketNumber}`,
                  })}
                </Typography>
                <Typography component="span" variant="body1" color="text.secondary">
                  {`${ticket?.orderId}`}
                </Typography>
              </>
            ) : (
              <Typography
                component="span"
                variant="body1"
                fontWeight="600"
                sx={{ mr: 2 }}
              >
                {$t('approvals.ticket_details.new_ticket')}
              </Typography>
            )
          }
          actions={
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          }
        />
        <Body>
          <Box display="flex" flex={1}>
            <Box width="455px" borderRight="1px solid" borderColor="grey.200">
              <Box display="flex" alignItems="center" p={2}>
                <Box height="36px" display="flex" alignItems="center">
                  <Typography variant="body1" fontWeight="bold">
                    {$t('approvals.ticket_details.material_ticket_upload')}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ px: 1 }}>
                <ImageUpload
                  ref={imageUploadRef}
                  isCanBeMagnified={true}
                  maxSize={10}
                  url={ticket?.imageUrl}
                  secondaryText={null}
                  allowDelete={false}
                  disabled={
                    (ticket && !canEditTicket(ticket.state as TicketState)) || readonly
                  }
                  removeCallback={() => {
                    if (ticket) {
                      onRemoveImage(ticket);
                    }
                  }}
                  onChange={(image) => {
                    onUploadImage(ticket, image);
                  }}
                  sx={{ minHeight: '450px', width: '100%' }}
                />
                {!readonly &&
                  ticket &&
                  canEditTicket(ticket.state as TicketState) &&
                  ticket.imageUrl && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      startIcon={<Delete />}
                      sx={{ mt: 1 }}
                      onClick={() => {
                        deleteImageModalDialogRef.current?.open();
                      }}
                    >
                      {$t('approvals.ticket_details.remove_image')}
                    </Button>
                  )}
                <Box pt={2}>
                  <Divider sx={{ mb: 2 }} />
                  {!readonly && (
                    <FileAttachments
                      fileAttachableId={loadId}
                      fileAttachableType={FileAttachment_Read.file_attachable_type.LOAD}
                      control={control}
                      errors={errors}
                      form={form}
                      name="fileAttachments"
                      allowPerItemUpdates
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignSelf="flex-start"
              width="455px"
            >
              <TicketDetailForm
                ref={ticketDetailFormRef}
                key={ticket?.id}
                readonly={readonly}
                jobId={job.id}
                onChange={onChange}
                onDelete={() => {
                  deleteTicketModalDialogRef.current?.open();
                }}
                onEditModeChange={handleEditModeChange}
                ticket={ticket}
              />

              <Header
                title={
                  <Typography variant="body2" fontWeight="bold">
                    {$t('approvals.ticket_details.load_details')}
                  </Typography>
                }
              />

              <Field label={$t('common.project')} value={job.projectName} />
              <Field label={$t('common.order')} value={job.orderId} />
              <Field label={$t('form_fields.job')} value={job.jobId} />
              <Field label={$t('common.customer')} value={job.order?.account?.name} />
              <Field
                label={$t('form_fields.drop_off')}
                value={job.dropOffWayPoints[0]?.site?.name}
              />
              <Field
                label={$t('common.vendor')}
                value={job.vendorJobAssignment?.vendorAccount?.name}
              />
              <Field label={$t('form_fields.driver')} value={job.driver?.fullName} />
              <Field label={$t('form_fields.equipment')} value={job.equipment?.name} />
            </Box>
            {loading && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="rgba(255, 255, 255, 0.7)"
              >
                <LoadingSpinner isVisible={true} />
              </Box>
            )}
          </Box>
        </Body>
        <Footer
          actions={
            <>
              {ticket && !readonly && canEditTicket(ticket.state as TicketState) && (
                <>
                  <Button
                    color="inherit"
                    disabled={isEditing || loading}
                    onClick={handleFlagTicket}
                    startIcon={ticket.flagged ? <Flag /> : <FlagOutlined />}
                    variant="outlined"
                  >
                    {ticket.flagged
                      ? $t('approvals.remove_flag')
                      : $t('approvals.add_flag')}
                  </Button>
                  <Button
                    color="success"
                    disabled={isEditing || loading}
                    onClick={() => onApprove(ticket)}
                    startIcon={<Check />}
                    variant="contained"
                  >
                    {$t('approvals.approve')}
                  </Button>
                </>
              )}
            </>
          }
        />
      </>
    );
  },
);

TicketDetails.displayName = 'TicketDetails';
