import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextFormField } from '~components/FormFields/TextFormField';
import { NameIdSchemaRequired } from '~constants/regexConst';
import { useStores } from '~store';
import { nullableStringOrNumberIsValidNumber } from '~utils/utilFunctions';

import { QuantityType } from '../newOrderFormSchema';
import { FlexColumn, FlexContainer, FlexRow, Hint, Label } from './shared-components';

const formatTime = (minutes: number) => {
  const days = Math.floor(minutes / 60 / 24);
  const hours = Math.floor((minutes / 60) % 24);
  const mins = Math.floor(minutes % 60);

  return `${days > 0 ? `${days}d ` : ''}${hours > 0 || days > 0 ? `${hours}h ` : ''}${mins}m`;
};

export default function Cycle() {
  const form = useFormContext();

  const { userStore } = useStores();

  const shouldUseKilometers = userStore.userCompany.isMeters;

  const watchedCycleTime = form.watch('cycleTime') as string | number | null;
  const watchedDropoffOnsite = form.watch('dropoffOnsite') as string | number | null;
  const watchedPickupOnsite = form.watch('pickupOnsite') as string | number | null;
  const watchedPickupToDropoff = form.watch('pickupToDropoff') as string | number | null;
  const watchedQuantityType = form.watch('quantityType') as NameIdSchemaRequired | null;
  const watchedServiceQuantity = form.watch('serviceQuantity') as string | number | null;

  const formattedDurations = useMemo(() => {
    const isValidCycleTime =
      nullableStringOrNumberIsValidNumber(watchedCycleTime) &&
      Number(watchedCycleTime) >= 0;

    const isValidPickupOnsite =
      nullableStringOrNumberIsValidNumber(watchedPickupOnsite) &&
      Number(watchedPickupOnsite) >= 0;

    const isValidDropoffOnsite =
      nullableStringOrNumberIsValidNumber(watchedDropoffOnsite) &&
      Number(watchedDropoffOnsite) >= 0;

    const isValidPickupToDropoff =
      nullableStringOrNumberIsValidNumber(watchedPickupToDropoff) &&
      Number(watchedPickupToDropoff) >= 0;

    return {
      cycleTime: isValidCycleTime ? formatTime(Number(watchedCycleTime)) : null,
      pickupOnsite: isValidPickupOnsite ? formatTime(Number(watchedPickupOnsite)) : null,
      dropoffOnsite: isValidDropoffOnsite
        ? formatTime(Number(watchedPickupOnsite))
        : null,
      pickupToDropoff: isValidPickupToDropoff
        ? formatTime(Number(watchedPickupToDropoff))
        : null,
    };
  }, [
    watchedCycleTime,
    watchedPickupOnsite,
    watchedDropoffOnsite,
    watchedPickupToDropoff,
  ]);

  const shouldDisableTruckCountField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    return selectedQuantityTypeId === QuantityType.TRUCKS;
  }, [watchedQuantityType]);

  const shouldDisableTotalLoadsField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    return selectedQuantityTypeId === QuantityType.LOAD;
  }, [watchedQuantityType]);

  const shouldDisableQuantityField = useMemo(() => {
    const selectedQuantityTypeId = watchedQuantityType?.id;

    if (!selectedQuantityTypeId) {
      return false;
    }

    const isTrucksQuantityTypeSelected = selectedQuantityTypeId === QuantityType.TRUCKS;
    const isLoadsQuantityTypeSelected = selectedQuantityTypeId === QuantityType.LOAD;

    if (isTrucksQuantityTypeSelected || isLoadsQuantityTypeSelected) {
      return false;
    }

    return true;
  }, [watchedQuantityType]);

  useEffect(() => {
    if (shouldDisableTruckCountField) {
      form.setValue('truckCount', watchedServiceQuantity);
    }
  }, [shouldDisableTruckCountField, watchedQuantityType, watchedServiceQuantity]);

  useEffect(() => {
    if (shouldDisableTotalLoadsField) {
      form.setValue('totalLoads', watchedServiceQuantity);
    }
  }, [shouldDisableTotalLoadsField, watchedQuantityType, watchedServiceQuantity]);

  useEffect(() => {
    if (shouldDisableQuantityField) {
      form.setValue('quantity', watchedServiceQuantity);
    }
  }, [shouldDisableQuantityField, watchedQuantityType, watchedServiceQuantity]);

  return (
    <FlexContainer>
      <FlexColumn>
        <Label isBold sx={{ mt: 2, mb: 1 }}>
          {t('dispatch.order.cycle.times_section_title')}
        </Label>

        <FlexRow>
          <FlexColumn noGap>
            <Label>{t('dispatch.order.cycle.pickup_onsite')}</Label>
            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              name="pickupOnsite"
              type="number"
            />
            <Hint isLarge>
              {formattedDurations.pickupOnsite
                ? t('dispatch.order.hrs_minutes', {
                    value: formattedDurations.pickupOnsite,
                  })
                : null}
            </Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label>{t('dispatch.order.cycle.dropoff_onsite')}</Label>
            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              name="dropoffOnsite"
              type="number"
            />
            <Hint isLarge>
              {formattedDurations.dropoffOnsite
                ? t('dispatch.order.hrs_minutes', {
                    value: formattedDurations.dropoffOnsite,
                  })
                : null}
            </Hint>
          </FlexColumn>

          <FlexColumn noGap>
            <Label>{t('dispatch.order.cycle.pickup_to_drop')}</Label>
            <TextFormField
              control={form.control}
              errors={form.formState.errors}
              name="pickupToDropoff"
              type="number"
            />
            <Hint isLarge>
              {formattedDurations.pickupToDropoff
                ? t('dispatch.order.hrs_minutes', {
                    value: formattedDurations.pickupToDropoff,
                  })
                : null}
            </Hint>
          </FlexColumn>
        </FlexRow>
      </FlexColumn>

      <FlexRow>
        <FlexColumn>
          <Label>{t('dispatch.order.cycle.cycle_time')}</Label>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            name="cycleTime"
            type="number"
          />
          <Hint isLarge>
            {formattedDurations.cycleTime
              ? t('dispatch.order.hrs_minutes', {
                  value: formattedDurations.cycleTime,
                })
              : null}
          </Hint>
          <Hint>{t('dispatch.order.cycle.cycle_time_hint')}</Hint>
        </FlexColumn>

        <FlexColumn>
          <Label>
            {t('dispatch.order.cycle.cycle_distance', {
              unit: shouldUseKilometers ? t('common.kilometers') : t('common.miles'),
            })}
          </Label>
          <TextFormField
            control={form.control}
            errors={form.formState.errors}
            name="cycleDistance"
            type="number"
          />
          <Hint>{t('dispatch.order.cycle.cycle_distance_hint')}</Hint>
        </FlexColumn>

        <FlexColumn />
      </FlexRow>
    </FlexContainer>
  );
}
