import CancelOutlined from '@mui/icons-material/CancelOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PeopleOutlineOutlined from '@mui/icons-material/PeopleOutlineOutlined';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import { buttonBaseClasses, svgIconClasses, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { buttonClasses, ButtonProps } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { OrderState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { cloneElement, ReactNode, useMemo, useRef } from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import { extractWaypointData } from '~components/Order/ordersDispatchColumns';
import {
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import OrderStatusTransitioner from '~components/Order/OrderStatusTransitioner';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { isJobAssignable } from '~utils/jobs/job-utils';

import { doneOrderStates } from '../constants/orderStates';
import { NotesCard } from './OrderNotes';
import { formatNumber, Summary } from './OrderSummary';

interface OrderDispatchCardFragment {
  order: Order;
}

export function Title({ order }: OrderDispatchCardFragment) {
  const mainText = useMemo(() => {
    const fragments: string[] = [];

    if (order.dispatchNumber) {
      fragments.push(`${order.dispatchNumber}`);
    }

    if (order.name) {
      fragments.push(`${order.name}`);
    }

    if (order.loadAt) {
      fragments.push(dayjs.tz(order.loadAt).format('h:mm A D MMM ‘YY'));
    }

    const pickUp = extractWaypointData(order.waypoints?.[0]);
    const dropOff = extractWaypointData(order.waypoints?.[1]);

    if (pickUp) {
      if (!order.name && dropOff) {
        fragments.push(`${pickUp.name} ${t('common.to')} ${dropOff.name}`.toUpperCase());
      } else {
        fragments.push(pickUp.name.toUpperCase());
      }
    }

    return fragments.join(' • ');
  }, [order.dispatchNumber, order.name, order.loadAt, order.waypoints]);

  return (
    <Typography fontSize="16px" fontWeight={600} mt="-3px">
      {mainText}
    </Typography>
  );
}

export function Subtitle({ order }: OrderDispatchCardFragment) {
  const fragments = useMemo(() => {
    const fragments: string[] = [];

    if (order.projectName) {
      fragments.push(order.projectName);
    }

    if (order.project?.externalId) {
      fragments.push(order.project?.externalId);
    }

    return fragments;
  }, [order.projectName, order.project?.externalId]);

  if (fragments.length) {
    return (
      <OverflowAwareText>
        <Typography fontSize="inherit" component="span">
          {t('common.project')}:
        </Typography>

        <Typography
          color={theme.brandV2.colors.treadGray3}
          component="span"
          fontSize="inherit"
        >
          {` ${fragments.join(' - ')} • ${order.orderId}`}
        </Typography>
      </OverflowAwareText>
    );
  }

  return (
    <OverflowAwareText>
      <Typography
        color={theme.brandV2.colors.treadGray3}
        component="span"
        fontSize="inherit"
      >
        {order.orderId}
      </Typography>
    </OverflowAwareText>
  );
}

interface StatusProps extends OrderDispatchCardFragment {
  onAccept: () => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onReject: () => void;
}

type AvailableAction = {
  color: ButtonProps['color'];
  icon?: ReactNode;
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
};

export const Status = observer(
  ({ onAccept, onOrderStateChange, onReject, order }: StatusProps) => {
    const { ordersDispatchStore, userStore } = useStores();
    const { canDeleteOrder } = userStore.getPermissions();

    const orderCTAs = useMemo(() => {
      const isPendingRequest = order.state === OrderState.PENDING_REQUEST;

      if (isPendingRequest) {
        return [
          {
            color: 'brandV2Green',
            isDisabled: ordersDispatchStore.acceptingOrRejectingOrderId === order.id,
            label: t('actions.accept'),
            onClick: onAccept,
          } as AvailableAction,
          {
            color: 'brandV2Red',
            isDisabled: ordersDispatchStore.acceptingOrRejectingOrderId === order.id,
            label: t('actions.decline'),
            onClick: onReject,
          } as AvailableAction,
        ];
      }

      return null;
    }, [order.id, order.state, ordersDispatchStore.acceptingOrRejectingOrderId]);

    const transitionableStates: OrderState[] = useMemo(() => {
      const { editable, state } = order;
      const availableNextStates: OrderState[] = [];

      const isCancellable =
        editable && !doneOrderStates.includes(state) && canDeleteOrder;
      const isCompletable = isCancellable && state !== OrderState.CREATED;

      if (isCancellable) {
        availableNextStates.push(OrderState.CANCELED);
      }

      if (isCompletable) {
        availableNextStates.push(OrderState.COMPLETED);
      }

      return availableNextStates;
    }, [order.editable, order.state, canDeleteOrder]);

    const isTransitioningToStatus = useMemo(() => {
      if (ordersDispatchStore.isCancellingOrder) {
        return OrderState.CANCELED;
      }

      if (ordersDispatchStore.isCompletingOrder) {
        return OrderState.COMPLETED;
      }
    }, [ordersDispatchStore.isCancellingOrder, ordersDispatchStore.isCompletingOrder]);

    if (orderCTAs) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {orderCTAs.map((cta) => (
            <SmallButton
              key={cta.label}
              color={cta.color}
              disabled={cta.isDisabled}
              onClick={(event) => {
                event.stopPropagation();
                cta.onClick();
              }}
              startIcon={cta.icon}
              sx={{
                [`&.${buttonBaseClasses.root}`]: {
                  height: '24px',
                  px: 0.5,
                  [`&.${buttonBaseClasses.disabled}`]: { pointerEvents: 'auto' },
                  [`.${svgIconClasses.root}`]: { fontSize: '16px' },
                },
              }}
            >
              {cta.label}
            </SmallButton>
          ))}
        </Box>
      );
    }

    return (
      <Box>
        <OrderStatusTransitioner
          closeOnTransitionComplete
          isTransitioningToStatus={isTransitioningToStatus}
          onTransitionToStatus={onOrderStateChange}
          order={order}
          transitionableStates={transitionableStates}
        />
      </Box>
    );
  },
);

interface BulkAssignMultipleCTAProps extends OrderDispatchCardFragment {
  onClick?: () => void;
}

export const BulkAssignMultipleCTA = observer(
  ({ onClick, order }: BulkAssignMultipleCTAProps) => {
    const { ordersDispatchStore } = useStores();
    const isDone = doneOrderStates.includes(order.state);

    const shouldShowCTA = useMemo(() => {
      if (!onClick || isDone) {
        return false;
      }

      const orderJobs = ordersDispatchStore.orderJobs.get(order.id) ?? [];
      const orderHasAssignableJobs = orderJobs.some((job) => isJobAssignable(job));

      return orderHasAssignableJobs;
    }, [JSON.stringify(ordersDispatchStore.orderJobs.get(order.id)), isDone, onClick]);

    if (!shouldShowCTA) {
      return null;
    }

    return (
      <BasicTooltip title={t('dispatch.order.bulk_assign_order')}>
        <Box
          sx={{
            display: 'inline-block',
            height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
          }}
        >
          <SmallButton
            color="brandV2Orange"
            variant="text"
            startIcon={<PeopleOutlineOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              onClick?.();
            }}
            sx={{
              [`&.${buttonBaseClasses.root}`]: {
                border: 'solid 1px transparent',
                borderRadius: '50%',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                p: 0,
                width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
                [`.${buttonClasses.startIcon}`]: { mx: 0 },
              },
            }}
          />
        </Box>
      </BasicTooltip>
    );
  },
);

interface EditOrderCTAProps extends OrderDispatchCardFragment {
  onClick: () => void;
}

export const EditOrderCTA = observer(({ onClick, order }: EditOrderCTAProps) => {
  const { userStore } = useStores();
  const { canEditOrder } = userStore.getPermissions();

  const isEditable = order.editable && canEditOrder;

  if (!isEditable) {
    return null;
  }

  return (
    <BasicTooltip title={t('dispatch.order.edit_order')}>
      <Box
        sx={{
          display: 'inline-block',
          height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
        }}
      >
        <SmallButton
          color="brandV2Orange"
          variant="text"
          startIcon={<EditOutlined />}
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
          sx={{
            [`&.${buttonBaseClasses.root}`]: {
              border: 'solid 1px transparent',
              borderRadius: '50%',
              height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              p: 0,
              width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
              '&:hover': { borderColor: theme.brandV2.colors.treadOrange },
              [`.${buttonClasses.startIcon}`]: { mx: 0 },
            },
          }}
        />
      </Box>
    </BasicTooltip>
  );
});

interface ThreeDotsMenuProps extends OrderDispatchCardFragment {
  onCancelClick: () => void;
  onCloneOrderClick: () => void;
  onTextAllDriversClick: () => void;
}

export const ThreeDotsMenu = observer(
  ({
    onCancelClick,
    onCloneOrderClick,
    onTextAllDriversClick,
    order,
  }: ThreeDotsMenuProps) => {
    const { userStore } = useStores();
    const { canCreateOrder, canCreateJob, canDeleteOrder } = userStore.getPermissions();
    const menuHandler = useRef<MenuHandler>(null);

    const { editable, state } = order;

    const isCloneable = editable && canCreateOrder;
    const isDone = doneOrderStates.includes(state);

    const isCancellable = editable && !isDone && canDeleteOrder;
    const canAddNewJobs = editable && !isDone && canCreateJob;
    const canTextDrivers = editable && !isDone;

    const availableActions = useMemo(() => {
      const actions = [];

      if (canTextDrivers) {
        actions.push({
          key: 'text_all_drivers',
          icon: <ChatOutlined />,
          label: `${t('dispatch.order.text_all_drivers')}`,
          onClick: onTextAllDriversClick,
        });
      }

      if (isCloneable) {
        actions.push({
          key: 'clone_order',
          icon: <FileCopyOutlined />,
          label: `${t('dispatch.order.clone_order')}`,
          onClick: onCloneOrderClick,
        });
      }

      if (isCancellable) {
        actions.push({
          key: 'cancel_order',
          icon: <CancelOutlined />,
          label: t('dispatch.order.cancel_order'),
          onClick: onCancelClick,
        });
      }

      return actions;
    }, [isCloneable, canAddNewJobs, canTextDrivers, isCancellable]);

    const [[cancelAction], otherActions] = _.partition(availableActions, (action) => {
      return action.key === 'cancel_order';
    });

    if (!availableActions.length) {
      return null;
    }

    return (
      <Menu
        ref={menuHandler}
        menuTrigger={
          <SmallButton
            color="brandV2TreadGray3"
            variant="text"
            startIcon={<MoreHoriz />}
            sx={{
              [`&.${buttonBaseClasses.root}`]: {
                border: 'solid 1px transparent',
                borderRadius: '50%',
                height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                p: 0,
                width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                '&:hover': { borderColor: theme.brandV2.colors.treadGray3 },
                [`.${buttonClasses.startIcon}`]: { mx: 0 },
              },
            }}
          />
        }
      >
        {otherActions.map((action) => (
          <MenuItem
            key={action.key}
            onClick={(event) => {
              event.stopPropagation();
              action.onClick();
              menuHandler.current?.onClose?.();
            }}
          >
            <Box alignItems="center" display="flex" width="100%" gap={1}>
              {cloneElement(action.icon, {
                sx: { fontSize: '16px', color: theme.brandV2.colors.treadBlack },
              })}

              <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                {action.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}

        {Boolean(otherActions.length && cancelAction) && <Divider sx={{ mx: 1 }} />}

        {Boolean(cancelAction) && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              cancelAction.onClick();
              menuHandler.current?.onClose?.();
            }}
          >
            <Box alignItems="center" display="flex" width="100%" gap={1}>
              {cloneElement(cancelAction.icon, {
                sx: { fontSize: '16px', color: theme.brandV2.colors.treadBlack },
              })}

              <Typography color={theme.brandV2.colors.treadBlack} fontSize="12px">
                {cancelAction.label}
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    );
  },
);

interface CustomerNameProps extends OrderDispatchCardFragment {
  sx?: SxProps;
}

export function CustomerName({ order, sx }: CustomerNameProps) {
  const fragments = order.sendingAccounts.length
    ? order.sendingAccounts.map((account) => account.name)
    : [order.account?.name ?? ''];

  return fragments.map((fragment, index) => (
    <OverflowAwareText key={index} sx={sx}>
      {fragment}
    </OverflowAwareText>
  ));
}

export function ServiceType({ order }: OrderDispatchCardFragment) {
  if (!order.service?.name) {
    return null;
  }

  return <OverflowAwareText>{order.service.name}</OverflowAwareText>;
}

export function Requester({ order }: OrderDispatchCardFragment) {
  const requester = order.requester?.fullName;

  if (!requester) {
    return null;
  }

  return (
    <BasicTooltip
      title={t('dispatch.dispatch_v2.order_requested_by', { name: requester })}
    >
      <OverflowAwareText
        sx={{ ml: '-5px', display: 'inline-block', maxWidth: 'fit-content' }}
      >
        <PersonOutlineOutlined
          sx={{ color: theme.brandV2.colors.treadGray4, fontSize: '20px' }}
        />
        {requester}
      </OverflowAwareText>
    </BasicTooltip>
  );
}

export function CreatedTime({ order }: OrderDispatchCardFragment) {
  const date = order.loadAt ? dayjs.tz(order.loadAt).format('h:mm A D MMM ‘YY') : null;

  if (!date) {
    return null;
  }

  return <OverflowAwareText>{date}</OverflowAwareText>;
}

interface BaseOrderNotesComponentProps {
  text?: string;
  title: string;
  Wrapper: React.ComponentType<React.PropsWithChildren>;
}

function BaseOrderNotesComponent({ text, title, Wrapper }: BaseOrderNotesComponentProps) {
  if (!text) {
    return null;
  }

  return (
    <Wrapper>
      <NotesCard title={title} text={text} variant="inline" />
    </Wrapper>
  );
}

interface OrderNotesComponentProps extends OrderDispatchCardFragment {
  Wrapper: BaseOrderNotesComponentProps['Wrapper'];
}

export function OrderNotes({ order, Wrapper }: OrderNotesComponentProps) {
  return (
    <BaseOrderNotesComponent
      text={order.notes ?? order.project?.notes}
      title={t('form_fields.order')}
      Wrapper={Wrapper}
    />
  );
}

export function InternalNotes({ order, Wrapper }: OrderNotesComponentProps) {
  return (
    <BaseOrderNotesComponent
      text={order.internalNotes ?? order.project?.internalNotes ?? undefined}
      title={t('form_fields.internal')}
      Wrapper={Wrapper}
    />
  );
}

export function QuantityAccepted({ order }: OrderDispatchCardFragment) {
  const count = order.orderSummary?.acceptedJobsCount ?? 0;
  const total = order.orderSummary?.jobsCount ?? 0;
  const equipmentType = order.equipmentType?.name;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Summary
        count={count}
        total={total}
        description={t('dispatch.dispatch_v3.order_summary.accepted.description', {
          num: formatNumber(count),
          total: formatNumber(total),
        })}
        descriptionSx={{ mb: '2px', fontWeight: 500 }}
      />

      {equipmentType && (
        <OverflowAwareText sx={{ fontWeight: 600 }}>{equipmentType}</OverflowAwareText>
      )}
    </Box>
  );
}

export function QuantityDelivered({ order }: OrderDispatchCardFragment) {
  const entry =
    order.orderSummary?.deliveredQuantities.find(
      (deliveredQuantity) => deliveredQuantity.materialName === order.material?.name,
    ) || order.orderSummary?.deliveredQuantities[0];

  const count = _.isNil(entry?.delivered) ? 0 : Number(entry?.delivered);
  const total = _.isNil(entry?.total) ? 0 : Number(entry?.total);
  const materialName = order.material?.name;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Summary
        count={count}
        total={total}
        description={t('dispatch.dispatch_v3.order_summary.quantity.description', {
          num: formatNumber(count),
          total: formatNumber(total),
        })}
        descriptionSx={{ mb: '2px', fontWeight: 500 }}
      />

      {materialName && (
        <OverflowAwareText sx={{ fontWeight: 600 }}>{materialName}</OverflowAwareText>
      )}
    </Box>
  );
}
