import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { FileAttachment_Read } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Drawer } from '~components/Drawer/src/ui';
import { NotSpecified } from '~components/Helpers/NotSpecified';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { OrderUnitOfMeasure } from '~constants/enums';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useFileAttachment } from '~hooks/useFileAttachment';
import { Job, useJob } from '~hooks/useJob';
import { JobLoadCycle, JobLoadSummary } from '~hooks/useJob/models';
import { BasicMaterial } from '~hooks/useMaterials';
import { Ticket, TicketEventType, useTickets } from '~hooks/useTickets';
import { TicketFormDTO } from '~pages/Approvals/ApprovalsComponents/ticketFormSchema';
import {
  TicketDetails,
  TicketDetailsImperativeApi,
} from '~pages/Approvals/TicketsReviewDataGrid/TicketDetails';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { getEffectiveUserCompanyId } from '~utils/user/user-utils';

import LoadsTable from './LoadsTable';

interface Props {
  details: Job;
  isEditing?: boolean;
  loadsTableNodeRef?: MutableRefObject<HTMLDivElement | undefined>;
  loadsTableSx?: SxProps;
  reload: () => void;
}

export default observer(function Loads({
  details,
  isEditing = false,
  loadsTableNodeRef,
  loadsTableSx,
  reload,
}: Props) {
  const { toasterStore, userStore, ticketsStore } = useStores();

  const {
    createTicket,
    deleteTicketImageById,
    deleteTicketById,
    getAllTicketsByJob,
    updateState,
    updateTicketById,
    uploadTicketImageById,
    subscribeToTicketUpdates,
    ticketSubscription,
  } = useTickets();
  const [selectedTicket, setSelectedTicket] = useState<{
    isDrawerOpen: boolean;
    loadId?: string;
    ticket?: Ticket;
  }>({ isDrawerOpen: false });
  const imagePendingToBeUploaded = useRef<File>();

  const userPermissions = userStore.getPermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [waitingForRtu, setWaitingForRtu] = useState(false);
  const waitingForRtuRef = useRef(false);
  const waitingForRtuTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [jobLoads, setJobLoads] = useState<JobLoadSummary[]>([]);
  const [jobTickets, setJobTickets] = useState<Ticket[]>([]);
  const { removeLoad, addLoad, getJobById, getJobLoadCycles, getJobLoads, approveLoad } =
    useJob();
  const { getAllFileAttachmentsById, deleteFileAttachmentById } = useFileAttachment();
  const [cycles, setCycles] = useState<Array<JobLoadCycle>>([]);
  const isCompanySameAsLoggedInCompany = useIsCompanySameAsLoggedInCompany(
    details.order?.account?.name,
  );

  const selectedTicketRef = useRef<{
    isDrawerOpen: boolean;
    loadId?: string;
    ticket?: Ticket;
  }>({ isDrawerOpen: false });
  const ticketDetailsRef = useRef<TicketDetailsImperativeApi>(null);

  useEffect(() => {
    selectedTicketRef.current = selectedTicket;
  }, [selectedTicket]);

  useEffect(() => {
    // to process rtus
    // setting up a callback wasn't working due to stale closure
    const selectedTicket = ticketsStore.tickets.find(
      (ticket) => ticket.id === selectedTicketRef.current.ticket?.id,
    );
    if (selectedTicket && selectedTicket.ocrProcessed && waitingForRtu) {
      setSelectedTicket((state) => ({ ...state, ticket: selectedTicket }));
      setWaitingForRtu(false);
      ticketDetailsRef.current?.setEditMode(true);
    }
  }, [ticketsStore.tickets]);

  useEffect(() => {
    waitingForRtuRef.current = waitingForRtu;
  }, [waitingForRtu]);

  useEffect(() => {
    if (!ticketSubscription && getEffectiveUserCompanyId(userStore)) {
      subscribeToTicketUpdates();
    }
  }, [ticketSubscription, getEffectiveUserCompanyId(userStore)]);

  useEffect(() => {
    return () => {
      clearTimeout(waitingForRtuTimeout.current);
    };
  }, []);

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    return res;
  };

  const saveTicket = async (ticketDto: TicketFormDTO) => {
    let ticket: Ticket;
    const isUpdate = Boolean(ticketDto.id);

    if (isUpdate) {
      ticket = await updateTicketById({
        id: String(ticketDto.id),
        data: {
          quantity: ticketDto.quantity,
          unitOfMeasure: ticketDto.unitOfMeasure,
          material: ticketDto.material.id ? { id: ticketDto.material.id } : undefined,
          ticketNumber: ticketDto.ticketNumber,
          imageUrl: ticketDto.imageUrl,
          serviceDate: ticketDto.serviceDate,
        },
      });
    } else {
      ticket = await createTicket({
        data: {
          job_id: ticketDto.jobId,
          load_id: selectedTicket.loadId,
          material_id: ticketDto.material.id || undefined,
          quantity: ticketDto.quantity,
          ticket_number: ticketDto.ticketNumber,
          unit_of_measure: ticketDto.unitOfMeasure.id,
        },
      });

      if (imagePendingToBeUploaded.current) {
        ticket = await uploadTicketImageById({
          id: ticket.id,
          file: imagePendingToBeUploaded.current,
        });
      }
    }

    if (ticket) {
      const message = isUpdate ? 'approvals.ticket_updated' : 'approvals.ticket_created';
      toasterStore.push(alert(t(message), AlertTypes.success));
      setSelectedTicket((state) => ({ ...state, ticket }));
    }

    return ticket;
  };

  const deleteTicket = async (ticket: Ticket) => {
    await deleteTicketById({ id: ticket.id });
    setSelectedTicket((state) => ({ ...state, ticket: undefined }));
  };

  const handleFlagTicket = async (ticket: Ticket) => {
    ticket.flagged = !ticket.flagged;

    const updatedTicket = await updateTicketById({ id: ticket.id, data: ticket });

    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
    reload();

    return updatedTicket;
  };

  const handleSelectedTicketChange = useCallback((loadId: string, ticket?: Ticket) => {
    setSelectedTicket({ isDrawerOpen: true, loadId, ticket });
  }, []);

  const handleRemoveLoad = useCallback(
    async (loadId: string) => {
      await removeLoad(loadId);
      await getJobById(details.id);

      reload();
      toasterStore.push(alert(t('loads.load_removed'), AlertTypes.success));
    },
    [details.id, reload],
  );

  const onAddNew = () => {
    addLoad(details.id, {
      quantity: Number(details.equipmentTypeGrossCapacity) || 1,
      unitOfMeasure: Number(details.equipmentTypeGrossCapacity)
        ? (details.unitOfMeasure as ItemNameAndId)
        : ({
            id: OrderUnitOfMeasure.LOAD,
            name: OrderUnitOfMeasure.LOAD,
          } as ItemNameAndId),
      material: details?.material
        ? ({ id: details.material.id, name: details.material.name } as BasicMaterial)
        : null,
    })
      .then(() => {
        toasterStore.push(alert(t('loads.load_added'), AlertTypes.success));
      })
      .then(() => {
        getJobById(details.id);
      })
      .then(() => {
        reload();
      });
  };

  const handleCloseDrawer = () => {
    setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }));
    reload();
  };

  useEffect(() => {
    function getInitialData() {
      const loadCyclesRequest = getJobLoadCycles(details.id).then((res) => {
        setCycles(res);
      });

      const jobLoadsRequest = getJobLoads(details.id).then((response) => {
        setJobLoads(response);
      });

      const jobTicketsRequest = getAllTicketsByJob(details.id).then((response) => {
        setJobTickets(response);
      });

      return Promise.all([loadCyclesRequest, jobLoadsRequest, jobTicketsRequest]);
    }

    setIsLoading(true);
    getInitialData().then(() => setIsLoading(false));
  }, [details]);

  useEffect(() => {
    if (!selectedTicket.isDrawerOpen) {
      imagePendingToBeUploaded.current = undefined;
      setWaitingForRtu(false);

      // remove added attachments if the drawer was closed without saving the ticket
      const shouldRemoveAttachments = Boolean(
        !selectedTicket.ticket?.id && selectedTicket.loadId,
      );

      if (shouldRemoveAttachments) {
        getAllFileAttachmentsById(
          String(selectedTicket.loadId),
          FileAttachment_Read.file_attachable_type.LOAD,
        ).then((attachments) => {
          attachments.map((attachment) => deleteFileAttachmentById(attachment.id));
        });
      }
    }
  }, [selectedTicket.isDrawerOpen, selectedTicket.loadId, selectedTicket.ticket?.id]);

  const allLoadsApprovable = useMemo(() => {
    return !jobLoads.length
      ? false
      : jobLoads.every((load) => {
          return load.approvable;
        });
  }, [jobLoads]);

  const onApproveAllLoads = () => {
    Promise.all(
      jobLoads.map((load) => {
        if (load.approvable) {
          return approveLoad(load.id);
        }
        return null;
      }),
    ).then(() => {
      toasterStore.push(alert(t('approvals.loads_approved'), AlertTypes.success));
      reload();
    });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: theme.brandV2.colors.treadBlack,
              fontWeight: 600,
              minHeight: `calc(${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px + ${theme.spacing(0.5)})`,
            }}
          >
            {t('approvals.driver_day.loads')}
          </Typography>
          {allLoadsApprovable && (
            <SmallButton
              color="brandV2Yellow"
              sx={{
                '&.MuiButtonBase-root': {
                  height: `${NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}px`,
                },
              }}
              onClick={onApproveAllLoads}
              disabled={isEditing}
            >
              {t('approvals.driver_day.approve_loads')}
            </SmallButton>
          )}
        </Box>

        <Box ref={loadsTableNodeRef}>
          {jobLoads.length ? (
            <LoadsTable
              cycles={cycles}
              jobTickets={jobTickets}
              loads={jobLoads}
              onLoadCardClick={handleSelectedTicketChange}
              onRemoveLoadClick={handleRemoveLoad}
              reload={reload}
              isEditing={isEditing}
              readonly={isCompanySameAsLoggedInCompany}
              sx={{
                border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
                borderRadius: '4px',
                p: 1,
                ...loadsTableSx,
              }}
            />
          ) : (
            <NotSpecified />
          )}
        </Box>

        {userPermissions.canCreateLoad && !isCompanySameAsLoggedInCompany && (
          <Box mt={2}>
            <SmallButton
              onClick={onAddNew}
              color={'brandV2Yellow'}
              disabled={isLoading || isEditing}
            >
              {`+ ${t('loads.add')}`}
            </SmallButton>
          </Box>
        )}
      </Box>

      <Drawer
        anchor="right"
        onClose={handleCloseDrawer}
        open={selectedTicket.isDrawerOpen}
        variant="temporary"
      >
        <TicketDetails
          ref={ticketDetailsRef}
          job={details}
          readonly={isCompanySameAsLoggedInCompany}
          loading={waitingForRtu}
          loadId={selectedTicket.loadId}
          ticket={selectedTicket.ticket}
          onFlag={handleFlagTicket}
          onApprove={async (ticket) => {
            const approvedTicket = await doAction(ticket.id, 'approve');

            if (approvedTicket) {
              setSelectedTicket((state) => ({ ...state, ticket: approvedTicket }));
              reload();
            }
          }}
          onRemoveImage={async (ticket) => {
            await deleteTicketImageById({ id: ticket.id });
            const newTicket = ticket;
            newTicket.imageUrl = '';
            setSelectedTicket((state) => ({ ...state, ticket: newTicket }));
          }}
          onUploadImage={async (ticket, image) => {
            if (ticket) {
              const updatedTicket = await uploadTicketImageById({
                id: ticket.id,
                file: image,
              });

              if (updatedTicket) {
                setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
              }
            } else {
              // there is no ticket, create one.
              setWaitingForRtu(true);
              imagePendingToBeUploaded.current = image;
              let ticket = await createTicket({
                data: {
                  job_id: details.id,
                  load_id: selectedTicket.loadId,
                  material_id: undefined,
                  quantity: null,
                  ticket_number: null,
                  unit_of_measure: OrderUnitOfMeasure.LOAD,
                },
              });

              if (imagePendingToBeUploaded.current) {
                ticket = await uploadTicketImageById({
                  id: ticket.id,
                  file: imagePendingToBeUploaded.current,
                });

                if (ticket) {
                  toasterStore.push(
                    alert(t('approvals.ticket_created'), AlertTypes.success),
                  );
                  setSelectedTicket((state) => ({ ...state, ticket }));
                }

                waitingForRtuTimeout.current = setTimeout(() => {
                  // incase RTU doesn't trigger, set loading to false after 20 seconds
                  if (waitingForRtuRef.current) {
                    ticketDetailsRef.current?.setEditMode(true);
                    setWaitingForRtu(false);
                  }
                }, 12000);
              }
            }
          }}
          onChange={saveTicket}
          onClose={handleCloseDrawer}
          onDelete={deleteTicket}
        />
      </Drawer>
    </>
  );
});

/**
 * Returns true if the company name is the same as the logged in company.
 */
export const useIsCompanySameAsLoggedInCompany = (companyName: string | undefined) => {
  const { userStore } = useStores();
  return userStore.userCompany.legalName === companyName;
};
