export enum DispatchURLParams {
  VIEW = 'view',
  TAB = 'tab',
}

export enum OrdersDispatchStorageKeys {
  APPLIED_FILTERS = 'ordersDispatch_appliedFilters',
  TAB_PARAM = `dispatch_${DispatchURLParams.TAB}`,
  VIEW_PARAM = `dispatch_${DispatchURLParams.VIEW}`,
  EXPANDED_STATE = `dispatch_expandedState`,
}

export enum CalendarDispatchStorageKeys {
  APPLIED_FILTERS = 'calendarDispatch_appliedFilters',
  TAB_PARAM = `dispatch_${DispatchURLParams.TAB}`,
  VIEW_PARAM = `dispatch_${DispatchURLParams.VIEW}`,
}

export enum DriverSchedulerStoreStorageKeys {
  APPLIED_ASSIGNED_JOBS_FILTERS = 'driverScheduler_appliedAssignedJobsFilters',
}
