import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { OrderState } from '@treadinc/horizon-api-spec';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import {
  ActionsColumn,
  AlertsColumn,
  CheckboxColumn,
  CompanyColumn,
  CycleColumn,
  DispatchColumn,
  DispatchV3ActionsColumn,
  DispatchV3BottomColumn,
  DispatchV3MainColumn,
  ForColumn,
  makeOrderDispatchCardTemplateColumns,
  MaterialColumn,
  StartColumn,
  StatusColumn,
  TrucksAndTypeColumn,
  WaypointsColumn,
} from '~components/Order/ordersDispatchColumns';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import {
  ExpandedState,
  ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
} from '~store/OrdersDispatchStore';
import theme from '~theme/AppTheme';

import { isCurrentPageDispatchV3 } from '../v3/utils';
import OrderDispatchCardExpandedBlock from './OrderDispatchCardExpandedBlock';
import OrderSummary from './OrderSummary';

export const ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE = 1;
export const ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX = 1;

interface OrderDispatchCardProps {
  checkedJobs?: string[];
  expandedState?: string;
  isChecked?: boolean;
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
  onAcceptOrder: () => void;
  onBulkAssignMultipleClick?: () => void;
  onCheckedStateChange?: (orderId: string, jobId?: string) => void;
  onCloneOrderClick: () => void;
  onCreateJobFromOrderClick: () => void;
  onEditClick: () => void;
  onExpandedStateChange: (orderId: string) => void;
  onOrderStateChange: (nextOrderState: OrderState) => Promise<void>;
  onRejectOrder: () => void;
  onTextAllDriversClick: () => void;
  order: Order;
  companyHasChildCompanies: boolean;
}

const OrderDispatchCard = observer(
  ({
    checkedJobs,
    expandedState = ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
    isChecked,
    nonRoutableAlertsFeatureFlagEnabled,
    onAcceptOrder,
    onBulkAssignMultipleClick,
    onCheckedStateChange,
    onCloneOrderClick,
    onCreateJobFromOrderClick,
    onEditClick,
    onExpandedStateChange,
    onOrderStateChange,
    onRejectOrder,
    onTextAllDriversClick,
    order,
    companyHasChildCompanies,
  }: OrderDispatchCardProps) => {
    const isDispatchV3Page = isCurrentPageDispatchV3();
    const isExpanded = expandedState === ExpandedState.EXPANDED;
    const isOpen = expandedState === ExpandedState.OPEN;

    const { ordersDispatchStore } = useStores();
    const handleCheckedOrderStateChange = useCallback(() => {
      onCheckedStateChange?.(order.id);
    }, [onExpandedStateChange, order.id]);

    const handleCheckedJobStateChange = useCallback(
      (jobId: string) => {
        onCheckedStateChange?.(order.id, jobId);
      },
      [onExpandedStateChange, order.id],
    );

    const handleExpandedStateChange = useCallback(() => {
      onExpandedStateChange(order.id);
    }, [onExpandedStateChange, order.id]);

    if (isDispatchV3Page) {
      return (
        <Box
          bgcolor="white"
          border={`solid ${ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX}px ${theme.brandV2.colors.treadGray7}`}
          borderRadius={theme.spacing()}
          boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.20)"
          width="100%"
        >
          <Box
            onClick={handleExpandedStateChange}
            sx={{
              display: 'grid',
              gridTemplateColumns: makeOrderDispatchCardTemplateColumns(
                false,
                isDispatchV3Page,
              ),
              p: ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE,
              rowGap: 1,
            }}
          >
            <CheckboxColumn
              isChecked={isChecked}
              isDisabled={ordersDispatchStore.isLoadingOrderJobs.get(order.id) ?? true}
              onCheckedStateChange={handleCheckedOrderStateChange}
            />

            <DispatchV3MainColumn order={order} />

            <DispatchV3ActionsColumn
              onAccept={onAcceptOrder}
              onBulkAssignMultiple={onBulkAssignMultipleClick}
              onCancel={() => onOrderStateChange(OrderState.CANCELED)}
              onCloneOrder={onCloneOrderClick}
              onEdit={onEditClick}
              onOrderStateChange={onOrderStateChange}
              onReject={onRejectOrder}
              onTextAllDrivers={onTextAllDriversClick}
              order={order}
            />

            <DispatchV3BottomColumn order={order} />
          </Box>

          <Collapse mountOnEnter unmountOnExit in={isExpanded || isOpen}>
            <Box borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
              <OrderDispatchCardExpandedBlock
                checkedJobs={checkedJobs}
                onCheckedJobStateChange={handleCheckedJobStateChange}
                onCreateJobFromOrder={onCreateJobFromOrderClick}
                order={order}
              />
            </Box>
          </Collapse>
        </Box>
      );
    }

    return (
      <Box
        bgcolor="white"
        border={`solid ${ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX}px ${theme.brandV2.colors.treadGray7}`}
        borderRadius={theme.spacing()}
        boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.20)"
        width="100%"
      >
        <Box
          display="grid"
          gridTemplateColumns={makeOrderDispatchCardTemplateColumns()}
          p={ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE}
          onClick={handleExpandedStateChange}
        >
          <CheckboxColumn
            isChecked={isChecked}
            isDisabled={ordersDispatchStore.isLoadingOrderJobs.get(order.id) ?? true}
            onCheckedStateChange={handleCheckedOrderStateChange}
          />
          <DispatchColumn order={order} />
          {companyHasChildCompanies && <CompanyColumn order={order} />}
          <ForColumn order={order} />
          <TrucksAndTypeColumn order={order} />
          <StartColumn order={order} />
          <CycleColumn order={order} />
          <StatusColumn
            onAccept={onAcceptOrder}
            onOrderStateChange={onOrderStateChange}
            onReject={onRejectOrder}
            order={order}
          />
          <WaypointsColumn order={order} />
          <MaterialColumn order={order} />
          <AlertsColumn
            nonRoutableAlertsFeatureFlagEnabled={nonRoutableAlertsFeatureFlagEnabled}
            order={order}
          />
          <ActionsColumn
            onBulkAssignMultiple={onBulkAssignMultipleClick}
            onCancel={() => onOrderStateChange(OrderState.CANCELED)}
            onCloneOrder={onCloneOrderClick}
            onEdit={onEditClick}
            onTextAllDrivers={onTextAllDriversClick}
            order={order}
          />
        </Box>

        <Collapse mountOnEnter unmountOnExit in={isExpanded || isOpen}>
          <Box borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
            <OrderSummary order={order} />
          </Box>
        </Collapse>

        <Collapse mountOnEnter unmountOnExit in={isOpen}>
          <Box borderTop={`solid 1px ${theme.brandV2.colors.treadGray7}`}>
            <OrderDispatchCardExpandedBlock
              checkedJobs={checkedJobs}
              onCheckedJobStateChange={handleCheckedJobStateChange}
              onCreateJobFromOrder={onCreateJobFromOrderClick}
              order={order}
            />
          </Box>
        </Collapse>
      </Box>
    );
  },
);

export default OrderDispatchCard;
